import React, { useContext ,useState} from "react";
import { StandardProgramContext } from "../../../../../../contexts/StandardProgramContext";
import strategicPlanning from "../../../../../../assets/icons/strategicPlanning.svg";
import lamp from "../../../../../../assets/icons/lamp.svg";
import Skeleton from "react-skeleton-loader";
import NextBtn from "../components/buttons/nextBtn";
import AppButton from "../../../../../../components/buttons/appButton";
import AppModal from "../../../../../../components/Models/appModal";
import UploadAdapter from "../../../../../../components/UploadAdapter";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { permissionsCheck } from "../../../../../../helpers/utils";

const StandardAnswerData = ({ loading, setMenuElement, pocAnswer }) => {
  const standardProgramContext = useContext(StandardProgramContext);
  const [openSiteVisit,setOpenSiteVisit] = useState(false)
  const [descriptionSiteVisit,setDescriptionSiteVisit] = useState("")

  return (
    <>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex w-[100%] flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={strategicPlanning}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">
              {standardProgramContext?.standard?.title}
            </h1>
          </div>
          <div>
            {/* {permissionsCheck("Pages.UserType.Expert") || permissionsCheck("Pages.UserType.Reviewer") || permissionsCheck("Pages.UserType.ExpertManager") ? (
              <AppButton onClick={()=>setOpenSiteVisit(true)}>طلب زيارة ميدانية</AppButton>
            ):null} */}
          </div>
          </div>
          <AppModal
            open={openSiteVisit}
            setOpen={setOpenSiteVisit}
            headerTitle="طلب زيارة ميدانية"
            submitLabel="ارسال"
            handleSubmit={() => setOpenSiteVisit(false)}
          >
            <CKEditor
          editor={ClassicEditor}
          data={
            descriptionSiteVisit
          }
          onReady={(editor) => {
            setDescriptionSiteVisit(editor.getData());
            // setContent(editor.getData());
            editor.plugins.get("FileRepository").createUploadAdapter = function (
              loader
            ) {
              return new UploadAdapter(loader);
            };
          }}
          config={{
            language: "ar",
            simpleUpload: {
              uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
              withCredentials: true,

              headers: {
                // 'X-CSRF-TOKEN': 'CSRF-Token',
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setDescriptionSiteVisit(data);
            // setContent(data);
            editor.plugins.get("FileRepository").createUploadAdapter = function (
              loader
            ) {
              return new UploadAdapter(loader);
            };
          }}
        />
          </AppModal>
          {standardProgramContext?.standard?.answerWay && (
            <div>
              <div className="group cursor-pointer relative inline-block  w-28 text-center">
                {/* <img src={lamb} className="w-[25px]"/> */}
                <img className="bg-transparent" src={lamp} alt="lamp" />
                <div className="opacity-0 w-28 bg-[#292069] text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 ml-14 px-3 pointer-events-none">
                  {standardProgramContext?.standard?.answerWay == ""
                    ? "لا يوجد"
                    : standardProgramContext?.standard?.answerWay}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-7 pr-[88px] max-w-[90%] overflow-y-auto scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100 scrollGroup">
          {loading ? (
            <div className="flex border-y-2 pb-5  pt-2 ">
              <h2 className="ml-3 w-24 text-[16px] flex-shrink-0 font-bold">
                <Skeleton width="80px" />
              </h2>
              <p className="text-[17px]">
                <Skeleton width="300px" />
              </p>
            </div>
          ) : (
            <div className="flex border-y-2 pb-5  pt-2 ">
              <h2 className="ml-3 w-24 text-[16px] flex-shrink-0 font-bold">
                {standardProgramContext?.standard?.code}
              </h2>
              <p className="text-[17px]">
                {standardProgramContext?.standard?.title}
              </p>
            </div>
          )}
          <div className="flex border-b-2 pb-5 pt-2">
            <h2 className="text-[16px] ml-3 flex-shrink-0 w-24 text-[#7D8BAB]">
              الهدف
            </h2>
            {loading ? (
              <p className="text-[17px]">
                <Skeleton width="300px" />
              </p>
            ) : (
              <p className="text-[17px]">
                {standardProgramContext?.standard?.target}
              </p>
            )}
          </div>

          {/* {(localStorage?.getItem("userType") == "expert" ||
            localStorage?.getItem("userType") == "expertManager" ||
            localStorage?.getItem("userType") == "Reviewer" ||
            localStorage?.getItem("userType") == "admin") && (
            <div className="flex border-b-2 pb-5 pt-2">
              <h2 className="ml-3 w-24 text-[16px] flex-shrink-0 text-[#7D8BAB]">
                وزن المعيار
              </h2>
              {loading ? (
                <p className="text-[17px]">
                  <Skeleton width="50px" />
                </p>
              ) : (
                <p className="text-[17px]">
                  {standardProgramContext?.standard?.weight}%
                </p>
              )}
            </div>
          )} */}
          <div className="border-b-2 pb-5  pt-2 ">
            <h2 className="ml-3 text-[16px] text-[#7D8BAB]">
              الأوامر والقرارات والتعاميم المرتبطة
            </h2>
            {loading ? (
              <p className="text-[17px] px-5 mt-2">
                <Skeleton width="300px" />
              </p>
            ) : (
              <div className="text-[17px] px-5 mt-2">
                <ul>
                  {standardProgramContext?.standard?.royalOrdersAndCabinetDecisions
                    ?.split(".")
                    // .slice(0, -1)
                    .map((item) => {
                      if (!item || item == "\n" || item == "\n\n") return;
                      return (
                        <li className="leading-loose text-gray-900">
                          - {item}.
                        </li>
                      );
                    }) || "لا يوجد"}
                </ul>
              </div>
            )}
          </div>
          {/* <div className="mt-4">
            <NewSwitch
              label="المعيار لا ينطبق"
              value={standardProgramContext.donotApply}
              setValue={standardProgramContext.setDonotApply}
            ></NewSwitch>
          </div> */}
        </div>
      </div>

      <NextBtn onClick={() => setMenuElement(1)} pocAnswer={pocAnswer} />
    </>
  );
};

export default StandardAnswerData;
