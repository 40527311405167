import React, { useCallback, useState } from "react";
import { useGetAnswersReport } from "./useGetAnswersReport";
import AppBadge from "../../../../components/AppBadge";
import useExcelExport from "../../../../helpers/customHooks/useExcelExport";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import NewTable from "../../../../components/NewTable";
import { ExpandableRowsComponent } from "./components";
import HeaderWithBreadcrumbLayout from "../../../../layouts/headerWithBreadcrumbLayout";
import { programsIcon } from "../../../../assets/icons/Index";
import AnswersReportFilter from "./components/AnswersReportFilter";
import { convertHtmlToString } from "../../../../helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import AppModal from "../../../../components/Models/appModal";
import NewTooltip from "../../../../components/NewTooltip";
import AppDivider from "../../../../components/appDivider";
import { toast } from "react-toastify";

function AnswersReportPage() {
  const [currentPage, setCurrentPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: "50", value: 50 });

  const [standardProofAndItsAttachment, setStandardProofAndItsAttachment] =
    useState([]);

  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);

  const [filterInputs, setFilterInputs] = useState({
    agencyName: null,
    standardCode: null,
    auditorName: null,
    expertName: null,
  });

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const { answersReport, isGettingAnswersReport, totalPages } =
    useGetAnswersReport({
      currentPage,
      maxResult: maxResult.value,
      filterInputs,
    });

  const columns = [
    {
      name: "رمز الجهة",
      sortable: true,
      cell: (info) => (
        <div className="text-[#292069] font-semibold">{info.agencyCode}</div>
      ),
    },
    {
      name: "اسم الجهة",
      sortable: true,
      center: true,
      cell: (info) => <div className="text-center">{info.agencyName}</div>,
    },
    {
      name: "رقم المعيار",
      center: true,
      sortable: true,
      cell: (row) => <div className="text-center">{row.standardCode}</div>,
    },
    {
      name: "المرفقات",
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="flex justify-center">
          <NewTooltip content={"المرفقات"}>
            <FontAwesomeIcon
              icon={faEye}
              className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
              onClick={() => {
                setOpenAttachmentModal(true);
                setStandardProofAndItsAttachment(
                  row.standardProofAndItsAttachment
                );
              }}
            />
          </NewTooltip>
        </div>
      ),
    },
    {
      name: "إجابة الجهة",
      sortable: true,
      center: true,
      cell: (info) => (
        <div className="text-center">
          {info?.donotApply ? (
            "لا ينطبق"
          ) : info.agencyAnswer == null ? (
            "لا يوجد"
          ) : (
            <AppBadge badgeColor={info.agencyAnswerColor}>
              {info.agencyAnswer}
            </AppBadge>
          )}
        </div>
      ),
    },
  ];

  // excel

  function formatCheckedRequirementsForExport(
    requirements,
    subRequirementPropName
  ) {
    let requirementsString = "";

    requirements?.forEach((requirement, index) => {
      if (requirement?.decision && !requirement?.donotApply) {
        requirementsString += `${index + 1}. ${
          requirement?.standardRequirementTitle
        }\n`;
      }

      if (requirement?.[subRequirementPropName]) {
        requirement[subRequirementPropName].forEach(
          (subRequirement, subIndex) => {
            if (subRequirement?.decision && !subRequirement?.donotApply) {
              requirementsString += `- ${subRequirement?.subStandardRequirementTitle}\n`;
            }
          }
        );
      }

      requirementsString += "\n"; // Add an extra newline for separation
    });

    return requirementsString;
  }

  function formatUncheckedRequirementsForExport(
    requirements,
    subRequirementPropName
  ) {
    let requirementsString = "";

    requirements?.forEach((requirement, index) => {
      if (!requirement?.decision && !requirement?.donotApply) {
        requirementsString += `${index + 1}. ${
          requirement?.standardRequirementTitle
        }\n`;
      }

      if (requirement?.[subRequirementPropName]) {
        requirement[subRequirementPropName].forEach(
          (subRequirement, subIndex) => {
            if (!subRequirement?.decision && !subRequirement?.donotApply) {
              requirementsString += `- ${subRequirement?.subStandardRequirementTitle}\n`;
            }
          }
        );
      }

      requirementsString += "\n"; // Add an extra newline for separation
    });

    return requirementsString;
  }

  const [excelLoading, setExcelLoading] = useState(false);

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);

    const requestId = generateRandomString(10);
    let promise = axiosGetRequest(environment.exportAnswerReports, {
      agencyName: filterInputs.agencyName,
      StandardCode: filterInputs.standardCode,
      ReviewerName: filterInputs.auditorName,
      ExpertName: filterInputs.expertName,
      SkipCount: 0,
      MaxResultCount: 100000,
      connectionId: localStorage.getItem("connectionId"),
      requestId: requestId,
    });

    try {
      const delayedPromise = new Promise((resolve, reject) => {
        promise.then(() => {
          document.addEventListener("reportReady", (data) => {
            if (data.detail.isSuccess && data.detail.requestId === requestId) {
              resolve(data.detail.result);
            } else if (
              !data.detail.isSuccess &&
              data.detail.requestId === requestId
            ) {
              setExcelLoading(false);
              reject(data.detail);
            }
          });
        });
      });

      toast.promise(delayedPromise, {
        // position: "top-center",
        pending: "...جاري تحميل الملف قد يستغرق بعض الوقت",
        success: "تم تحميل الملف بنجاح",
        error: "حدث خطأ أثناء تحميل البيانات",
      });

      const res = await delayedPromise;
      setExcelLoading(false);
      downloadFile(res, "سجل الاٍجابات.xlsx");
    } catch (error) {
      console.log("error", error);
      setExcelLoading(false);
    }

    return;

    // const dataForExcel = res?.result?.items?.map((ele) => {
    //   return {
    //     "رمز الجهة": ele?.agencyCode,
    //     "اسم الجهة": ele?.agencyName,
    //     "رقم المعيار": ele?.standardCode,
    //     "عدد المرفقات": ele?.standardProofAndItsAttachment
    //       ?.map(
    //         (item) => `[${item.standardProofTitle} = ${item.attachmentCount}]`
    //       )
    //       .join(" - "),
    //     "إجابة الجهة": ele?.donotApply ? "لا ينطبق" : ele?.agencyAnswer,

    //     // auditor
    //     "اسم المدقق": ele?.reviewerName,
    //     "إجابة المدقق": ele?.reviewerAnswer,
    //     "تاريخ إجابة المدقق": ele?.reviewerAnswerDate,
    //     "(المدقق) الدرجة المقترحة من النظام": ele?.reviewerSuggestedDegree,
    //     "(المدقق) سبب تغيير الاٍجابة عن النظام": ele?.reviewerNote,

    //     "(المدقق) الأسئلة المتحققة": formatCheckedRequirementsForExport(
    //       ele?.reviewerRequirements,
    //       "reviewerSubRequirementAnswers"
    //     ),

    //     "(المدقق) الأسئلة غير المتحققة": formatUncheckedRequirementsForExport(
    //       ele?.reviewerRequirements,
    //       "reviewerSubRequirementAnswers"
    //     ),

    //     "ملاحظة المدقق ": convertHtmlToString(
    //       (ele?.reviewerAgencyNote?.noteTemplate?.templateContent || "") +
    //         (ele?.reviewerAgencyNote?.unCheckRequirement || "") +
    //         (ele?.reviewerAgencyNote?.content || "") +
    //         (ele?.reviewerAgencyNote?.template || "")
    //     ),
    //     "يحتاج زيارة (المدقق)": ele?.reviewerNeedVisit,

    //     // expert
    //     "اسم الخبير": ele?.expertName,
    //     "تقييم الخبير": ele?.expertAnswer,
    //     "تاريخ تقييم الخبير": ele?.expertAnswerDate,
    //     "(الخبير) الدرجة المقترحة من النظام": ele?.expertSuggestedDegree,
    //     "(الخبير) سبب تغيير الاٍجابة عن النظام": ele?.expertNote,

    //     "(الخبير) الأسئلة المتحققة": formatCheckedRequirementsForExport(
    //       ele?.expertRequirements,
    //       "expertSubRequirementAnswers"
    //     ),

    //     "(الخبير) الأسئلة غير المتحققة": formatUncheckedRequirementsForExport(
    //       ele?.expertRequirements,
    //       "expertSubRequirementAnswers"
    //     ),

    //     "ملاحظة الخبير ": convertHtmlToString(
    //       (ele?.expertAgencyNote?.noteTemplate?.templateContent || "") +
    //         (ele?.expertAgencyNote?.unCheckRequirement || "") +
    //         (ele?.expertAgencyNote?.content || "") +
    //         (ele?.expertAgencyNote?.template || "")
    //     ),
    //     "يحتاج زيارة (الخبير)": ele?.expertNeedVisit,
    //     "تمت المراجعة (الخبير)": ele?.expertIsReview,

    //     // pocOfficer
    //     "اسم ضابط الاتصال": ele?.pocName,
    //     "إجابة ضابط الاتصال": ele?.pocAnswer,
    //     "تاريخ إجابة ضابط الاتصال": ele?.pocAnswerDate,
    //     "(ضابط الاتصال) الدرجة المقترحة من النظام":
    //       ele?.pocOfficerSuggestedDegree,
    //     "(ضابط الاتصال) سبب تغيير الاٍجابة عن النظام": ele?.pocNote,

    //     "(ضابط الاتصال) الأسئلة المتحققة": formatCheckedRequirementsForExport(
    //       ele?.pocOfficerRequirements,
    //       "pocOfficerSubRequirementAnswers"
    //     ),

    //     "(ضابط الاتصال) الأسئلة غير المتحققة":
    //       formatUncheckedRequirementsForExport(
    //         ele?.pocOfficerRequirements,
    //         "pocOfficerSubRequirementAnswers"
    //       ),

    //     "ملاحظة ضابط الاتصال ": convertHtmlToString(
    //       (ele?.pocAgencyNote?.noteTemplate?.templateContent || "") +
    //         (ele?.pocAgencyNote?.unCheckRequirement || "") +
    //         (ele?.pocAgencyNote?.content || "") +
    //         (ele?.pocAgencyNote?.template || "")
    //     ),
    //     "يحتاج زيارة (ضابط الاتصال)": ele?.pocOfficerNeedVisit,
    //   };
    // });

    // downloadExcel("سجل الاٍجابات", dataForExcel);
  };

  const breadcrumbArr = [{ title: "سجل الاٍجابات", icon: programsIcon }];

  return (
    <HeaderWithBreadcrumbLayout breadcrumbArr={breadcrumbArr}>
      <div className="py-5 space-y-3">
        <AnswersReportFilter setFilterInputs={setFilterInputs} />
        <NewTable
          withMaxResultChange
          maxResultValue={maxResult}
          setMaxResultValue={setMaxResult}
          withExcel
          excelLoading={excelLoading}
          onExportExcel={handleExcelExport}
          loading={isGettingAnswersReport}
          columns={columns}
          expandableRows
          expandableRowsComponent={ExpandableRowsComponent}
          data={answersReport}
          pagination
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />

        <AppModal
          open={openAttachmentModal}
          setOpen={setOpenAttachmentModal}
          headerTitle={"المرفقات"}
          isFooter={false}
        >
          <div className="text-xl h-full pb-2 flex flex-col gap-2">
            {standardProofAndItsAttachment.map((item, index) => (
              <React.Fragment key={item.standardProofTitle}>
                <li className="mb-2 flex justify-between gap-3">
                  <p>
                    {index + 1} - {item?.standardProofTitle}
                  </p>

                  <div className="text-nowrap">
                    عدد المرفقات : {item?.attachmentCount}
                  </div>
                </li>

                {index < standardProofAndItsAttachment.length - 1 && (
                  <AppDivider />
                )}
              </React.Fragment>
            ))}
          </div>
        </AppModal>
      </div>
    </HeaderWithBreadcrumbLayout>
  );
}

export default AnswersReportPage;

function generateRandomString(length) {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function downloadFile(url, fileName) {
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
