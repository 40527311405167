import React, {useEffect, useState} from "react";
import useAxiosGetRequest from "../../../../../../helpers/customHooks/useAxiosGetRequest";
import environment from "../../../../../../services/environment";
import {headers} from "../content";
import AppTable from "../../../../../../components/table/index";
import TableSkeleton from "../../../../../../components/table/TableSkeleton";
import DeleteIcon from "../../../../../../components/DeleteIcon";
import {axiosDeleteRequest, axiosGetRequest, get} from "../../../../../../services/Request";
import {toast} from "react-toastify";
import NewTooltip from "../../../../../../components/NewTooltip";
import NewTable from "../../../../../../components/NewTable";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditIcon from "../../../../../../components/EditIcon";
import { useParams , useHistory} from "react-router";
import CustomTable from "../../../../../../components/CustomTable";
import useExcelExport from "../../../../../../helpers/customHooks/useExcelExport";

const SurveyTemplateList = () => {
  // const [getTemplates, templates, loading] = useAxiosGetRequest();
  const [totalPages,setTotalPages] = useState(null)
  const [templates,setTemplates] = useState([])
  const [currentPage, setCurrentPage] = useState(0);
  const [loading,setLoading] = useState(false)
  const [excelLoading, setExcelLoading] = useState(false);
  const { surveyId } = useParams();
  const history = useHistory()

  const downloadExcel = useExcelExport();


  const templatesData = [
    {
      name: "الرقم",
      selector: (row) => row.id,
      header: "الرقم ",
      accessorKey: "id",
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">
              {info.id}
          </span>
        </div>
      ),
    },
    {
      name: "المحتوي",
      selector: (row) => row.templateContent,
      header: "المحتوي ",
      accessorKey: "templateContent",
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">
              {convertHtmlToString(info.templateContent)}
          </span>
        </div>
      ),
    },
    {
      name: "درجات المقيم",
      selector: (row) => row.correctorDegree,
      header: "درجات المقيم ",
      accessorKey: "correctorDegree",
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">
              {info.correctorCommitmentLevels?.[0]?.commitmentLevel?.fixedDegree}
          </span>
        </div>
      ),
    },
    {
      name: "درجات الجهة",
      selector: (row) => row.agencyDegree,
      header: "درجات الجهة ",
      accessorKey: "agencyDegree",
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">
              {info.agencyCommitmentLevels?.[0]?.commitmentLevel?.fixedDegree}
          </span>
        </div>
      ),
    },
    {
      name: "حالة إرفاق ملف",
      selector: (row) => row.agencyHasAttachment,
      header: "حالة إرفاق ملف",
      accessorKey: "agencyHasAttachment",
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">
              {info.agencyHasAttachment
      ? "تم الإرفاق" : "لم يتم الإرفاق"
      }
          </span>
        </div>
      ),
    },
    {
        name: " الاجراءات",
        selector: (row) => row.settings,
        header: " الاجراءات ",
        accessorKey: "settings",
        footer: (props) => props.column.id,
        cell: (info) => (
          <div className="flex flex-row mx-5 items-center">
                 <div>
                   <NewTooltip content={"حذف"}>
                    <DeleteIcon
                      deletedName={convertHtmlToString(info.templateContent)}
                      deleteFunction={() => {
                        handleDeleteTemplate(info.id);
                      }}
                      loading={deleteLoading}
                    />
                  </NewTooltip>
                </div>
                <div>
                <NewTooltip content={"تعديل"}>
                       <button
                  onClick={()=>history.push(`/surveys/${surveyId}/settings/templates/${info.id}/edit`)}
                >
                  <FontAwesomeIcon className="text-yellow-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer" icon={faEdit} />
                </button>
                  </NewTooltip>
                </div>
                </div>

        ),
      },
  ];

  useEffect(() => {
    getAllTemplates(0)
  }, []);


  const getAllTemplates = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.getAllNoteTemplate + "?SkipCount=" + page * 6 + "&MaxResultCount=" + 6,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(res.data.error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 500) {
          toast.error(res.data.error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 200) {
          setTemplates(res.data.result.items);
          setTotalPages(Math.ceil(res.data.result.totalCount / 6));
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      }
    );
  };


  function convertHtmlToString(htmlString) {
    var tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText;
  }

  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteTemplate = async (id) => {
    setDeleteLoading(true);
    const res = await axiosDeleteRequest(environment.deleteNoteTemplate, {
      id,
    });
    setDeleteLoading(false);
    if (res?.success) {
      toast.success("تم الحذف بنجاح");
      getAllTemplates(0);
    }
  };


    const handlePageClick = (event) => {
      console.log(event.selected);
      const newOffset = event.selected;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setCurrentPage(newOffset);
      getAllTemplates(newOffset);
    };

    function cleanHTML(html) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText || '';
    }


    const handleExcelExport = async () => {
      setExcelLoading(true);
      const res = await axiosGetRequest(environment.getAllNoteTemplate, {
        SkipCount: 0,
        MaxResultCount: 1000000,
      });
  
      setExcelLoading(false);
      console.log(res)
  
      const dataForExcel = res?.result?.items?.map((ele) => {
        return {
          numberTemplate: ele.id,
          templateContent: cleanHTML(ele.templateContent),
          degreeCorrector: ele.correctorCommitmentLevels[0]?.commitmentLevel?.fixedDegree,
          degreeAgency: ele.agencyCommitmentLevels[0]?.commitmentLevel?.fixedDegree,
          agencyHasAttachment: ele.agencyHasAttachment ? "تم الإرفاق" : "لم يتم الإرفاق",
        };
      });
  
      downloadExcel("القوالب", dataForExcel);
    };


  return (
    <div className="mt-4">
        <NewTable
        columns={templatesData}
        data={templates}
        pagination={true}
        loading={loading}
        currentPage={currentPage}
        totalPages={totalPages}
        withExcel
        excelLoading={excelLoading}
        onPageChange={handlePageClick}
        onExportExcel={handleExcelExport}

      />
    </div>
  );
};

export default SurveyTemplateList;
