import React, { useContext } from "react";
import StandardSubRequirementItem from "./subRequirementItem";
import { AddStandardContext } from "../../addStandardContext";
import { axiosGetRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { uniqueBy } from "../../../../../../../helpers/utils";
import { errorToast } from "../../../../../../../helpers/toast";
import { useParams } from "react-router";

const SubRequirementList = ({ subReq, requirementIndx, disabled }) => {
  const { addFinalValue, setQuestionsBank } = useContext(AddStandardContext);
  const { interlocutorId } = useParams();

  const getQuestionBank = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("MaxResultCount", "10000");
    searchParams.append("interlocutorId", interlocutorId);
    
    try {
      const res = await axiosGetRequest(environment.questionBankGetAll + '?' + searchParams.toString());
      setQuestionsBank(uniqueBy(res?.result?.items ?? [], "title"));
    } catch (err) {
      errorToast("حدث خطأ في بنك الأسئلة");
    }
  };

  const renderedSubRequirements =
    addFinalValue.standardRequirements[requirementIndx].subStandardRequirements
      ?.length > 0 &&
    addFinalValue.standardRequirements[
      requirementIndx
    ].subStandardRequirements.map((item, indx) => {
      return (
        <StandardSubRequirementItem
          {...item}
          key={indx}
          index={indx}
          number={indx + 1}
          disabled={disabled}
          requirementIndx={requirementIndx}
          getQuestionBank={getQuestionBank}
        />
      )
    });

  return (
    <div>
      {renderedSubRequirements || (
        <p className="text-sm">
          لا يوجد أسئلة
        </p>
      )}
    </div>
  );
};

export default SubRequirementList;
