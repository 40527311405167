export function showRequirement(requirement) {
  // support for requirements without subStandardRequirements

  if(requirement.title === "specialTextDonTShowIt" || requirement.standardRequirementTitle === "specialTextDonTShowIt"){
    return false
  }

  if(requirement.expertSubRequirementAnswers) {
    requirement.subStandardRequirements = requirement.expertSubRequirementAnswers;
  }

  if (
    !requirement.subStandardRequirements ||
    requirement.subStandardRequirements.length === 0
  ) {
    return (
      (requirement.decision == 0 || !requirement.decision) &&
      requirement.donotApply == false
    );
  } else {
    return !!(
      (requirement.decision == 0 || !requirement.decision) &&
      requirement.donotApply == false &&
      requirement.subStandardRequirements.find(
        (subReq) =>
          subReq.donotApply == false &&
          (subReq.decision == 0 || !subReq.decision)
      )
    );
  }
}

export function getCommitmentLevelsBasedOnDegree( degree, commitmentLevels ){
  const commitmentLevel = commitmentLevels.find((level) => level.rangeFrom <= degree && level.rangeTo >= degree);
  return commitmentLevel?.title;
}


export function checkTheDummyDimensionName(name){
  return name === "specialTextDonTShowIt"
}