import {t} from "i18next";
import {useHistory, useParams} from "react-router";

import SecondaryTapsLayout from "../../../../../layouts/secondaryTapsLayout";
import {serveySettingTabs} from "../data";
import {Title} from "../../../../../components";
import {programsIcon} from "../../../../../assets/icons/Index";
import SurveySidesList from "./sidesList";

const SurveySideList = (props) => {
  const {surveyId} = useParams();
  const history = useHistory();

  return (
    <SecondaryTapsLayout
      linksArr={serveySettingTabs(`/surveys/${surveyId}`)}
      breadcrumbs={
        <div className="-mx-5">
          <Title
            iconTitle={programsIcon}
            subTitle={"اعدادات الإستبیان"}
            thired={true}
            subTitleSeconed={"أبعاد التقييم"}
            title={t("المؤشرات")}
            toPage={() => history.push(`/indicators`)}
            toPageSeconed={() => history.push(`/indicators/${localStorage.getItem("NavIndicatorId") || null}`)}
            withoutButton={true}
          />
        </div>
      }
    >
      <SurveySidesList/>
    </SecondaryTapsLayout>
  );
};

export default SurveySideList;
