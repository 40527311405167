import { useState } from "react";
import { programsIcon } from "../../assets/icons/Index";
import AppDivider from "../../components/appDivider";
import HeaderWithBreadcrumbLayout from "../../layouts/headerWithBreadcrumbLayout";
import AgencyAnswer from "./AgencyAnswer";
import AgencyAttachments from "./AgencyAttachments";
import AuditsTabs from "./AuditsTabs";
import QiyasAnswer from "./QiyasAnswer";
import { useParams } from "react-router-dom";
import { useGetFinalStandardAnswersDetailsForAgency } from "./useGetFinalStandardAnswersDetailsForAgency";
import Skeleton from "react-skeleton-loader";
import FinalAnswersSkeleton from "./skeleton/FinalAnswersSkeleton";

function AgencyAnswerDetails() {
  const { standardId, agencyId } = useParams();

  const [currentPage, setCurrentPage] = useState(0);

  const {
    data,
    agencyDetails,
    isGettingAgencyDetails,
    totalPages,
    totalCount,
  } = useGetFinalStandardAnswersDetailsForAgency({
    currentPage,
    standardId,
    agencyId,
  });

  const agencyAnswerDetailsBreadcumbs = [
    {
      icon: programsIcon,
      title: isGettingAgencyDetails ? (
        <div className="mt-3">
          <Skeleton height="20px" width="100px" />
        </div>
      ) : (
        `معيار ${data?.result?.standardCode}`
      ),
      url: "",
    },
    {
      title: isGettingAgencyDetails ? (
        <div className="mt-3">
          <Skeleton height="20px" width="100px" />
        </div>
      ) : (
        `${data?.result?.agencyNameInArabic}`
      ),
    },
  ];

  return (
    <HeaderWithBreadcrumbLayout breadcrumbArr={agencyAnswerDetailsBreadcumbs}>
      <div className="flex flex-col gap-3 mx-3 my-7">
        {isGettingAgencyDetails ? (
          <FinalAnswersSkeleton />
        ) : (
          <></>
          // <div className="flex justify-between items-center bg-gray-100 p-5">
          //   {/* <AgencyAnswer
          //     finalAgencyAnswer={data?.result?.finalAgencyAnswer}
          //     badgeColor={data?.result?.finalAgencyAnswerColor}
          //   /> */}
          //   {/* <QiyasAnswer
          //     badgeColor={data?.result?.finalNationalQiyasTeamAnswerColor}
          //     finalNationalQiyasTeamAnswer={
          //       data?.result?.finalNationalQiyasTeamAnswer
          //     }
          //   /> */}
          // </div>
        )}

        <AppDivider />

        <AgencyAttachments
          agencyName={data?.result?.agencyNameInArabic}
          loading={isGettingAgencyDetails}
          attachedFiles={data?.result?.attachedFiles}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />

        <AppDivider />

        <AuditsTabs agencyId={agencyId} standardId={standardId} />
      </div>
    </HeaderWithBreadcrumbLayout>
  );
}

export default AgencyAnswerDetails;
