import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAnswerContext } from "../../answerContext";
import ReadOnlyRequirements from "../../components/readOnlyRequirements";
import StandardAnswerRequirement from "../../standardAnswerRequirements";
import MainRequirement from "../../standardAnswerRequirements/ui/mainRequirement";
import SubRequirement from "../../standardAnswerRequirements/ui/subRequirement";
import Tabs from "../Tabs";
import AgencyRequirementAnswer from "../Agency/agencyRequirementAnswer";

const ExpertRequirementAnswer = ({ setMenuElement }) => {
  const { standardId, entityId } = useParams();
  const { getSelfEvaluation, newExpertAnswer, setNewExpertAnswer } =
    useAnswerContext();

  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState("expert");

  const handleRequirementChange = (requirement, property = "decision") => {
    const updatedRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((subReq) => {
              return property === "decision"
                ? {
                    ...subReq,
                    decision: requirement?.decision ? 0 : 1,
                    donotApply: 0,
                  }
                : {
                    ...subReq,
                    donotApply: !requirement?.donotApply,
                    decision: !requirement?.donotApply
                      ? 0
                      : requirement?.decision,
                  };
            });

          return property === "decision"
            ? {
                ...requirement,
                subStandardRequirements,
                decision: requirement?.decision ? 0 : 1,
                donotApply: 0,
              }
            : {
                ...requirement,
                subStandardRequirements,
                donotApply: !requirement?.donotApply,
                decision: !requirement?.donotApply ? 0 : requirement?.decision,
              };
        }

        return item;
      }
    );

    getSelfEvaluation(standardId, updatedRequirements, entityId);

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const handleSubRequirementChange = (
    requirement,
    subReqId,
    property = "decision"
  ) => {
    const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return property === "decision"
                  ? {
                      ...subRequire,
                      decision: subRequire?.decision ? 0 : 1,
                      donotApply: 0,
                    }
                  : {
                      ...subRequire,
                      donotApply: !subRequire?.donotApply,
                      decision: !subRequire?.donotApply
                        ? 0
                        : subRequire?.decision,
                    };
              }

              return subRequire;
            }
          );

          const isAllSubReqChecked = newSubReqs?.every(
            (value) => value?.decision == 1
          );
          const isAllSubReqDonotApply = newSubReqs?.every(
            (value) => value?.donotApply === true
          );

          console.log(isAllSubReqChecked);

          if (isAllSubReqChecked) requirement.decision = 1;
          else requirement.decision = 0;
          if (isAllSubReqDonotApply) requirement.donotApply = true;
          else requirement.donotApply = false;

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );
    getSelfEvaluation(standardId, updatedSubRequirements, entityId);

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };


  const renderedExpertRequirements =
    newExpertAnswer?.standardRequirements?.length > 0 &&
    newExpertAnswer?.standardRequirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {
              handleRequirementChange(requirement);
            }}
            handleDonotApplyRequirementChange={() =>
              handleRequirementChange(requirement, "donotApply")
            }
          >
            {hasSubRequirements &&
              requirement?.subStandardRequirements?.map((subRequirement) => {
                return (
                  <>
                    <SubRequirement
                      key={subRequirement?.id}
                      title={subRequirement?.title}
                      checked={subRequirement?.decision}
                      onChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id
                        )
                      }
                      subRequirementDonotApplyChecked={
                        subRequirement?.donotApply
                      }
                      handleDonotApplySubRequirementChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id,
                          "donotApply"
                        )
                      }
                    />
                  </>
                );
              })}
          </MainRequirement>
        </>
      );
    });

  return (
    <Tabs.TabList defaultActiveTab={1}>
      <Tabs.Tab title="فتح النظام">
        <div className="w-full mb-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
          <StandardAnswerRequirement setMenuElement={setMenuElement}>
            <AgencyRequirementAnswer />
          </StandardAnswerRequirement>
        </div>
      </Tabs.Tab>
      <Tabs.Tab title="مرحلة التحقق">
        <div className="w-full mb-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
          <div>
            <StandardAnswerRequirement setMenuElement={setMenuElement}>
              <div className="flex py-5 w-full justify-center">
                <div className="flex bg-[#F9F9FF] py-2 px-8 rounded-3xl">
                  <p
                    className={
                      selectedRequirementsTab == "reviewer"
                        ? "bg-[#2B47AD] text-white px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer"
                        : "bg-transparent px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer"
                    }
                    onClick={() => setSelectedRequirementsTab("reviewer")}
                  >
                    المدقق
                  </p>

                  <p
                    className={
                      selectedRequirementsTab == "expert"
                        ? "bg-[#2B47AD] text-white px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer"
                        : "bg-transparent px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer"
                    }
                    onClick={() => setSelectedRequirementsTab("expert")}
                  >
                    الخبير
                  </p>
                </div>
              </div>

              {selectedRequirementsTab === "reviewer" && (
                <ReadOnlyRequirements
                  requirements={newExpertAnswer?.reviewerRequirementsAnswer}
                />
              )}
            </StandardAnswerRequirement>
          </div>
        </div>
      </Tabs.Tab>
    </Tabs.TabList>
  );
};

export default ExpertRequirementAnswer;
