import React, { useEffect, useState } from "react";
import StandardAnswerRequirement from "../../standardAnswerRequirements";
import MainRequirement from "../../standardAnswerRequirements/ui/mainRequirement";
import SubRequirement from "../../standardAnswerRequirements/ui/subRequirement";
import { useAnswerContext } from "../../answerContext";
import { useParams } from "react-router-dom";
import AppTextarea from "../../../../../../../components/form/appTextarea";
import AgencyRequirementAnswer from "../Agency/agencyRequirementAnswer";
import ReadOnlyRequirements from "../../components/readOnlyRequirements";
import {
  isAdminFunc,
  isExpertManager,
  isInquiryManager,
} from "../../../../../../../helpers/isPermission";
import Tabs from "../Tabs";

const ExpertManagerRequirementAnswer = ({ setMenuElement }) => {
  const { standardId, entityId } = useParams();
  const {
    getSelfEvaluation,
    newExpertManagerAnswer,
    setNewExpertManagerAnswer,
  } = useAnswerContext();

  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState("expertManager");

  const handleRequirementChange = (requirement, property = "decision") => {
    const updatedRequirements =
      newExpertManagerAnswer?.standardRequirements?.map((item) => {
        if (item?.id === requirement?.id) {
          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((subReq) => {
              return property === "decision"
                ? {
                    ...subReq,
                    decision: requirement?.decision ? 0 : 1,
                    donotApply: 0,
                  }
                : {
                    ...subReq,
                    donotApply: !requirement?.donotApply,
                    decision: !requirement?.donotApply
                      ? 0
                      : requirement?.decision,
                  };
            });

          return property === "decision"
            ? {
                ...requirement,
                subStandardRequirements,
                decision: requirement?.decision ? 0 : 1,
                donotApply: 0,
              }
            : {
                ...requirement,
                subStandardRequirements,
                donotApply: !requirement?.donotApply,
                decision: !requirement?.donotApply ? 0 : requirement?.decision,
              };
        }

        return item;
      });

    getSelfEvaluation(
      standardId,
      updatedRequirements,
      entityId,
      "expertManager"
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const handleSubRequirementChange = (
    requirement,
    subReqId,
    property = "decision"
  ) => {
    const updatedSubRequirements =
      newExpertManagerAnswer?.standardRequirements?.map((item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return property === "decision"
                  ? {
                      ...subRequire,
                      decision: subRequire?.decision ? 0 : 1,
                      donotApply: 0,
                    }
                  : {
                      ...subRequire,
                      donotApply: !subRequire?.donotApply,
                      decision: !subRequire?.donotApply
                        ? 0
                        : subRequire?.decision,
                    };
              }

              return subRequire;
            }
          );

          const isAllSubReqChecked = newSubReqs?.every(
            (value) => value?.decision == 1
          );
          const isAllSubReqDonotApply = newSubReqs?.every(
            (value) => value?.donotApply === true
          );

          console.log(isAllSubReqChecked);

          if (isAllSubReqChecked) requirement.decision = 1;
          else requirement.decision = 0;
          if (isAllSubReqDonotApply) requirement.donotApply = true;
          else requirement.donotApply = false;

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      });
    getSelfEvaluation(
      standardId,
      updatedSubRequirements,
      entityId,
      "expertManager"
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSelectAllReqs = (action = "selectAll") => {
    const updatedRequirements =
      newExpertManagerAnswer?.standardRequirements?.map((req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            ...subReq,
            donotApply: 0,
            decision: action === "selectAll" ? 1 : 0,
          })
        );

        return {
          ...req,
          subStandardRequirements,
          donotApply: 0,
          decision: action === "selectAll" ? 1 : 0,
        };
      });

    getSelfEvaluation(
      standardId,
      updatedRequirements,
      entityId,
      "expertManager"
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const renderedExpertManagerRequirements =
    newExpertManagerAnswer?.standardRequirements?.length > 0 &&
    newExpertManagerAnswer?.standardRequirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {
              handleRequirementChange(requirement);
            }}
            handleDonotApplyRequirementChange={() =>
              handleRequirementChange(requirement, "donotApply")
            }
          >
            {hasSubRequirements &&
              requirement?.subStandardRequirements?.map((subRequirement) => {
                return (
                  <>
                    <SubRequirement
                      key={subRequirement?.id}
                      title={subRequirement?.title}
                      checked={subRequirement?.decision}
                      onChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id
                        )
                      }
                      subRequirementDonotApplyChecked={
                        subRequirement?.donotApply
                      }
                      handleDonotApplySubRequirementChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id,
                          "donotApply"
                        )
                      }
                    />
                    {/* <div className="px-6">
                      <AppTextarea
                        placeholder="اكتب توصية"
                        style={{
                          marginBottom: "24px",
                          backgroundColor: "#fafafa",
                        }}
                        value={subRequirement?.note || ""}
                        onChange={(e) =>
                          handleSubRequirementNoteChange(
                            e,
                            requirement,
                            subRequirement?.subStandardRequirementId
                          )
                        }
                      />
                    </div> */}
                  </>
                );
              })}
          </MainRequirement>

          {/* <div className="px-5 w-full">
            <AppTextarea
              placeholder="اكتب توصية"
              style={{ marginBottom: "24px" }}
              value={requirement?.note || ""}
              onChange={(e) => handleRequirementNoteChange(e, requirement)}
            />
          </div> */}
        </>
      );
    });

  return (
    <Tabs.TabList defaultActiveTab={1}>
      <Tabs.Tab title="فتح النظام">
        <div className="w-full mb-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
          <StandardAnswerRequirement setMenuElement={setMenuElement}>
            <AgencyRequirementAnswer />
          </StandardAnswerRequirement>
        </div>
      </Tabs.Tab>
      <Tabs.Tab title="مرحلة التحقق">
        <div className="w-full mb-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
          <div>
            <StandardAnswerRequirement setMenuElement={setMenuElement}>
              <div className="flex py-5 w-full justify-center">
                <div className="flex bg-[#F9F9FF] py-2 px-8 rounded-3xl">
                  <p
                    className={
                      selectedRequirementsTab == "reviewer"
                        ? "bg-[#2B47AD] text-white px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer"
                        : "bg-transparent px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer"
                    }
                    onClick={() => setSelectedRequirementsTab("reviewer")}
                  >
                    المدقق
                  </p>

                  <p
                    className={
                      selectedRequirementsTab == "expert"
                        ? "bg-[#2B47AD] text-white px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer"
                        : "bg-transparent px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer"
                    }
                    onClick={() => setSelectedRequirementsTab("expert")}
                  >
                    الخبير
                  </p>
                  {/* <p
                    className={
                      selectedRequirementsTab == "pocOfficer"
                        ? "bg-[#2B47AD] text-white px-5 pb-2.5  rounded-2xl cursor-pointer"
                        : "bg-transparent px-5 pb-2.5  rounded-2xl  cursor-pointer"
                    }
                    onClick={() => setSelectedRequirementsTab("pocOfficer")}
                  >
                    ضابط الاتصال
                  </p> */}
                  <p
                    className={
                      selectedRequirementsTab == "expertManager"
                        ? "bg-[#2B47AD] text-white px-5 pb-2.5  rounded-2xl cursor-pointer"
                        : "bg-transparent px-5 pb-2.5  rounded-2xl  cursor-pointer"
                    }
                      onClick={() => setSelectedRequirementsTab("expertManager")}
                    >
                    رئيس الخبراء
                  </p>
                </div>
              </div>

              {selectedRequirementsTab === "reviewer" && (
                <ReadOnlyRequirements
                  requirements={newExpertManagerAnswer?.reviewerRequirementsAnswer}
                />
              )}
              {selectedRequirementsTab === "expert" && (
                <ReadOnlyRequirements
                  requirements={newExpertManagerAnswer?.expertRequirementsAnswer}
                />
              )}
              {/* {selectedRequirementsTab === "pocOfficer" && (
                <ReadOnlyRequirements
                  requirements={newExpertManagerAnswer?.pocOfficerRequirementsAnswer}
                />
              )} */}

              {!isExpertManager() &&
                (isAdminFunc() || isInquiryManager()) &&
                selectedRequirementsTab === "expertManager" && (
                  <ReadOnlyRequirements
                    requirements={newExpertManagerAnswer?.standardRequirements}
                  />
                  // <>
                  //   <div className="flex justify-end w-full gap-4">
                  //     <p
                  //       onClick={() => handleSelectAllReqs()}
                  //       className="mb-6 cursor-pointer text-end text-emerald-500"
                  //     >
                  //       تحديد الكل
                  //     </p>
                  //     <p
                  //       onClick={() => handleSelectAllReqs("cancel")}
                  //       className="mb-6 cursor-pointer text-end text-red-800"
                  //     >
                  //       الغاء تحديد الكل
                  //     </p>
                  //   </div>
                  //   {renderedExpertManagerRequirements}
                  // </>
                )}
              {isExpertManager() &&
                !isAdminFunc() &&
                !isInquiryManager() &&
                selectedRequirementsTab === "expertManager" && (
                  <>
                    <div className="flex justify-end w-full gap-4">
                      <p
                        onClick={() => handleSelectAllReqs()}
                        className="mb-6 cursor-pointer text-end text-emerald-500"
                      >
                        تحديد الكل
                      </p>
                      <p
                        onClick={() => handleSelectAllReqs("cancel")}
                        className="mb-6 cursor-pointer text-end text-red-800"
                      >
                        الغاء تحديد الكل
                      </p>
                    </div>
                    {renderedExpertManagerRequirements}
                  </>
                )}

              {/* {
                isReviewerRequirement ? (
                  
                ) : (
                  <AgencyRequirementAnswer />
                )

                // renderedAgencyRequirements
              } */}
            </StandardAnswerRequirement>
          </div>
        </div>
      </Tabs.Tab>
    </Tabs.TabList>
  );
};

export default ExpertManagerRequirementAnswer;
