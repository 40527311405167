import './Component.css';
import Switch from 'react-switch';
import NewTooltip from './NewTooltip';

const Title = ({
  title,
  onClick,
  style,
  titleButton,
  icon,
  withIcon,
  textStyle,
  withoutButton,
  subTitle,
  single,
  toPage,
  handleChangeSwitch,
  checked,
  withSwitch,
  seconed,
  thired,
  four,
  toPageFour,
  toPageSeconed,
  toPageThired,
  subTitleFive,
  subTitleFour,
  subTitleSeconed,
  subTitleThired,
  toPageFive,
  subTitleLast,
  five,
  iconTitle,
  withoutButtonSeconed,
  onClickSeconed,
  titleButtonSeconed,
  withSendSwitch,
  sendSwitch,
}) => {

  const showFullTitle = !subTitle && !subTitleThired && !subTitleFour && !subTitleFive && !subTitleLast;
  return (
    <div className="bg-[#F9F9FF] flex md:flex-row flex-col items-center justify-between rounded-[30px] p-5 m-5">
      <div className="flex flex-row items-center mb-2 md:mb-0">
        <img
          src={iconTitle}
          alt="page icon"
          className="w-[26px] h-[30px] bg-transparent ml-3 "
        />
        {single ? (
          <div className="flex flex-row items-center">
            <span className={"text-[18px] text-[#251E5C]"}>{title}</span>
          </div>
        ) : seconed ? (
          <div className="flex flex-row items-center">
            <span
              className={"text-[18px] text-[#251E5C] cursor-pointer"}
              onClick={toPage}
            >
              {title?.length > 20 ? title.slice(0, 20) + "  " + "..." : title}
            </span>
            <span className="mr-5 ml-5">/</span>
            <NewTooltip content={subTitle}>
              <span className={"text-[18px] text-[#251E5C] "}>
                {subTitle?.length > 20
                  ? subTitle.slice(0, 20) + "  " + "..."
                  : subTitle}
              </span>
            </NewTooltip>
          </div>
        ) : thired ? (
          <div className="flex flex-row items-center">
            <span
              className={"text-[18px] text-[#251E5C] cursor-pointer"}
              onClick={toPage}
            >
              {title?.length > 20 ? title.slice(0, 20) + "  " + "..." : title}
            </span>
            <span className="mr-5 ml-5">/</span>
            <NewTooltip content={subTitle}>
              <span
                className={"text-[18px] text-[#251E5C] cursor-pointer"}
                onClick={toPageSeconed}
              >
                {subTitle?.length > 20
                  ? subTitle.slice(0, 20) + "  " + "..."
                  : subTitle}
              </span>
            </NewTooltip>
            <span className="mr-5 ml-5">/</span>
            <NewTooltip content={subTitleSeconed}>
              <span className={"text-[18px] text-[#251E5C]"}>
                {subTitleSeconed?.length > 20
                  ? subTitleSeconed.slice(0, 20) + "  " + "..."
                  : subTitleSeconed}
              </span>
            </NewTooltip>
          </div>
        ) : four ? (
          <div className="flex flex-row items-center">
            <span
              className={"text-[18px] text-[#251E5C] cursor-pointer"}
              onClick={toPage}
            >
              {title?.length > 20 ? title.slice(0, 20) + "  " + "..." : title}
            </span>
            <span className="mr-5 ml-5">/</span>
            <span
              className={"text-[18px] text-[#251E5C] cursor-pointer"}
              onClick={toPageSeconed}
            >
              {subTitle?.length > 20
                ? subTitle.slice(0, 20) + "  " + "..."
                : subTitle}
            </span>
            <span className="mr-5 ml-5">/</span>
            <span
              className={"text-[18px] text-[#251E5C] cursor-pointer"}
              onClick={toPageThired}
            >
              {subTitleThired?.length > 20
                ? subTitleThired.slice(0, 20) + "  " + "..."
                : subTitleThired}
            </span>
            <span className="mr-5 ml-5">/</span>
            <span className={"text-[18px] text-[#251E5C]"}>
              {subTitleFour?.length > 20
                ? subTitleFour.slice(0, 20) + "  " + "..."
                : subTitleFour}
            </span>
          </div>
        ) : five ? (
          <div className="flex flex-row items-center">
            <span
              className={"text-[18px] text-[#251E5C] cursor-pointer"}
              onClick={toPage}
            >
              {title?.length > 20 ? title.slice(0, 20) + "  " + "..." : title}
            </span>
            <span className="mr-5 ml-5">/</span>
            <span
              className={"text-[18px] text-[#251E5C] cursor-pointer"}
              onClick={toPageSeconed}
            >
              {subTitle?.length > 20
                ? subTitle.slice(0, 20) + "  " + "..."
                : subTitle}
            </span>
            <span className="mr-5 ml-5">/</span>
            <span
              className={"text-[18px] text-[#251E5C] cursor-pointer"}
              onClick={toPageThired}
            >
              {subTitleThired?.length > 20
                ? subTitleThired.slice(0, 20) + "  " + "..."
                : subTitleThired}
            </span>
            <span className="mr-5 ml-5">/</span>
            <span
              className={"text-[18px] text-[#251E5C] cursor-pointer"}
              onClick={toPageFour}
            >
              {subTitleFour?.length > 20
                ? subTitleFour.slice(0, 20) + "  " + "..."
                : subTitleFour}
            </span>
            <span className="mr-5 ml-5">/</span>
            <span className={"text-[18px] text-[#251E5C]"}>
              {subTitleFive?.length > 20
                ? subTitleFive.slice(0, 20) + "  " + "..."
                : subTitleFive}
            </span>
          </div>
        ) : (
          <div className="flex flex-row items-center">
            <span
              className={"text-[18px] text-[#251E5C] cursor-pointer"}
              title={title || ''}
              onClick={toPage}
            >
              {title?.length > 20 && !showFullTitle ? title.slice(0, 20) + "  " + "..." : title}
            </span>
            {
              subTitle && <>
                <span className="mr-5 ml-5">/</span>
                <span
                  className={"text-[18px] text-[#251E5C] cursor-pointer"}
                  title={subTitle || ''}
                  onClick={toPageSeconed}
                >
                  {subTitle?.length > 20
                    ? subTitle.slice(0, 20) + "  " + "..."
                    : subTitle}
                </span>
              </>
            }
            {
              subTitleThired && <>
                <span className="mr-5 ml-5">/</span>
                <span
                  className={"text-[18px] text-[#251E5C] cursor-pointer"}
                  title={subTitleThired || ''}
                  onClick={toPageThired}
                >
                  {subTitleThired?.length > 20
                    ? subTitleThired.slice(0, 20) + "  " + "..."
                    : subTitleThired}
                </span>
              </>
            }
            {
              subTitleFour && <>
                <span className="mr-5 ml-5">/</span>
                <span
                  className={"text-[18px] text-[#251E5C] cursor-pointer"}
                  title={subTitleFour || ''}
                  onClick={toPageFour}
                >
                  {subTitleFour?.length > 20
                    ? subTitleFour.slice(0, 20) + "  " + "..."
                    : subTitleFour}
                </span>
              </>
            }
            {
              subTitleFive && <>
                <span className="mr-5 ml-5">/</span>
                <span
                  className={"text-[18px] text-[#251E5C] cursor-pointer"}
                  title={subTitleFive || ''}
                  onClick={toPageFive}
                >
                  {subTitleFive?.length > 20
                    ? subTitleFive.slice(0, 20) + "  " + "..."
                    : subTitleFive}
                </span>

              </>
            }
            {
              subTitleLast && <>
                <span className="mr-5 ml-5">/</span>
                <span className={"text-[18px] text-[#251E5C]"} title={subTitleLast || ''}>
                  {subTitleLast?.length > 20
                    ? subTitleLast.slice(0, 20) + "  " + "..."
                    : subTitleLast}
                </span>
              </>
            }
          </div>
        )}
      </div>
      <div>
        {withoutButtonSeconed ? (
          <button
            className="mr-2 ml-2 border border-[#1CC081] p-[12px] relative z-0 inline-flex items-center justify-start overflow-hidden transition-all bg-[#1CC081] rounded-[30px] hover:border-[#1CC081] hover:bg-red group"
            onClick={onClickSeconed}
          >
            <span className="w-0 h-0 text-white rounded bg-[#1CC081] group-hover:border-[#1CC081] absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
            <span className="w-full text-white transition-colors duration-300 ease-in-out group-hover:text-white z-10">
              <div className="flex flex-row items-center justify-around">
                <span
                  className={"text-white group-hover:text-white text-[14px]"}
                >
                  {titleButtonSeconed}
                </span>
              </div>
            </span>
          </button>
        ) : null}
        {withoutButton ? null : (
          <button
            className=" border border-[#1CC081] p-[12px] relative z-0 inline-flex items-center justify-start overflow-hidden transition-all bg-[#1CC081] rounded-[30px] hover:border-[#1CC081] hover:bg-red group"
            onClick={onClick}
          >
            <span className="w-0 h-0 text-white rounded bg-[#1CC081] group-hover:border-[#1CC081] absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
            <span className="w-full text-white transition-colors duration-300 ease-in-out group-hover:text-white z-10">
              <div className="flex flex-row items-center justify-around">
                <span
                  className={"text-white group-hover:text-white text-[14px]"}
                >
                  {titleButton}
                </span>
              </div>
            </span>
          </button>
        )}
        {withSwitch ? (
          <Switch onChange={handleChangeSwitch} checked={checked} />
        ) : null}

        {withSendSwitch ? sendSwitch : null}
      </div>
    </div>
  );
};

export default Title;
