import { isInquiryManager } from "../../../../../../../helpers/isPermission";
import { axiosGetRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { noAnswerDegreeId, useAnswerContext } from "../../answerContext";
import usePrevAgencyAnswer from "./usePrevAgencyAnswer";

export const getDegrees = async (standardId) => {
  let degreeValues = [];
  const degreesResult = await axiosGetRequest(environment.getCommitmentLevels, {
    standardId,
    StageId: localStorage.getItem("surveyStageId"),
  });

  if (degreesResult?.success) {
    degreeValues = degreesResult?.result?.map((degree) => ({
      ...degree,
      label: degree.title,
      value: degree.id,
    }));
  }

  const surveyDegrees = [...degreeValues];

  return surveyDegrees;
};

const usePrevExpertManagerAnswer = () => {
  const { getPrevAgencyAnswer } = usePrevAgencyAnswer();

  const {
    setLoadPreExpertManagerAnswer,
    setNewExpertManagerAnswer,
    setExpertManagerAnswer,
    expertManagerDegree,
    newExpertManagerAnswer,
    setExpertManagerDegree,
  } = useAnswerContext();

  // const { currentStage } = useContext(StageContext);

  const getPrevExpertManagerAnswer = async (
    standardId,
    entityId,
    currentStage
  ) => {
    setLoadPreExpertManagerAnswer(true);

    const agencyAnswer = await getPrevAgencyAnswer(
      standardId,
      entityId,
      currentStage
    );

    const res = await axiosGetRequest(environment.getExpertManagerAnswer, {
      AgencyId: entityId,
      StageId: currentStage,
      StandardId: standardId,
      ExpertManagerId: localStorage.getItem("userId"),
    });

    // if (res?.result?.lastAnsweredReviewerId) {
    //   agencyAnswer = await getPrevReviewerAnswer(
    //     standardId,
    //     entityId,
    //     currentStage,
    //     res?.result?.lastAnsweredReviewerId
    //   );
    //   setNewExpertManagerAnswer(agencyAnswer);
    // } else {

    setNewExpertManagerAnswer(agencyAnswer);
    // }

    const reviewerRequirementsAnswer = agencyAnswer?.standardRequirements?.map(
      (requirement) => {
        const reqAnswer =
          res?.result?.lastAnswer?.reviewerRequirementAnswers?.find(
            (reqAns) => reqAns.standardRequirementId === requirement?.id
          );

        if (reqAnswer?.reviewerSubRequirementAnswers) {
          const { reviewerSubRequirementAnswers, ...reqs } = reqAnswer;

          const subRequirements = requirement?.subStandardRequirements?.map(
            (subReq) => {
              const sub = reviewerSubRequirementAnswers?.find(
                (item) => subReq?.id === item?.subStandardRequirementId
              );

              return {
                ...sub,
                title: subReq?.title,
                id: subReq.id,
              };
            }
          );

          return {
            ...reqs,
            title: requirement?.title,
            id: requirement.id,
            recommendation: "",
            subStandardRequirements: [...subRequirements],
          };
        }

        const subStandardRequirements =
          requirement?.subStandardRequirements?.map((item) => ({
            ...item,
            decision: 0,
            donotApply: false,
          }));
        return {
          ...requirement,
          decision: 0,
          donotApply: false,
          subStandardRequirements,
        };
      }
    );
    const pocOfficerRequirementsAnswer =
      agencyAnswer?.standardRequirements?.map((requirement) => {
        const reqAnswer =
          res?.result?.lastAnswer?.pocOfficerRequirementAnswers?.find(
            (reqAns) => reqAns.standardRequirementId === requirement?.id
          );

        if (reqAnswer?.pocOfficerSubRequirementAnswers) {
          const { pocOfficerSubRequirementAnswers, ...reqs } = reqAnswer;

          const subRequirements = requirement?.subStandardRequirements?.map(
            (subReq) => {
              const sub = pocOfficerSubRequirementAnswers?.find(
                (item) => subReq?.id === item?.subStandardRequirementId
              );

              return {
                ...sub,
                title: subReq?.title,
                id: subReq.id,
              };
            }
          );

          return {
            ...reqs,
            title: requirement?.title,
            id: requirement.id,
            recommendation: "",
            subStandardRequirements: [...subRequirements],
          };
        }

        const subStandardRequirements =
          requirement?.subStandardRequirements?.map((item) => ({
            ...item,
            decision: 0,
            donotApply: false,
          }));
        return {
          ...requirement,
          decision: 0,
          donotApply: false,
          subStandardRequirements,
        };
      });
    const expertRequirementsAnswer = agencyAnswer?.standardRequirements?.map(
      (requirement) => {
        const reqAnswer =
          res?.result?.lastAnswer?.expertRequirementAnswers?.find(
            (reqAns) => reqAns.standardRequirementId === requirement?.id
          );

        if (reqAnswer?.expertSubRequirementAnswers) {
          const { expertSubRequirementAnswers, ...reqs } = reqAnswer;

          const subRequirements = requirement?.subStandardRequirements?.map(
            (subReq) => {
              const sub = expertSubRequirementAnswers?.find(
                (item) => subReq?.id === item?.subStandardRequirementId
              );

              return {
                ...sub,
                title: subReq?.title,
                id: subReq.id,
              };
            }
          );

          return {
            ...reqs,
            title: requirement?.title,
            id: requirement.id,
            recommendation: "",
            subStandardRequirements: [...subRequirements],
          };
        }

        const subStandardRequirements =
          requirement?.subStandardRequirements?.map((item) => ({
            ...item,
            decision: 0,
            donotApply: false,
          }));
        return {
          ...requirement,
          decision: 0,
          donotApply: false,
          subStandardRequirements,
        };
      }
    );

    const ExpertManagerRequirementsAnswer =
      agencyAnswer?.standardRequirements?.map((requirement) => {
        const reqAnswer =
          res?.result?.expertManagerAnswer?.expertManagerRequirementAnswers?.find(
            (reqAns) => reqAns.standardRequirementId === requirement?.id
          );

        if (reqAnswer?.expertManagerSubRequirementAnswers) {
          const { expertManagerSubRequirementAnswers, ...reqs } = reqAnswer;

          // const subRequirements = requirement?.subStandardRequirements?.map(
          //   (subReq) => {
          //     const sub = expertManagerSubRequirementAnswers?.find(
          //       (item) => subReq?.id === item?.subStandardRequirementId
          //     );

          //     return {
          //       ...sub,
          //       title: subReq?.title,
          //       id: subReq.id,
          //     };
          //   }
          // );

          const subRequirements = requirement?.subStandardRequirements?.map(
            (subReq) => {

              const sub = expertManagerSubRequirementAnswers?.find(
                (item) => subReq?.id === item?.subStandardRequirementId
              );

              const subStandardRequirement = {
                ...subReq,
                ...sub,
                id: subReq.id,
                decision: sub?.decision,
                donotApply: sub?.donotApply,
              }

              if (sub?.expertManagerSubRequirementAnswerSides) {
                subStandardRequirement.sides = structuredClone(sub?.expertManagerSubRequirementAnswerSides?.map((sideAnswer, idx) => {
                  return {
                    ...subReq.sides?.[idx],
                    answer: sideAnswer?.answer,
                    evaluationDegree: sideAnswer?.evaluationDegree,
                    attachmentId: sideAnswer?.attachmentId,
                    evaluationNote: sideAnswer?.evaluationNote,
                    recommendation: sideAnswer?.recommendation,
                  }
                }));
              }
              else {
                const allSides =
                res.result.expertManagerAnswer.expertManagerRequirementAnswers
                .map((item) => item.expertManagerSubRequirementAnswers || [])
                .flat()
                .map((item) => item.expertManagerSubRequirementAnswerSides  || [])
                .flat();
  
                subStandardRequirement.sides = subReq.sides.map((side) => {
                  const sideAnswer = allSides.find(
                    (item) => item.subStandardRequirementSideId === side.id
                  );


                  return {
                    ...side,
                    answer: sideAnswer?.answer,
                    evaluationDegree: sideAnswer?.evaluationDegree,
                    attachmentId: sideAnswer?.attachmentId,
                    evaluationNote: sideAnswer?.evaluationNote,
                    recommendation: sideAnswer?.recommendation,
                  };
                });
              }

              return subStandardRequirement
            }
          );

          return {
            ...reqs,
            title: requirement?.title,
            id: requirement.id,
            recommendation: "",
            subStandardRequirements: [...subRequirements],
          };
        }

        const subStandardRequirements =
          requirement?.subStandardRequirements?.map((item) => ({
            ...item,
            decision: 0,
            donotApply: false,
          }));
        return {
          ...requirement,
          decision: 0,
          donotApply: false,
          subStandardRequirements,
        };
      });

    const agencyPrevUploadedFilesAnswer = agencyAnswer?.standardProofs?.map(
      (proofFile) => {
        const proofNote =
          res?.result?.expertManagerAnswer?.surveyExpertManagerAnswerStandardProofs?.find(
            (proofAns) =>
              proofAns.standardProofId === proofFile?.standardProofId
          );

        return {
          ...proofFile,
          ...proofNote,
          expertManagerProofNotes: proofNote?.expertManagerProofNotes || "",
          pastSelectedFiles: [],
          standardProofId: proofFile?.standardProofId || proofFile?.id,
        };
      }
    );

    const templateList = isInquiryManager
      ? ""
      : await axiosGetRequest(environment.getAllNoteTemplate);

    // * Get and select selected ExpertManager degree
    const surveyDegrees = (await getDegrees(standardId)).filter(
      (item) => item?.id !== noAnswerDegreeId
    );

    const donotApplyDegree =
      surveyDegrees?.filter((item) => item?.title == "لا ينطبق")?.[0] || "";
    const noCommitmentDegree =
      surveyDegrees?.filter((item) => item?.title == "عدم التزام")?.[0] || "";

    const checkedExpertManagerDegree =
      surveyDegrees?.filter(
        (item) =>
          item?.id == res?.result?.expertManagerAnswer?.finalCommitmentLevel?.id
      )?.[0] || null;

    setExpertManagerDegree({
      ...expertManagerDegree,
      expertManagerSelfEvaluationCommitmentLevel:
        res?.result?.expertManagerAnswer?.selfEvaluationCommitmentLevel ||
        noCommitmentDegree,
      expertManagerSelfEvaluationDegree:
        res?.result?.expertManagerAnswer?.selfEvaluationDegree,
    });

    const answer = {
      ...newExpertManagerAnswer,

      standardRequirements: ExpertManagerRequirementsAnswer,
      reviewerRequirementsAnswer,
      pocOfficerRequirementsAnswer,
      expertRequirementsAnswer,

      standardProofs: agencyPrevUploadedFilesAnswer,
      checkedExpertManagerDegree,
      surveyDegrees,
      templateList: templateList?.result?.items,

      expertManagerAnswerNote:
        res?.result?.expertManagerAnswer?.expertManagerAnswerNote || "",

      expertManagerSelectedTemplateForAgency:
        res?.result?.expertManagerAnswer?.noteForAgency?.template ||
        res?.result?.expertManagerAnswer?.noteForAgency?.content ||
        "",
      expertManagerSelectedTemplateForReviewer:
        res?.result?.expertManagerAnswer?.noteForReviewer?.template || "",

      noteForAgency:
        res?.result?.expertManagerAnswer?.noteForAgency?.content || "",
      noteForReviewer:
        res?.result?.expertManagerAnswer?.noteForReviewer?.content || "",

      templateIdForAgency:
        res?.result?.expertManagerAnswer?.noteForAgency?.noteTemplate?.id,

      // donotApply: res?.result?.lastAnswer?.donotApply || false,
      agencyFinalCommitmentLevel: agencyAnswer?.agencyDonotApply
        ? donotApplyDegree
        : res?.result?.lastAnswer?.agencyFinalCommitmentLevel || null,
      pocOfficerFinalCommitmentLevel:
        res?.result?.lastAnswer?.pocOfficerFinalCommitmentLevel || null,
      reviewerFinalCommitmentLevel:
        res?.result?.lastAnswer?.reviewerFinalCommitmentLevel || null,
      expertFinalCommitmentLevel:
        res?.result?.lastAnswer?.expertFinalCommitmentLevel || null,
      agencySelfEvaluationCommitmentLevel:
        res?.result?.lastAnswer?.agencySelfEvaluationCommitmentLevel || null,
      reviewerSelfEvaluationCommitmentLevel:
        res?.result?.lastAnswer?.reviewerSelfEvaluationCommitmentLevel || null,

      reviewerRequirementAnswers: res?.result?.lastAnswer.reviewerRequirementAnswers,
      reviewerNoteTemplate: res?.result?.lastAnswer?.reviewerNoteForAgency?.noteTemplate?.templateContent || "",
      reviewerNotContent: res?.result?.lastAnswer?.reviewerNoteForAgency?.content,
      reviewerNoteForAgency:
        (res?.result?.lastAnswer?.reviewerNoteForAgency?.noteTemplate
          ?.templateContent || "") +
        (res?.result?.lastAnswer?.reviewerNoteForAgency?.unCheckRequirement ||
          "") +
        (res?.result?.lastAnswer?.reviewerNoteForAgency?.content || "") +
        (res?.result?.lastAnswer?.reviewerNoteForAgency?.template || ""),
      
      expertNoteTemplate: res?.result?.lastAnswer?.expertNoteForAgency?.noteTemplate?.templateContent || "",
      expertNotContent: res?.result?.lastAnswer?.expertNoteForAgency?.content,
      expertRequirementAnswers: res?.result?.lastAnswer.expertRequirementAnswers,
      expertNoteForAgency:
        (res?.result?.lastAnswer?.expertNoteForAgency?.noteTemplate
          ?.templateContent || "") +
        (res?.result?.lastAnswer?.expertNoteForAgency?.unCheckRequirement ||
          "") +
        (res?.result?.lastAnswer?.expertNoteForAgency?.content || "") +
        (res?.result?.lastAnswer?.expertNoteForAgency?.template || ""),
      
      pocOfficerNoteTemplate: res?.result?.lastAnswer?.pocOfficerNoteForAgency?.noteTemplate?.templateContent || "",
      pocOfficerNotContent: res?.result?.lastAnswer?.pocOfficerNoteForAgency?.content,
      pocOfficerRequirementAnswers: res?.result?.lastAnswer.pocOfficerRequirementAnswers,
      pocOfficerNoteForAgency:
        (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.noteTemplate
          ?.templateContent || "") +
        (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.unCheckRequirement ||
          "") +
        (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.content || "") +
        (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.template || ""),
      
      expertManagerNoteTemplate: res?.result?.expertManagerAnswer?.noteForAgency?.noteTemplate?.templateContent || "",
      expertManagerNotContent: res?.result?.expertManagerAnswer?.noteForAgency?.content,
      expertManagerRequirementAnswers: res?.result?.lastAnswer?.expertManagerRequirementAnswers,
      expertManagerNoteForAgency:
        (res?.result?.expertManagerAnswer?.noteForAgency?.noteTemplate
          ?.templateContent || "") +
        (res?.result?.expertManagerAnswer?.noteForAgency?.unCheckRequirement ||
          "") +
        (res?.result?.expertManagerAnswer?.noteForAgency?.content || "") +
        (res?.result?.expertManagerAnswer?.noteForAgency?.template || ""),

      lastAnsweredReviewerId: res?.result?.lastAnswer?.lastAnsweredReviewerId,
      lastAnsweredPocOfficerId: res?.result?.lastAnswer?.lastAnsweredPocOfficerId,
      lastAnsweredExpertId: res?.result?.lastAnswer?.lastAnsweredExpertId,
      expertLastAnsweredId: res?.result?.lastAnswer?.expertLastAnsweredId,
      reviewerLastAnsweredId: res?.result?.lastAnswer?.reviewerLastAnsweredId,
    };

    setNewExpertManagerAnswer(answer);
    setExpertManagerAnswer(structuredClone(answer));

    setLoadPreExpertManagerAnswer(false);
  };

  return { getPrevExpertManagerAnswer };
};

export default usePrevExpertManagerAnswer;
