import AppButton from "../../../../../components/buttons/appButton";
import { axiosPutRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { produce } from "immer";
import { errorToast, successToast } from "../../../../../helpers/toast";
import { AddStandardContext } from "../addStandard/addStandardContext";
import { generateRandomNumber, useQuery } from "../../../../../helpers/utils";

const SubmitEditStandard = ({disabled}) => {
  const { interlocutorId, standardId, surveyId, perspectiveId } = useParams();
  const history = useHistory();
  const search = useQuery();

  const [loading, setLoading] = useState(false);
  const {
    addFinalValue,
    setAddFinalValue,
    subRequirementCount,
  } = useContext(AddStandardContext);

  useEffect(() => {
    setAddFinalValue(
      produce((draft) => {
        draft.interlocutorId = Number(interlocutorId);
        draft.id = Number(standardId);
      })
    );
  }, []);

  const handleSubmit = async () => {
    if(disabled) {
      return;
    }

    if (subRequirementCount == 0) {
      errorToast("يجب ادخال سؤال واحد على الأقل");
      return;
    }

    if (addFinalValue?.standardProofs?.length == 0) {
      errorToast("يجب ادخال مستند على الأقل فى طريقة الإثبات");
      return;
    }

    const standardRequirements = [
      ...addFinalValue?.standardRequirements,
    ];

    let standardProofs = [...addFinalValue?.standardProofs];

    const standardRequirementList = standardRequirements?.map((item, indx) => ({
      title: item?.title,
      id: item?.id,
      isExisted: item.isExisted,
      subStandardRequirements: item?.subStandardRequirements,
      recommendation: item?.recommendation,
      standardRequirementClassificationId:
        item?.standardRequirementClassificationId,
      // agencyStandardRequirements: addFinalValue?.standardRequirements?.[
      //   standardRequirements?.length - 1 - indx
      // ]?.agencyStandardRequirements?.map((ele) => ({
      //   agencyId: ele.agencyId,
      // })),
      numberOfStandardRequirement: item.id
    }));
  
    standardProofs = standardProofs?.map((item, index) => ({
      ...item,
      // agencyStandardProofs: addFinalValue?.standardProofs[
      //   addFinalValue?.standardProofs?.length - 1 - index
      // ]?.agencyStandardProofs
      //   ?.map((val) => ({
      //     agencyId: val.id,
      //   }))
      //   .filter((item) => item?.agencyId),
      numberOfStandardProof: generateRandomNumber(0, 2147483647),
      numberOfStandardRequirements: item.standardProofSubRequirements?.map(
        (item) => {
          return item?.value;
        }
      ),
      standardProofRequirements: addFinalValue?.standardProofs[
        addFinalValue?.standardProofs?.length - 1 - index
      ]?.standardProofRequirements
        ?.map((val) => {
          return {
            standardRequirementId: val.standardRequirementId ?? val.id,
          };
        })
        .filter((item) => item?.standardRequirementId),
      subStandardProofRequirements: addFinalValue?.standardProofs[
          addFinalValue?.standardProofs?.length - 1 - index
        ]?.standardProofSubRequirements
          ?.map((val) => {
            return {
              subStandardRequirementId: val.standardSubRequirementId ?? val.id,
            };
          })
          .filter((item) => item?.subStandardRequirementId),
    }));

    let pastSurveyStandardAssociations =
      addFinalValue?.pastSurveyStandardAssociations?.map((item) => {
        if (item?.id) return { pastSurveyStandardId: item?.id };
        return item;
      });

    pastSurveyStandardAssociations = pastSurveyStandardAssociations?.filter(
      (item) => item?.pastSurveyStandardId
    );
    

    setLoading(true);
    let res;
    try {
      if (!addFinalValue?.code.match(/^\d+\.\d+\.\d+$/)) {
        errorToast("تنسيق الكود على الطريقة 1.0.0");
      } else {
        res = await axiosPutRequest(environment.updateStandard, {
          ...addFinalValue,
          standardRequirements: standardRequirementList,
          standardProofs,
          agencyStandards: [
            ...addFinalValue?.agencyStandards?.agencies,
            ...addFinalValue?.agencyStandards?.commonAgencies,
          ],
          pastSurveyStandardAssociations,
        });
      }
    } catch (err) {
      errorToast("حدث خطأ ");
    }

    setLoading(false);
    if (res.success) {
      successToast("تم تعديل المعيار بنجاح");
      history.push({
        pathname: `/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards`,
        state: search.get("indicatorId"),
        search: `?indicatorId=${search.get("indicatorId")}`
      });
    }
  };

  console.log("(addFinalValue.standardRequirements?.map(item => item?.subStandardRequirements)?.flat() ?? []).length", (addFinalValue.standardRequirements?.map(item => item?.subStandardRequirements)?.flat() ?? []).length)
  return (
    <div className="my-8 mt-12 flex justify-between rounded-lg items-center flex-wrap gap-x-6 px-6 py-3 bg-white fixed bottom-6 left-20  shadow-xl">
      <div className="flex gap-2 mb-1">
        <p>
          {addFinalValue.standardRequirements?.length - 1 || "0"}{" "}
          <span className="text-light">بعد مضاف ،</span>
        </p>
        <p>
          {addFinalValue.standardProofs?.length || "0"}{" "}
          <span className="text-light">مستند مضاف ،</span>
        </p>
        <p>
          {(addFinalValue.standardRequirements?.map(item => item?.subStandardRequirements)?.flat() ?? []).length || "0"}{" "}
          <span className="text-light">سؤال مضاف</span>
        </p>
      </div>
      <div className="text-end">
        <AppButton loading={loading} disabled={disabled} onClick={handleSubmit}>
          {
            !disabled ? "حفظ" : "لا يمكن الحفظ"
          }
        </AppButton>
      </div>
    </div>
  );
};

export default SubmitEditStandard;
