import { Link } from "react-router-dom";
import { Badge } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useGetPhase2Standards } from "../api/useGetPhase2Standards";
import { drawLeftGreen } from "../../../../assets/icons/Index";
import { getCompletionRateProgressBar } from "../../../../helpers/utils";
import useExcelExport from "../../../../helpers/customHooks/useExcelExport";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import NewCard from "../../../../components/dashboard/NewCard";
import NewTable from "../../../../components/NewTable";
import AppBadge from "../../../../components/AppBadge";
import NewTooltip from "../../../../components/NewTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import AppDivider from "../../../../components/appDivider";
import AppModal from "../../../../components/Models/appModal";
import { useDashboardContext } from "../../../../contexts/DashboardContext";

function Phase2Standards({ isPhase2 = false }) {
  const surveyStageId = localStorage.getItem("surveyStageId");
  const surveyId = localStorage.getItem("surveyId");
  const [currentPage, setCurrentPage] = useState(0);
  const [filterValue, setFilterValue] = useState(null);

  const [openShowModal, setOpenShowModal] = useState(false);
  const [showedAgencies, setShowedAgencies] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState("");

  const { setLoading, setAuditorsDashboardStats } = useDashboardContext();

  const {
    allStandards,
    isGettingAllStandards,
    totalCount,
    totalPages,
  } = useGetPhase2Standards({ currentPage, filterValue });

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  useEffect(() => {
    setAuditorsDashboardStats((prevStats) => ({
      ...prevStats,
      standardsCount: totalCount,
    }));
    setLoading(isGettingAllStandards);
  }, [totalCount, isGettingAllStandards]);

  // tables columns
  const standardsColumn = [
    {
      name: "كود المعيار",
      accessorKey: "standardCode",
      selector: (row) => row.standardCode,
      sortable: true,
      width: "100px",
      cell: (info) => (
        <div className="text-[#292069] font-semibold">{info.standardCode}</div>
      ),
    },
    // {
    //   name: "اسم المعيار",
    //   accessorKey: "standardTitle",
    //   selector: (row) => row.standardTitle,
    //   sortable: true,
    //   center: true,
    //   cell: (info) => <div className="text-center">{info.standardTitle}</div>,
    // },
    {
      name: "القدرة",
      accessorKey: "perspectiveTitle",
      selector: (row) => row.perspectiveTitle,
      center: true,
      sortable: true,
      cell: (row) => (
        <AppBadge badgeColor="#ffa92b" size="sm">
          {row?.perspectiveTitle}
        </AppBadge>
      ),
    },
    {
      name: "العنصر",
      center: true,
      sortable: true,
      cell: (row) => (
        <AppBadge badgeColor="#1cc081" size="sm">
          {row?.interlocutorTitle}
        </AppBadge>
      ),
    },
    {
      name: "عدد الجهات",
      center: true,
      selector: (row) => row.assignedAgenciesCountOnStandard,
    },
    {
      name: "تم التدقيق بواسطة المدققين",
      center: true,
      selector: (row) => row.reviewedAgenciesCountByReviewers,
    },
    {
      name: "تم التدقيق بواسطة الخبراء",
      center: true,
      selector: (row) => row.reviewedAgenciesCountByExperts,
    },
    // {
    //   name: "تم التدقيق بواسطة ضباط الاتصال",
    //   center: true,
    //   selector: (row) => row.reviewedAgenciesCountByPocOfficers,
    // },
    {
      name: "الجهات التي تم تدقيقها",
      center: true,
      cell: (row) => (
        <NewTooltip content={"عرض"}>
          <FontAwesomeIcon
            icon={faEye}
            className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
            onClick={() => {
              setSelectedStandard(row.standardCode);
              setOpenShowModal(true);
              setShowedAgencies(row?.agencies);
            }}
          />
        </NewTooltip>
      ),
    },
    {
      name: "نسبة اكتمال التدقيق",
      center: true,
      sortable: true,
      cell: (row) => getCompletionRateProgressBar(row.standardReviewRate),
    },
    {
      name: "تفاصيل الاجابات على المعيار",
      selector: (row) => row.status,
      sortable: true,
      center: true,
      cell: (row) => (
        <Link to={`/Standard/${row.standardId}/agencies/answer`}>
          <img
            src={drawLeftGreen}
            alt="viewNew"
            className="w-[40px] cursor-pointer"
          />
        </Link>
      ),
    },
  ];

  // excel
  const [excelLoading, setExcelLoading] = useState(false);

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(
      environment.GetStandardStatusBasedOnReviewOperation,
      {
        KeyWord: filterValue,
        StageId: surveyStageId,
        SurveyId: surveyId,
        SkipCount: 0,
        MaxResultCount: 100000,
      }
    );

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        "كود المعيار": ele?.standardCode,
        "اسم المعيار": ele?.standardTitle,
        القدرة: ele?.perspectiveTitle,
        العنصر: ele?.interlocutorTitle,
        "الجهات التي تم تدقيقها": ele?.agencies
          ?.map((ele) => ele.nameInArabic)
          .join(", "),
        "نسبة اكتمال التدقيق": ele?.standardReviewRate?.toFixed(2),
      };
    });

    downloadExcel("المعايير", dataForExcel);
  };

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  return (
    <NewCard title={`المعايير (${totalCount})`}>
      <NewTable
        withSearch
        onSearch={handleFilter}
        withExcel
        excelLoading={excelLoading}
        onExportExcel={handleExcelExport}
        loading={isGettingAllStandards}
        columns={standardsColumn}
        data={allStandards || []}
        pagination={true}
        totalPages={totalPages}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />

      <AppModal
        open={openShowModal}
        setOpen={setOpenShowModal}
        headerTitle={`الجهات الخاصة بمعيار ${selectedStandard}`}
        isFooter={false}
      >
        <div className="text-xl h-full pb-2">
          {
            <ul>
              {showedAgencies?.map((agency, index) => (
                <React.Fragment key={agency.nameInArabic}>
                  <li className="mb-2 flex justify-between gap-3">
                    <p>
                      {index + 1} - {agency?.nameInArabic}
                    </p>
                  </li>

                  {index < showedAgencies.length - 1 && <AppDivider />}
                </React.Fragment>
              ))}
            </ul>
          }
        </div>
      </AppModal>
    </NewCard>
  );
}

export default Phase2Standards;
