import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";

import { faBinoculars } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
  faFileExcel,
  faEdit,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TableSkeleton from "../table/TableSkeleton";
import { emptyTable } from "../../assets/imgs/Index";
import { permissionsCheck } from "../../helpers/utils";
import NewTooltip from "../NewTooltip";
import AppInput from "../form/appInput";
import ExcelDownloadButton from "../buttons/excelDownloadButton";
import DownloadButton from "../DownloadButton";
import { DropdownMultiSelect } from "../index";
const customStyles = {
  table: {
    style: {},
  },
  headRow: {
    style: {},
  },
  rows: {
    style: {
      color: "black",
      backgroundColor: "white",
      minHeight: "72px",
      fontSize: 14,
      "&:not(:last-of-type)": {
        borderBottomStyle: "dashed",
        borderBottomWidth: "1px",
        borderBottomColor: "#EAEAEA",
      },
      stripedStyle: {
        color: "black",
        backgroundColor: "#FFFFFF",
        minHeight: "72px",
        fontSize: 14,
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "0px",
          borderBottomColor: "white",
        },
      },
    },
    headCells: {
      style: {
        padding: "8px",
        backgroundColor: "#E8E8F3",
        minHeight: "40px",
        display: "flex",
        justifyContent: "center",
        color: "#292069",
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        padding: "5px",
        minHeight: "72px",
        display: "flex",
        justifyContent: "center",
      },
    },
    pageButtonsStyle: {
      borderRadius: "50%",
      height: "40px",
      width: "40px",
      padding: "8px",
      margin: "px",
      cursor: "pointer",
      transition: "0.4s",
      color: "#656565",
      fill: "#656565",
      backgroundColor: "transparent",
    },
  },
};

const CustomTable = ({
  columns,
  data,
  selectableRows,
  currentPage,
  onPageChange,
  totalPages,
  table,
  other,
  loading,
  pagination,
  handlePerRowsChange,
  handlePageChange,
  totalRows,
  withExcel = false,
  onEditClick,
  onDeleteClick,
  onSearch,
  onExportExcel,
  exportExcelText = "استخراج ملف اكسيل",

  permissionPage = "Tickets",
  actions = false,
  handlePerPageChange,
  perPage = { label: "10", value: 10 },
  showPerPage = false,
  withSearch = true,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleEditClick = (row) => {
    if (onEditClick) {
      onEditClick(row);
    }
  };

  const handleDeleteClick = (row) => {
    if (onDeleteClick) {
      onDeleteClick(row);
    }
  };

  const actionsCell = (row) => (
    <div className="flex justify-center items-center gap-4">
      {permissionsCheck(`Pages.${permissionPage}.Get`) ? (
        <FontAwesomeIcon
          fontSize={24}
          icon={faBinoculars}
          className="bg-blue-50 text-sky-500 cursor-pointer"
          // onClick={(e) => history.push("/ticket/" + row.id)}
          onClick={() =>
            window.open("/ticket/" + row.id, "_blank", "noreferrer")
          }
        />
      ) : null}

      {permissionsCheck(`Pages.${permissionPage}.Edit`) && (
        <button
          className="text-blue-500 hover:text-blue-700 focus:outline-none"
          onClick={() => handleEditClick(row)}
        >
          <FontAwesomeIcon fontSize={24} icon={faEdit} />
        </button>
      )}

      {permissionsCheck(`Pages.${permissionPage}.Delete`) && (
        <button
          className="text-red-500 hover:text-red-700 focus:outline-none"
          onClick={() => handleDeleteClick(row)}
        >
          <FontAwesomeIcon fontSize={24} icon={faTrash} />
        </button>
      )}
    </div>
  );

  const columnsWithActions = [
    ...columns,
    actions == false
      ? {}
      : {
          name: t("modules.main.mailbox.actions"),
          cell: (row) => actionsCell(row),
          grow: 1,
          width: "140px",
          selector: (row) => row.id,
          header: "id",
          accessorKey: "id",
          footer: (props) => props.column.id,
        },
  ];

  return (
    <div>
      <div className="mb-3 flex items-center justify-between">
        <div className="flex flex-row items-center">
          {showPerPage == true ? (
            <div>
              <DropdownMultiSelect
                filter={true}
                withoutLabel={true}
                isSearchable={false}
                value={perPage}
                handleChange={(value) => handlePerPageChange(value)}
                placeholder={t("عدد العناصر في الجدول")}
                label={t("عدد العناصر في الجدول")}
                options={[
                  { label: "5", value: 5 },
                  { label: "10", value: 10 },
                  { label: "25", value: 25 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
                isMultiple={false}
              />
            </div>
          ) : null}
          {withSearch ? (
            <div className="relative mr-5 ml-5">
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <FontAwesomeIcon
                  color="white"
                  className="text-slate-400"
                  icon={faSearch}
                />
              </div>
              <AppInput
                type="text"
                placeholder={"ابحث هنا..."}
                className="w-full border rounded bg-[#F9F9ff] text-slate-400 placeholder:text-slate-400 py-2 px-3 pr-10"
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
          ) : null}
        </div>
        {withExcel && (
          <div className="cursor-pointer p-3 self-end">
            <DownloadButton onClick={onExportExcel}>
              {exportExcelText}
            </DownloadButton>
          </div>
        )}
      </div>

      {loading ? (
        <TableSkeleton />
      ) : (
        <div className="mb-5">
          <div></div>
          <DataTable
            progressPending={loading}
            columns={columnsWithActions}
            data={data}
            noDataComponent={
              <div className="flex flex-col items-center content-center p-5">
                <img src={emptyTable} alt="empty" />
                <span className="text-base text-[#656565]">
                  {t("general.noFoundTable")}
                </span>
              </div>
            }
            customStyles={customStyles}
            highlightOnHover={true}
            fixedHeader
            onSort={() => {}}
            striped={true}
          />
        </div>
      )}

      {pagination === false ? null : (
        <div className="w-[100%] m-auto bg-white p-5">
          <ReactPaginate
            nextLabel={
              loading ? null : (
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  style={{ color: "#A1A9C4", fontSize: 14 }}
                />
              )
            }
            onPageChange={onPageChange}
            initialPage={currentPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={totalPages}
            previousLabel={
              loading ? null : (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ color: "#A1A9C4", fontSize: 14 }}
                />
              )
            }
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item-previous"
            previousLinkClassName="page-link-previous"
            nextClassName="page-item-next"
            nextLinkClassName="page-link-next"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </div>
  );
};

export default CustomTable;
