import React, { useContext, useEffect, useState } from "react";
// import Card from "../Card";
import NewCard from "../../../../components/dashboard/NewCard";
import NewTable from "../../../../components/NewTable";
import environment from "../../../../services/environment";
import { StageContext } from "../../../../contexts/stageContext";
import { formateDate } from "../../../../helpers/dateFormat";
import { Badge } from "flowbite-react";
import { axiosGetRequest } from "../../../../services/Request";
import CustomTable from "../../../../components/CustomTable";
import useExcelExport from "../../../../helpers/customHooks/useExcelExport";

const AssignedToStandard = ({ title }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [excelLoading, setExcelLoading] = useState(false);

  const [count, setCount] = useState(0);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState(null);

  const surveyId = localStorage.getItem("surveyId");

  const getAssignedUsers = async () => {
    setLoading(true);
    const res = await axiosGetRequest(
      environment.getExpertAndReviewerAndPocOfficerToStandard,
      {
        surveyId: surveyId,
        KeyWord: filterValue,
        MaxResultCount: 5,
        SkipCount: currentPage * 5,
      }
    );

    if (res?.success) {
      setAssignedUsers(res?.result?.items);
      setCount(res?.result?.totalCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAssignedUsers();
  }, [currentPage, filterValue]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const columns = [
    {
      name: "رقم المعيار",
      selector: (row) => row.standardCode,
      header: "رقم المعيار",
      accessorKey: "standardCode",
      footer: (props) => props.column.standardCode,
    },
    {
      name: "اسم المعيار",
      selector: (row) => row.standardTitle,
      header: "اسم المعيار",
      accessorKey: "standardTitle",
      footer: (props) => props.column.standardTitle,
    },

    {
      name: "الخبراء",
      selector: (row) => row.assignedExperts,
      header: "الخبراء",
      accessorKey: "assignedExperts",
      footer: (props) => props.column.assignedExperts,
      center: true,
      cell: (info) => (
        <div className="grid grid-cols-2">
          {" "}
          {info?.assignedExperts.map((item, index) => (
            <div className="flex p-1 bg-[#1F925433] justify-center items-center rounded m-1">
              <span key={index} className="text-[10px] text-[#1F9254]">
                {item.fullName}
              </span>
            </div>
          ))}
        </div>
      ),
    },
    {
      name: "المدققين",
      selector: (row) => row.assignedReviewer,
      header: "المدققين",
      accessorKey: "assignedReviewer",
      footer: (props) => props.column.assignedReviewer,
      center: true,
      cell: (info) => (
        <div className="grid grid-cols-2">
          {" "}
          {info?.assignedReviewer.map((item, index) => (
            <div className="flex p-1 bg-[#1F925433] justify-center items-center rounded m-1">
              <span key={index} className="text-[10px] text-[#1F9254]">
                {item.fullName}
              </span>
            </div>
          ))}
        </div>
      ),
    },
    // {
    //   name: "ضباط الاتصال",
    //   selector: (row) => row.assignedPocOfficiers,
    //   header: "ضباط الاتصال",
    //   accessorKey: "assignedPocOfficiers",
    //   footer: (props) => props.column.assignedPocOfficiers,
    //   center: true,
    //   cell: (info) => (
    //     <div className="grid grid-cols-2">
    //       {" "}
    //       {info?.assignedPocOfficiers.map((item, index) => (
    //         <div className="flex p-1 bg-[#1F925433] justify-center items-center rounded m-1">
    //           <span key={index} className="text-[10px] text-[#1F9254]">
    //             {item.fullName}
    //           </span>
    //         </div>
    //       ))}
    //     </div>
    //   ),
    // },
  ];

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(
      environment.getExpertAndReviewerAndPocOfficerToStandard,
      {
        surveyId: surveyId,
        KeyWord: filterValue,
        SkipCount: 0,
        MaxResultCount: 500,
      }
    );

    setExcelLoading(false);

    const dataForExcel = res?.result?.items.map((item) => {
      const { ...rest } = item;
      return {
        ...rest,
        assignedExperts: item?.assignedExperts
          ?.map((obj) => " [ " + obj?.fullName + " ) ]")
          .join(" - "),
        assignedReviewer: item?.assignedReviewer
          ?.map((obj) => " [ " + obj?.fullName + " ) ]")
          .join(" - "),
        assignedPocOfficiers: item?.assignedPocOfficiers
          ?.map((obj) => " [ " + obj?.fullName + " ) ]")
          .join(" - "),
      };
    });

    downloadExcel("المستخدمين الذي تم اضافتهم لمعايير", dataForExcel);
  };

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  return (
    <NewCard title={title || "المستخدمين الذي تم اضافتهم لمعايير"}>
      <NewTable
        loading={loading}
        columns={columns}
        data={assignedUsers || []}
        pagination={true}
        totalPages={Math.ceil(count / 5)}
        onPageChange={handlePageClick}
        currentPage={currentPage}
        withExcel
        onExportExcel={handleExcelExport}
        excelLoading={excelLoading}
        withSearch
        onSearch={handleFilter}
      />
    </NewCard>
  );
};

export default AssignedToStandard;
