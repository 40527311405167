import NoAnswerComponent from "../../components/NoAnswerComponent";
import AuditTimeLine from "./AuditTimeLine";
import MainRequirement from "../../modules/main/features/standerds/standardAnswer/standardAnswerRequirements/ui/mainRequirement";
import { TextInput } from "flowbite-react";
import UploadFile from "../../modules/main/features/standerds/standardAnswer/answers/UploadFile";

function AnswersHistory({ answersHistory, showNote = true, userType }) {
  const inputLabel = userType === 'expert' ? "الخبير" : userType === 'reviewer' ? "المدقق" : "رئيس الخبراء"

  return (
    <div>
      {!answersHistory?.length ? (
        <NoAnswerComponent />
      ) : (
        <>
        {
          (userType === 'expert' || userType === 'reviewer' || userType === 'expertManager') ?
          <ReadOnlyRequirementAnswers 
            requirements={answersHistory?.[0]?.expertRequirementAnswers} 
            inputLabel={inputLabel}          
          />
          :
          
          <AuditTimeLine
            events={answersHistory}
            showNote={showNote}
            userType={userType}
          />
        }
        </>


      )}
    </div>
  );
}

export default AnswersHistory;

function ReadOnlyRequirementAnswers({ requirements, inputLabel }) {

  return (
    requirements?.length > 0 &&
    requirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.expertSubRequirementAnswers?.length > 0;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title || requirement?.standardRequirementTitle}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {}}
            handleDonotApplyRequirementChange={() => {}}
          >
            {hasSubRequirements &&
              requirement?.expertSubRequirementAnswers?.map((subRequirement) => {
                return (
                  <>
                    <div className="px-5 flex flex-col mb-3 py-2">
                      <h2 className="text-2xl pb-2">{subRequirement.subStandardRequirementTitle}</h2>
                      <div className="flex flex-col gap-4">
                        <>
                          {subRequirement?.expertSubRequirementAnswerSides?.map((side, index) => {
                            return (
                              <div className="border-b pb-2">
                                <h3 className="text-xl pb-3">{side.subStandardRequirementSideTitle}</h3>
                                <div className=" flex items-center gap-2 mb-4">
                                  <span className="w-24">{`تقييم ${inputLabel}`}</span>
                                  <TextInput
                                    value={side?.evaluationDegree}
                                    type="number"
                                    placeholder=""
                                    min={0}
                                    max={100}
                                  />
                                  {/* <label>
                                    {getCommitmentLevelsBasedOnDegree(
                                      Number(
                                        subRequirement
                                          ?.expertSubRequirementAnswerSides?.[
                                          index
                                        ]?.evaluationDegree
                                      ),
                                      commitmentLevels
                                    )}
                                  </label> */}
                                </div>
                                <div className="flex gap-2 w-full items-center mb-4">
                                  <span className="w-24"> الملاحظة</span>
                                  <TextInput
                                    value={side?.evaluationNote}
                                    className="grow"
                                    placeholder={`ملاحظة ${inputLabel} للجهة`}
                                    disabled
                                  />
                                </div>
                                <div className="flex gap-2 w-full items-center">
                                  <span className="w-24">التوصية</span>
                                  <TextInput
                                    value={side?.recommendation}
                                    className="grow"
                                    placeholder={`توصية ${inputLabel} للجهة`}
                                    disabled
                                  />
                                </div>
                                {
                                  side.attachmentId &&
                                  <div className="flex gap-2 w-full items-center">
                                    <span className="w-24">المستند</span>
                                    <UploadFile
                                      value={side.attachmentId}
                                      withOutToolTip
                                      readOnly
                                    />
                                  </div>
                                }
                              </div>
                            );
                          })}
                        </>
                      </div>
                    </div>
                  </>
                );
              })}
          </MainRequirement>
        </>
      );
    })
  );
}
