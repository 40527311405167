import { errorToast, successToast } from "../../../../../../../helpers/toast";
import { axiosPostRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { useAnswerContext } from "../../answerContext";
import { useHistory } from "react-router";
import usePrevExpertManagerAnswer from "../useGetPrevAnswer/usePrevExpertManagerAnswer";

const useCreateExpertManagerAnswer = () => {
  const history = useHistory();
  const { getPrevExpertManagerAnswer } = usePrevExpertManagerAnswer();

  const {
    newExpertManagerAnswer,
    setCreateAnswerLoading,
    expertManagerDegree,
  } = useAnswerContext();

  const createExpertManagerAnswer = async (
    standardId,
    entityId,
    currentStage
  ) => {
    const expertManagerRequirementAnswers =
      newExpertManagerAnswer?.standardRequirements?.map((req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            subStandardRequirementId: subReq?.id,
            decision: subReq?.decision,
            note: subReq.note || "",
            recommendation: subReq.recommendation || "",
            donotApply: subReq?.donotApply,
            percentage: parseFloat(subReq?.percentage),
            attachmentId: subReq?.attachmentId,
            expertManagerSubRequirementAnswerSides: subReq?.sides.map( item => {
              return {
                subStandardRequirementSideId: item?.id,
                evaluationDegree: parseFloat(item?.evaluationDegree),
                answer: item?.answer || "",
                attachmentId: item?.attachmentId || null,
                evaluationNote: item?.evaluationNote || "",
                recommendation: item?.recommendation || "",
              }
            }),
          })
        );

        return {
          standardRequirementId: req?.id,
          decision: req?.decision,
          note: req?.note || "",
          recommendation: req?.note || "",
          donotApply: req?.donotApply,
          expertManagerSubRequirementAnswers: subStandardRequirements,
          percentage: req?.percentage
        };
      });
  
    const allSubRequirmenets = expertManagerRequirementAnswers.map(item => item.expertManagerSubRequirementAnswers).flat()
    const subRequirementNotes = allSubRequirmenets.map(item => item.percentage)
    const isValidPercentage = subRequirementNotes.every(percentage => !percentage || (+percentage >= 0 && +percentage <= 100)) 

    if (!isValidPercentage) {
      // the evaluation of the expert  for the questions should be within 0 and 100
      errorToast("التقييم الخاص بالخبير للأسئلة يجب أن يكون بين 0 و 100")
      return
    }


    const surveyExpertManagerAnswerStandardProofs =
      newExpertManagerAnswer?.standardProofs
        ?.filter((proof) => proof?.expertManagerProofNotes)
        .map((proof) => {
          console.log(
            proof,
            "dsl;kfjkals;djfiewurpoiuwerkljdzkjnljkgsjldkfgsfjdklhjklh"
          );
          return {
            standardProofId: proof?.standardProofId || null,
            expertManagerProofNotes: proof?.expertManagerProofNotes || "",
          };
        }) || [];

    console.log(
      newExpertManagerAnswer,
      "newExpertManagerAnswer - newExpertManagerAnswer"
    );
    console.log(
      newExpertManagerAnswer,
      "newExpertManagerAnswer - newExpertManagerAnswer"
    );

    // validation
    // if (newExpertManagerAnswer?.checkedExpertManagerDegree?.value == null) {
    //   return errorToast("لابد من وجود اجابة للتدقيق");
    // }

    const data = {
      standardId: Number(standardId),
      surveyStageId: Number(currentStage),
      expertManagerRequirementAnswers,
      surveyExpertManagerAnswerStandardProofs,
      noteForAgency: {
        // content:
        //   newExpertManagerAnswer?.selectedTemplatesForAgency == undefined
        //     ? "<p>لا يوجد</p>" +
        //       "<ul>" +
        //       newExpertManagerAnswer?.newRequirementsAgencyForExpertManager +
        //       "</ul>"
        //     : newExpertManagerAnswer?.selectedTemplatesForAgency +
        //       "<ul>" +
        //       newExpertManagerAnswer?.newRequirementsAgencyForExpertManager +
        //       "</ul>",
        // template:
        //   newExpertManagerAnswer?.expertManagerSelectedTemplateForAgency,

        unCheckRequirement:
          newExpertManagerAnswer?.newRequirementsAgencyForExpertManager ==
          undefined
            ? ""
            : `<ul style="padding : 6px 0 ">` +
              newExpertManagerAnswer?.newRequirementsAgencyForExpertManager +
              "</ul>",
        template: "",
        content: newExpertManagerAnswer?.expertManagerSelectedTemplateForAgency,
        noteTemplateId: newExpertManagerAnswer?.selectedTemplatesForAgency,
      },
      noteForReviewer: {
        content:
          // newExpertManagerAnswer?.selectedTemplatesForReviewer +
          "<ul>" +
          newExpertManagerAnswer?.newRequirementsReviewerForExpertManager +
          "</ul>",
        template:
          newExpertManagerAnswer?.expertManagerSelectedTemplateForReviewer,
      },
      expertManagerId: Number(localStorage.getItem("userId")),
      agencyId: Number(entityId),
      finalDegree:
        newExpertManagerAnswer?.checkedExpertManagerDegree?.fixedDegree,
      donotApply: newExpertManagerAnswer?.donotApply,
      finalCommitmentLevelId:
        newExpertManagerAnswer?.checkedExpertManagerDegree?.value,
      selfEvaluationCommitmentLevelId:
        expertManagerDegree?.expertManagerSelfEvaluationCommitmentLevel?.id ||
        null,
      selfEvaluationDegree:
        expertManagerDegree?.expertManagerSelfEvaluationDegree || 0,

      expertManagerAnswerNote:
        expertManagerDegree?.expertManagerSelfEvaluationCommitmentLevel
          ?.title !== newExpertManagerAnswer?.checkedExpertManagerDegree?.title
          ? newExpertManagerAnswer?.expertManagerAnswerNote
          : null,

      visitId: null,
      visitRecommendation: "",
      needVisit: false,
      visitNote: "",
    };
    console.log(data, "expertmanager - data");

    setCreateAnswerLoading(true);
    const res = await axiosPostRequest(
      environment.addExpertManagerAnswer,
      data
    );
    if (res?.success) {
      successToast("تم اضافة الاجاية بنجاح");
      getPrevExpertManagerAnswer(
        Number(standardId),
        Number(entityId),
        Number(currentStage)
      );
    }
    setCreateAnswerLoading(false);
  };
  return { createExpertManagerAnswer };
};

export default useCreateExpertManagerAnswer;
