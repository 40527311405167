import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppLoader from "../../../../../../../components/loader";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { useAnswerContext } from "../../answerContext";
import NextBtn from "../../components/buttons/nextBtn";
import StandardAnswerData from "../../standardAnswerData";
import StandardAnswerNotes from "../../standardAnswerNotes";
import useCreateExpertAnswer from "../useCreateAnswer/useCreateExpertAnswer";
import usePrevExpertAnswer from "../useGetPrevAnswer/usePrevExpertAnswer";
import ExpertRequirementAnswer from "./ExpertRequirementAnswer";
import ExpertRequirementAnswerConfidential from "./ExpertRequirementAnswerConfidential";
import ExpertNoteTemplate from "./expertNoteTemplates";
import ExpertProofFiles from "./expertProofFiles";
import NotesForAgency from "./notesForAgency";

import { TextInput } from "flowbite-react";
import { axiosGetRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import ReadOnlyRequirements from "../../components/readOnlyRequirements";
import StandardAnswerRequirement from "../../standardAnswerRequirements";
import MainRequirement from "../../standardAnswerRequirements/ui/mainRequirement";
import AgencyRequirementAnswer from "../Agency/agencyRequirementAnswer";
import UploadFile from "../UploadFile";
import { getCommitmentLevelsBasedOnDegree } from "../utils";
import { toast } from "react-toastify";

const ExpertAnswer = ({ menuElement, setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const { getPrevExpertAnswer } = usePrevExpertAnswer();
  const { newAgencyAnswer } = useAnswerContext();
  const { loadPreExpertAnswer, newExpertAnswer } = useAnswerContext();
  const { createExpertAnswer } = useCreateExpertAnswer();
  const { programId, entityId, standardId } = useParams();
  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency;

  useEffect(() => {
    if (currentStage) {
      getPrevExpertAnswer(standardId, entityId, currentStage?.id);
    }
  }, [currentStage]);

  if (menuElement === 3 && isConfidentialAgency) {
    if (loadPreExpertAnswer && menuElement !== 0) {
      return (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      );
    } else {
      return (
        <ExpertRequirementAnswerConfidential setMenuElement={setMenuElement} />
      );
    }
  }

  return (
    <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
      {menuElement === 0 && (
        <StandardAnswerData
          onNext={() => setMenuElement(1)}
          loading={loadPreExpertAnswer}
        />
      )}

      {loadPreExpertAnswer && menuElement !== 0 ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      ) : (
        <>
          {menuElement === 1 &&
            (newAgencyAnswer.surveyStructureSubStandardRequirementDirectly ? (
              <ExpertRequirementAnswerType2 setMenuElement={setMenuElement} />
            ) : (
              <ExpertRequirementAnswer setMenuElement={setMenuElement} />
            ))}
          {menuElement === 3 && (
            <>
              <ExpertProofFiles setMenuElement={setMenuElement} />
              <NextBtn onClick={() => setMenuElement(6)} />
            </>
          )}

          {/* {menuElement === 4 && (
            <ExpertFinalAnswer setMenuElement={setMenuElement} />
          )} */}

          {/* {menuElement === 5 && (
            <ExpertVisit
              setMenuElement={setMenuElement}
            />
          )} */}

          {menuElement === 6 && (
            <NotesForAgency setMenuElement={setMenuElement} />
          )}

          {menuElement === 7 && (
            <StandardAnswerNotes
              createAnswer={() =>
                createExpertAnswer(standardId, entityId, currentStage?.id)
              }
            >
              <ExpertNoteTemplate />
            </StandardAnswerNotes>
          )}
        </>
      )}
    </div>
  );
};

export default ExpertAnswer;

const ExpertRequirementAnswerType2 = ({ setMenuElement }) => {
  const { standardId, entityId } = useParams();
  const { getSelfEvaluation, newExpertAnswer, setNewExpertAnswer } =
    useAnswerContext();
  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState("expert");

  const { data: commitmentLevels = [] } = useQuery({
    queryKey: ["commitmentLevels", { standardId }],
    queryFn: async ({ queryKey }) => {
      const [, { standardId }] = queryKey;

      const res = await axiosGetRequest(environment.getCommitmentLevels, {
        standardId,
      });

      return res?.result;
    },
  });

  const handleRequirementChange = (requirement, property = "decision") => {
    const updatedRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((subReq) => {
              return property === "decision"
                ? {
                    ...subReq,
                    decision: requirement?.decision ? 0 : 1,
                    donotApply: 0,
                  }
                : {
                    ...subReq,
                    donotApply: !requirement?.donotApply,
                    decision: !requirement?.donotApply
                      ? 0
                      : requirement?.decision,
                  };
            });

          return property === "decision"
            ? {
                ...requirement,
                subStandardRequirements,
                decision: requirement?.decision ? 0 : 1,
                donotApply: 0,
              }
            : {
                ...requirement,
                subStandardRequirements,
                donotApply: !requirement?.donotApply,
                decision: !requirement?.donotApply ? 0 : requirement?.decision,
              };
        }

        return item;
      }
    );

    getSelfEvaluation(standardId, updatedRequirements, entityId);

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const handleSubRequirementPercentageChange = (
    requirement,
    subReqId,
    property = "note",
    sideId,
    value
  ) => {
    if (value > 100) {
      toast.error("الدرجة لا يمكن أن تكون أكبر من 100");
      return;
    }
    if (value < 0) {
      toast.error("الدرجة لا يمكن أن تكون أٌقل من 0");
      return;
    }
    const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  sides: subRequire?.sides?.map((side) => {
                    if (sideId === side?.id) {
                      return {
                        ...side,
                        evaluationDegree: value,
                      };
                    }

                    return side;
                  }),
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSubRequirementEvaluationNoteChange = (
    requirement,
    subReqId,
    property = "note",
    sideId,
    value
  ) => {
    const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  sides: subRequire?.sides?.map((side) => {
                    if (sideId === side?.id) {
                      return {
                        ...side,
                        evaluationNote: value,
                      };
                    }

                    return side;
                  }),
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSubRequirementAttachmentIdChange = (
    requirement,
    subReqId,
    property = "note",
    sideId,
    value
  ) => {
    const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  sides: subRequire?.sides?.map((side) => {
                    if (sideId === side?.id) {
                      return {
                        ...side,
                        attachmentId: value,
                      };
                    }

                    return side;
                  }),
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSubRequirementRecommendationChange = (
    requirement,
    subReqId,
    property = "note",
    sideId,
    value
  ) => {
    const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  sides: subRequire?.sides?.map((side) => {
                    if (sideId === side?.id) {
                      return {
                        ...side,
                        recommendation: value,
                      };
                    }

                    return side;
                  }),
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const renderedExpertRequirements =
    newExpertAnswer?.standardRequirements?.length > 0 &&
    newExpertAnswer?.standardRequirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {
              handleRequirementChange(requirement);
            }}
            handleDonotApplyRequirementChange={() =>
              handleRequirementChange(requirement, "donotApply")
            }
          >
            {hasSubRequirements &&
              requirement?.subStandardRequirements?.map((subRequirement) => {
                return (
                  <>
                    <div className="px-5 flex flex-col mb-3 py-2">
                      <h2 className="text-2xl pb-2">{subRequirement.title}</h2>
                      <div className="flex flex-col gap-4">
                        <>
                          {subRequirement?.sides?.map((side, index) => {
                            return (
                              <div>
                                <div className="border-b pb-2">
                                  <h3 className="text-xl pb-3">{side.title}</h3>
                                  <div className="flex items-center gap-2 mb-4">
                                    <span className="w-24">تقييم الخبير</span>
                                    <TextInput
                                      value={side?.evaluationDegree}
                                      type="number"
                                      placeholder=""
                                      min={0}
                                      max={100}
                                      onChange={(e) => {
                                        handleSubRequirementPercentageChange(
                                          requirement,
                                          subRequirement?.id,
                                          "evaluationDegree",
                                          side.id,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label>
                                      {getCommitmentLevelsBasedOnDegree(
                                        Number(side?.evaluationDegree),
                                        commitmentLevels
                                      )}
                                    </label>
                                  </div>
                                  <div className="flex gap-2 w-full items-center mb-4">
                                    <span className="w-24"> الملاحظة</span>
                                    <TextInput
                                      value={side?.evaluationNote}
                                      className="grow"
                                      placeholder="ملاحظة الخبير للجهة"
                                      onChange={(e) => {
                                        handleSubRequirementEvaluationNoteChange(
                                          requirement,
                                          subRequirement?.id,
                                          "evaluationNote",
                                          side.id,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="flex gap-2 w-full items-center">
                                    <span className="w-24">التوصية</span>
                                    <TextInput
                                      value={side?.recommendation}
                                      className="grow"
                                      placeholder="توصية الخبير للجهة"
                                      onChange={(e) => {
                                        handleSubRequirementRecommendationChange(
                                          requirement,
                                          subRequirement?.id,
                                          "recommendation",
                                          side.id,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <UploadFile
                                    value={side.attachmentId}
                                    onChange={(id) => {
                                      handleSubRequirementAttachmentIdChange(
                                        requirement,
                                        subRequirement?.id,
                                        "attachmentId",
                                        side.id,
                                        id
                                      );
                                    }}
                                    withOutToolTip
                                    label="يمكنك إرفاق ملف ولا يزيد حجم الملف عن 30 ميجا"
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </>
                      </div>
                    </div>
                  </>
                );
              })}
          </MainRequirement>
        </>
      );
    });

  return (
    <div>
      <StandardAnswerRequirement setMenuElement={setMenuElement}>
        <div className="flex  py-5 w-full">
          <p
            className={
              selectedRequirementsTab == "agency"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("agency")}
          >
            الجهة
          </p>
          <p
            className={
              selectedRequirementsTab == "reviewer"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("reviewer")}
          >
            المدقق
          </p>

          <p
            className={
              selectedRequirementsTab == "expert"
                ? "bg-indigo-800 text-white px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-emerald-50 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("expert")}
          >
            الخبير
          </p>
        </div>

        {selectedRequirementsTab === "agency" && <AgencyRequirementAnswer />}
        {selectedRequirementsTab === "reviewer" && (
          // <ReadOnlyRequirements
          //   label={"تقييم المدقق"}
          //   requirements={newExpertAnswer?.reviewerRequirementsAnswer}
          // />

          <ReadOnlyRequirementAnswers
            requirements={newExpertAnswer?.reviewerRequirementsAnswer}
            commitmentLevels={commitmentLevels}
          />
        )}
        {selectedRequirementsTab === "expert" && (
          <>{renderedExpertRequirements}</>
        )}
      </StandardAnswerRequirement>
    </div>
  );
};

function ReadOnlyRequirementAnswers({ requirements, commitmentLevels }) {
  console.log("ReadOnlyRequirementAnswers", requirements);

  return (
    requirements?.length > 0 &&
    requirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      const hasAnswer =
        requirement?.subStandardRequirements?.[0]?.reviewerSubRequirementAnswerSides;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {}}
            handleDonotApplyRequirementChange={() => {}}
          >
            {!hasAnswer && <h2 className="text-2xl pb-2">لا يوجد إجابة حتى الآن</h2>}
            {hasSubRequirements && hasAnswer &&
              requirement?.subStandardRequirements?.map((subRequirement) => {
                return (
                  <>
                    <div className="px-5 flex flex-col mb-3 py-2">
                      <h2 className="text-2xl pb-2">{subRequirement.title}</h2>
                      <div className="flex flex-col gap-4">
                        <>
                          {subRequirement?.subRequirementAnswerSides?.map(
                            (side, index) => {
                              return (
                                <div className="border-b pb-2">
                                  <h3 className="text-xl pb-3">{side.title}</h3>
                                  <div className=" flex items-center gap-2 mb-4">
                                    <span className="w-24">تقييم المدقق</span>
                                    <TextInput
                                      value={side?.evaluationDegree}
                                      type="number"
                                      placeholder=""
                                      min={0}
                                      max={100}
                                      disabled
                                    />
                                    <label>
                                      {getCommitmentLevelsBasedOnDegree(
                                        Number(
                                          side?.evaluationDegree
                                        ),
                                        commitmentLevels
                                      )}
                                    </label>
                                  </div>
                                  <div className="flex gap-2 w-full items-center mb-4">
                                    <span className="w-24"> الملاحظة</span>
                                    <TextInput
                                      value={side?.evaluationNote}
                                      className="grow"
                                      placeholder="ملاحظة المدقق للجهة"
                                      disabled
                                    />
                                  </div>
                                  <div className="flex gap-2 w-full items-center">
                                    <span className="w-24">التوصية</span>
                                    <TextInput
                                      value={side?.recommendation}
                                      className="grow"
                                      placeholder="توصية المدقق للجهة"
                                      disabled
                                    />
                                  </div>
                                  <UploadFile
                                    value={side.attachmentId}
                                    withOutToolTip
                                    readOnly
                                    label="يمكنك إرفاق ملف ولا يزيد حجم الملف عن 30 ميجا"
                                  />
                                </div>
                              );
                            }
                          )}
                        </>
                      </div>
                    </div>
                  </>
                );
              })}
          </MainRequirement>
        </>
      );
    })
  );
}
