import HeaderWithBreadcrumbLayout from "../../../../../layouts/headerWithBreadcrumbLayout";
import AddAboutStandard from "./addAboutStandard";
import { AddStandardProvider } from "./addStandardContext";
import AddStandardRequirements from "./standardRequirements";

import { useLocation, useParams } from "react-router";
import { programsIcon } from "../../../../../assets/icons/Index";
import { useQuery } from "../../../../../helpers/utils";
import StandardOrders from "./standardOrders";
import StandardProofFiles from "./standardProofFiles";
import SubmitAddStandard from "./submitAddStandard";

const AddStandard = (props) => {
  const search = useQuery();
  const { surveyId, perspectiveId, interlocutorId } = useParams();
  const { indicatorId } = useLocation();
  const disableAddStandard = false;

  const addStanderdsBreadcumbs = [
    {
      icon: programsIcon,
      title: "استبيان التقنيات الناشئة",
      url: `/indicators/${indicatorId || search.get("indicatorId") || localStorage.getItem("NavIndicatorId")}`,
    },
    {
      title: "القدرات",
      url: `/programs/${surveyId}/perspectives`,
    },
    {
      title: "العناصر",
      url: `/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor`,
    },
    {
      title: "المعايير",
      url: `/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards`,
    },
    {
      title: "إضافة معيار",
    },
  ];

  return (
    <AddStandardProvider>
      <HeaderWithBreadcrumbLayout breadcrumbArr={addStanderdsBreadcumbs}>
        <div className="bg-gray_light p-5 mb-10 rounded-lg pb-36 relative">

          <AddAboutStandard from={"add"} disabled={disableAddStandard} />
          <AddStandardRequirements from={"add"} disabled={disableAddStandard} />

          <StandardProofFiles disabled={disableAddStandard} />
          <StandardOrders disabled={disableAddStandard} />
          <SubmitAddStandard disabled={disableAddStandard} />
        </div>{" "}
      </HeaderWithBreadcrumbLayout>
    </AddStandardProvider>
  );
};

export default AddStandard;
