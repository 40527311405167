import { Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import useText from "../../../../helpers/customHooks/useText";
import environment from "../../../../services/environment";
import { axiosGetRequest, get, post } from "../../../../services/Request";
import ListUsers from "../../home/Users";

import { toast } from "react-toastify";
import {
  AppInput,
  DropdownMultiSelect,
  FModal,
  InputLabel,
  SModal,
} from "../../../../components";
import { UsersContext } from "../../../../contexts/UsersContext";
import { useTranslation } from "react-i18next";
import { permissionsCheck } from "../../../../helpers/utils";
import { avatar, programsIcon } from "../../../../assets/icons/Index";
import NewTable from "../../../../components/NewTable";
import HeaderWithBreadcrumbLayout from "../../../../layouts/headerWithBreadcrumbLayout";
import DrawerFilter from "../../../../components/DrawerFilter";
import useAxiosGetRequest from "../../../../helpers/customHooks/useAxiosGetRequest";
import NewDropdownMultiSelect from "../../../../components/NewDropDownMultiSelect";
import { useHistory } from "react-router-dom";
import useExcelExport from "../../../../helpers/customHooks/useExcelExport";
import CustomTable from "../../../../components/CustomTable";

const UsersList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const usersContext = useContext(UsersContext);
  const [values, handleChange] = useText();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showAddNumber, setShowAddNumber] = useState(false);

  const [usersForExcel, setUsersForExcel] = useState([]);

  const [totalPages, setTotalPages] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchFilter, setSearchFilter] = useState("");

  const [imageUrl, setImageUrl] = useState(null);
  const [statuses, setStatuses] = useState([
    { id: 1, label: "الحالة", labelEn: "Status", value: null },
    { id: 2, label: "نشط", labelEn: "Active", value: true },
    { id: 3, label: "غير نشط", labelEn: "Un active", value: false },
  ]);
  const [totalRows, setTotalRows] = useState(0);

  const [openPermissions, setOpenPermissions] = useState(false);
  const [openEntities, setOpenEntities] = useState(false);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState({});

  const [role, setRole] = useState([]);
  const [roleStatic, setRoleStatic] = useState(null);
  const [entity, setEntity] = useState(null);

  const [roles, setRoles] = useState([]);
  const [rolesStatic, setRolesStatic] = useState([]);
  const [entities, setEntities] = useState([]);
  const [perPage, setPerPage] = useState({ label: "10", value: 10 });
  const [experts, setExperts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [selectedExperts, setSelectedExperts] = useState([]);
  const [entitiesForFilter, setEntitiesForFilter] = useState([])
  // const [getEntitiesForFilter, entitiesForFilter] = useAxiosGetRequest();
  const formUserRef = useRef(null);

  const getEntitiesForFilter =async () => {
    const res =await axiosGetRequest(environment.getAllAgencyWithoutPagination);
    setEntitiesForFilter(res)
  }

  const handleSubmitAddUser = () => {
    if (formUserRef.current) {
      formUserRef.current?.handleSubmit();
    }
  };
  useEffect(() => {
    getEntitiesForFilter();
    getAllUsersExcel(0,10)
    getAllUsers(0, 10);
    getAllRoles()
    getAllExperts();
  }, [searchText]);

  const entitiesOptions =
    entitiesForFilter?.result &&
    entitiesForFilter?.result?.map((item) => {
      return { label: item?.nameInArabic?.toString() || "", value: item.id };
    });

  const handleChangeExperts = (value) => {
    setSelectedExperts(value);
  };
  const handleChangeRoles = (value) => {
    setRole(value);
  };

  const handleChangeRoleStatic = (value) => {
    setRoleStatic(value);
  };

  const handleChangeRole = (value) => {
    usersContext.setSelectedRole(value);
  };

  const handleChangeStatus = (value) => {
    usersContext.setSelectedStatus(value);
  };

  const handleChangeEntities = (value) => {
    setEntity(value);
  };

  const handleChangeEntitiesFilter = (value) => {
    usersContext.setSelectedEntity(value);
  };

  

  const getAllUsersExcel = (page, perPage) => {
    setLoading(true);
    let arr = [];
    if (usersContext.selectedRole != null) {
      usersContext.selectedRole.forEach((item, index) => {
        arr.push(item.id);
      });
    } else {
      arr = [];
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let status = "";
    let entity = "";
    let search = ""
    let searchF = ""
    let roles = [];
    if (usersContext.selectedStatus.value != null) {
      status = "&IsActive=" + usersContext.selectedStatus.value;
    }
    if (usersContext.selectedEntity != null) {
      entity = "&AgencyId=" + usersContext.selectedEntity.value;
    }
    if (searchText != "") {
      search = "&Keyword=" + searchText;
    }
    if (searchFilter != "") {
      search = "&Keyword=" + searchFilter;
    }

    if (arr.length != 0) {
      arr.forEach((item, index) => {
        roles = "&Roles=" + item;
      });
    }

    get(
      environment.getAllUsers +
        "?SkipCount=" +
        page * perPage +
        "&MaxResultCount=" +
        1000000 +
        status +
        entity +
        search +
        searchF +
        roles,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(res.data.error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 500) {
          toast.error(res.data.error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 200) {
          // setTotalPages(res.data.result.totalCount / perPage);
          // setTotalRows(res.data.result.totalCount);
          setUsersForExcel(res.data.result.items, getAllRoles());
          // setFilteredData(res?.data?.result?.items);
        }
      }
    );
  };
  const getAllUsers = (page, perPage) => {
    setLoading(true);
    let arr = [];
    if (usersContext.selectedRole != null) {
      usersContext.selectedRole.forEach((item, index) => {
        arr.push(item.id);
      });
    } else {
      arr = [];
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let status = "";
    let entity = "";
    let search = ""
    let searchF = ""
    let roles = [];
    if (usersContext.selectedStatus.value != null) {
      status = "&IsActive=" + usersContext.selectedStatus.value;
    }
    if (usersContext.selectedEntity != null) {
      entity = "&AgencyId=" + usersContext.selectedEntity.value;
    }
    if (searchText != "") {
      search = "&Keyword=" + searchText;
    }
    if (searchFilter != "") {
      searchF = "&Keyword=" + searchFilter;
    }

    if (arr.length != 0) {
      arr.forEach((item, index) => {
        roles = "&Roles=" + item;
      });
    }

    get(
      environment.getAllUsers +
        "?SkipCount=" +
        page * perPage +
        "&MaxResultCount=" +
        perPage +
        status +
        entity +
        search +
        searchF +
        roles,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(res.data.error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 500) {
          toast.error(res.data.error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 200) {
          getAllUsersExcel(page,perPage)
          setTotalPages(res.data.result.totalCount / perPage);
          setTotalRows(res.data.result.totalCount);
          setUsers(res.data.result.items);
          setFilteredData(res?.data?.result?.items,setLoading(false));
        }
      }
    );
  };
  const getAllRoles = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
  
    get(environment.getAllRoles + "?isStatic=" + false, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((role) => {
          arr.push({ id: role.id, label: role.name, value: role.name });
        });
        setRoles(arr, getAllRolesStatic());
      }
    });
  };

  const getAllRolesStatic = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.getRoles + "?MaxResultCount=" + 1000,
      config,
      (res) => {
        if (res?.status == 403) {
          toast.error(res.data?.error?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res?.status == 500) {
          toast.error(res.data?.error?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res?.status == 200) {
          let arr = [];
          res.data.result.items.forEach((role) => {
            arr.push({ id: role.id, label: role.name, value: role.name });
          });
          setRolesStatic(arr, getAllAgency());
        }
      }
    );
  };

  const passwordValidation = (password) => {
    var re = {
      capital: /(?=.*[A-Z])/,
      length: /(?=.{7,40}$)/,
      specialChar: /[ -\/:-@\[-\`{-~]/,
      digit: /(?=.*[0-9])/,
    };
    return (
      re.capital.test(password) &&
      re.length.test(password) &&
      re.specialChar.test(password) &&
      re.digit.test(password)
    );
  };

  const getAllAgency = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllAgencyWithoutPagination, config, (res) => {
      console.log(res);
      if (res?.status == 403) {
        toast.error(res.data?.error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res?.status == 500) {
        toast.error(res.data?.error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res?.status == 200) {
        console.log(res);
        // let arr = [];
        // let arrayResult = res?.data?.result?.map((item) => {
        //  return ({ label: item.name.toString(), value: item.id, id: item.id });
        // });
        // console.log(arrayResult)
        if (res.data.result && res.data.result.length > 0) {
          var arr = [];
          for (var i = 0; i < res.data.result.length; i++) {
            arr.push({
              value: res.data.result[i].id,
              label: res.data.result[i].name.toString(),
              isSupported: res.data.result[i].isSupported,
              isSelected: false,
            });
          }
          console.log(arr);
          // setAgencies(array);
          arr.unshift({ label: "جهة حكومية", value: "", id: "" });
          setEntities(arr);
        }
      }
    });
  };

  const getAllExperts = () => {
    // setLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    get(environment.getAllExpertLookUp, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((user) => {
          arr.push({ id: user.id, label: user.fullName, value: user.name });
        });
        setExperts(arr, setLoading(false));
      }
    });
  };

  const addUser = (values, profilePictureId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let roleArr = [];
    role.forEach((role, index) => {
      roleArr.push(role.value);
    });
    roleArr.push(roleStatic.value);
    let newExperts = [];
    if (roleStatic.value == "Reviewer") {
      selectedExperts.forEach((expert) => {
        newExperts.push(expert.id);
      });
    }
    console.log(entity);
    let data = {
      userName: values.userName,
      name: values.name,
      surname: values.surname,
      emailAddress: values.emailAddress,
      isActive: true,
      roleNames: roleArr,
      password: values.password,
      agencyId:
        // roleStatic.value == "POC" ||
        // roleStatic.value == "CIO" ||
        // roleStatic.value == "Delegate"
        entity != null ? entity.value : "",
      // : "",
      mobile: values.mobile,
      // profilePictureId: profilePictureId,
      nationalId: values.nationalId,
      experstIds: newExperts,
    };

    console.log(data);

    post(environment.addUser, data, config, (res) => {
      setSelectedFile("");
      setImageUrl(avatar);
      setRole([]);
      if (res.status == 200) {
        toast.success(t("تم اضافة المستخدم بنجاح"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setRoleStatic("");
        setEntity("");
        setOpen(false);
        setLoading(false, getAllUsers(currentPage, perPage.value));
      } else if (res.status == 400) {
        toast.error(t("اسم المستخدم يحب الا يحتوي علي رموز"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false, getAllUsers(currentPage, perPage.value));
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false, getAllUsers(currentPage, perPage.value));
      } else {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false, getAllUsers(currentPage, perPage.value));
      }
    });
  };

  const onPageChange = (page) =>
    setCurrentPage(page, getAllUsers(page, perPage.value));
  const onChangeFile = (event) => {
    setSelectedFile(
      event.target.files[0],
      setImageUrl(URL.createObjectURL(event.target.files[0]))
    );
  };

  const onFileUpload = (values) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const formData = new FormData();

    formData.append("Name", selectedFile.name);
    formData.append("FileType", "image/*");
    formData.append("EntityType", "");
    formData.append("File", selectedFile);
    post(environment.attachmentFile, formData, config, (res) => {
      addUser(values, res.data?.result?.id);
    });
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllUsers(newOffset, perPage.value);
  };

  const handlePerRowsChange = (value) => {
    setPerPage(value,getAllUsers(currentPage,value.value));
  };

  const handlePageChange = (page) => {
    getAllUsers(page);
  };

    const downloadExcel = useExcelExport();


  const clearSearch = () => {
    usersContext.setSelectedRole([]);
    usersContext.setSelectedEntity(null);
    usersContext.setSelectedStatus({
      id: 1,
      label: "الحالة",
      labelEn: "Status",
      value: null,
    });
    setPerPage({ label: "5", value: 5 });
    setSearchFilter("")

  };

  const usersDetailsBreadcumbs = [
    {
      icon: programsIcon,
      title: "مستخدمو النظام",
      url: "/users",
    },
    {
      title: "المستخدمون",
      url: "/users",
    },
  ];

  const handleFilterData = (value) => {
    // console.log(searchText)
    // if(searchText != ""){
    //   console.log("مليان")
    //   console.log(searchText)
      setSearchText(value,getAllUsers(currentPage, perPage.value));
    // }else{
    //   console.log("فاضي")
    //   console.log(searchText)
    //   getAllUsers(0,10)
    // }
    // if (!value) {
    //   setFilteredData(users);
    // } else {
    //   var searchData = users.filter((item) => {
    //     if (
    //         item.id
    //         .toString()
    //         .toLowerCase()
    //         .includes(value.toLowerCase()) ||
    //         item.fullName
    //         .toString()
    //         .toLowerCase()
    //         .includes(value.toLowerCase()) ||
    //         item.mobile
    //         .toString()
    //         .toLowerCase()
    //         .includes(value.toLowerCase()) ||
    //         item.staticRole
    //         .toString()
    //         .toLowerCase()
    //         .includes(value.toLowerCase())
    //     ) {
    //       return item;
    //     }
    //   });
    //   setFilteredData(searchData);
    //   setUsersForExcel(searchData)
    // }
  };
  console.log(users[0]);

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={usersDetailsBreadcumbs}
      btnLabel={"اٍضافة مستخدم"}
      handleBtnClick={() => setOpen(true)}
      hideBtn={
        permissionsCheck("Pages.Administration.Users.Create") ? false : true
      }
      handleBtnSeconedClick={() => history.push("/identities")}
      btnLabelSeconed={"إضافة هوية"}
    >
      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />
      <FModal
        content={
          <div>
            <Formik
              innerRef={formUserRef}
              initialValues={{
                userName: "",
                name: "",
                surname: "",
                emailAddress: "",
                isActive: false,
                roleNames: [],
                password: "",
                agencyId: "",
                mobile: "",
                nationalId: "",
                profilePictureId: "",
                roleStatic: "",
                experstIds: [],
                selectedExperts:[]
              }}
              validate={(values) => {
                const errors = {};
                if (!values.userName) {
                  errors.userName = t(
                    "modules.main.users.users.messages.userName"
                  );
                }
                if (!values.name) {
                  errors.name = t("modules.main.users.users.messages.name");
                }
                var inValid = /^\S+$/;
                var inValidEnglish = /^[A-Za-z]+$/;

                console.log(inValid.test(values.userName));
                if (
                  inValid.test(values.userName) == false ||
                  !inValidEnglish.test(values.userName)
                ) {
                  errors.userName = t(
                    "اسم المستخدم يجب الا يحتوي علي مسافة و يجب ان يكون انجليزي"
                  );
                }

                // if(!inValidEnglish.test(values.userName)){
                //   errors.userName = t(
                //     "يجب ان يكون اسم المستخدم انجليزي فقط"
                //   );
                // }
                if (!values.surname) {
                  errors.surname = t(
                    "modules.main.users.users.messages.surname"
                  );
                }
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!regex.test(values.emailAddress)) {
                  errors.emailAddress = t(
                    "modules.main.users.users.messages.emailFormat"
                  );
                }
                if (!values.emailAddress) {
                  errors.emailAddress = t(
                    "modules.main.users.users.messages.email"
                  );
                }
                if (!values.password) {
                  errors.password = t(
                    "modules.main.users.users.messages.password"
                  );
                }
                if (values.password.length < 6) {
                  errors.password = t(
                    "modules.main.users.users.messages.lengthPassword"
                  );
                }
                if (!passwordValidation(values.password)) {
                  errors.password = t(
                    "modules.main.users.users.messages.charachterPassword"
                  );
                }
                // if (!role || role.length == 0) {
                //   errors.roleNames = t(
                //     "modules.main.users.users.messages.listEntites"
                //   );
                // }
                if (roleStatic == null) {
                  errors.roleStatic = t(
                    "modules.main.users.users.messages.permissionStatic"
                  );
                }

                  if (roleStatic&& roleStatic.value === "Reviewer" && selectedExperts.length == 0 ) {
                  errors.selectedExperts = t(
                    "modules.main.users.users.messages.expert"
                  );
                }



                if (!values.mobile) {
                  errors.mobile = t("modules.main.users.users.messages.phone");
                }
                if(values.mobile){
                  if (values.mobile.length != 14) {
                    errors.mobile = t(
                      "modules.main.users.users.messages.phoneLength"
                    );
                  }
                  if (!values.mobile.startsWith("0096")) {
                    errors.mobile = t(
                      "modules.main.users.users.messages.phoneStart"
                    );
                  }
                }

                if (!values.nationalId) {
                  errors.nationalId = t("modules.main.users.users.messages.nationalId");
                }
                if(values.nationalId){
                  if (values.nationalId.length != 10) {
                    errors.nationalId = t(
                      "modules.main.users.users.messages.nationalIdLength"
                    );
                  }
                  if (isNaN(values.nationalId)) {
                    errors.nationalId = t(
                      "modules.main.users.users.messages.nationalIdType"
                    );
                  }                  
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values);
                addUser(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <div>
                  <InputLabel
                    value={values.userName}
                    onChange={handleChange}
                    name="userName"
                    type={"text"}
                    label={t("modules.main.users.users.form.userName")}
                    placeholder={t(
                      "modules.main.users.users.placeholder.userName"
                    )}
                    style={{
                      borderColor: errors.userName
                        ? "red"
                        : values.userName != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.userName && touched.userName && errors.userName}
                  </div>
                  <InputLabel
                    value={values.name}
                    onChange={handleChange}
                    name="name"
                    type={"text"}
                    label={t("modules.main.users.users.form.name")}
                    placeholder={t("modules.main.users.users.placeholder.name")}
                    style={{
                      borderColor: errors.name
                        ? "red"
                        : values.name != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.name && touched.name && errors.name}
                  </div>
                  <InputLabel
                    value={values.surname}
                    onChange={handleChange}
                    name="surname"
                    type={"text"}
                    label={t("modules.main.users.users.form.surname")}
                    placeholder={t(
                      "modules.main.users.users.placeholder.surname"
                    )}
                    style={{
                      borderColor: errors.surname
                        ? "red"
                        : values.surname != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.surname && touched.surname && errors.surname}
                  </div>
                  <InputLabel
                    value={values.emailAddress}
                    onChange={handleChange}
                    name="emailAddress"
                    type={"email"}
                    label={t("modules.main.users.users.form.email")}
                    placeholder={t(
                      "modules.main.users.users.placeholder.email"
                    )}
                    style={{
                      borderColor: errors.emailAddress
                        ? "red"
                        : values.emailAddress != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.emailAddress &&
                      touched.emailAddress &&
                      errors.emailAddress}
                  </div>

                  <InputLabel
                    value={values.password}
                    onChange={handleChange}
                    name="password"
                    type={"password"}
                    label={t("modules.main.users.users.form.password")}
                    placeholder={t(
                      "modules.main.users.users.placeholder.password"
                    )}
                    style={{
                      borderColor: errors.password
                        ? "red"
                        : values.password != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.password && touched.password && errors.password}
                  </div>

                  {/* <NewDropdownMultiSelect
                    isSearchable={true}
                    value={role}
                    handleChange={handleChangeRoles}
                    placeholder={t(
                      "modules.main.users.users.placeholder.permissions"
                    )}
                    label={t("modules.main.users.users.form.permissions")}
                    options={roles}
                    isMultiple={true}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.roleNames && touched.roleNames && errors.roleNames}
                  </div> */}

                  <DropdownMultiSelect
                    isSearchable={true}
                    value={roleStatic}
                    handleChange={handleChangeRoleStatic}
                    placeholder={t(
                      "modules.main.users.users.placeholder.permissionStatic"
                    )}
                    label={t("modules.main.users.users.form.permissionsStatic")}
                    options={rolesStatic}
                    isMultiple={false}
                    isRequired={true}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.roleStatic &&
                      touched.roleStatic &&
                      errors.roleStatic}
                  </div>
                  {/* {roleStatic?.value == "POC" ||
                  roleStatic?.value == "CIO" ||
                  roleStatic?.value == "Delegate" ? ( */}
                  <div>
                    <DropdownMultiSelect
                      isSearchable={true}
                      value={entity}
                      handleChange={handleChangeEntities}
                      placeholder={t(
                        "modules.main.users.users.placeholder.entity"
                      )}
                      label={t("modules.main.users.users.form.entity")}
                      options={entitiesOptions}
                      isMultiple={false}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.agencyId && touched.agencyId && errors.agencyId}
                    </div>
                  </div>
                  {/* ) : null} */}
                  <div className="text-sm text-[#202969] mt-3">
                    يتم اختيار الجهة في حالة كانت الصلاحية جهة
                  </div>

                  {/* {roleStatic?.value == "Reviewer" ? ( */}
                  <div>
                    <NewDropdownMultiSelect
                      label={"الخبراء"}
                      placeholder={"اختر خبير"}
                      options={experts}
                      handleChange={handleChangeExperts}
                      value={selectedExperts}
                      isMultiple={true}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.selectedExperts && touched.selectedExperts && errors.selectedExperts}
                    </div>
                  </div>
                  {/* ) : null} */}
                  <div className="text-sm text-[#202969] mt-3">
                    يتم اختيار الخبراء في حالة كانت الصلاحية مدقق
                  </div>

                  <InputLabel
                    value={values.mobile}
                    onChange={handleChange}
                    type={"text"}
                    name="mobile"
                    label={t("modules.main.users.users.form.phone")}
                    placeholder={t(
                      "modules.main.users.users.placeholder.phone"
                    )}
                    style={{
                      borderColor: errors.mobile
                        ? "red"
                        : values.mobile != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.mobile && touched.mobile && errors.mobile}
                  </div>

                  <InputLabel
                    value={values.nationalId}
                    onChange={handleChange}
                    type={"text"}
                    name="nationalId"
                    label={t("modules.main.users.users.form.nationalId")}
                    placeholder={t(
                      "modules.main.users.users.placeholder.nationalId"
                    )}
                    style={{
                      borderColor: errors.nationalId
                        ? "red"
                        : values.nationalId != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.nationalId && touched.nationalId && errors.nationalId}
                  </div>
                </div>
              )}
            </Formik>
          </div>
        }
        open={open}
        titleButton={t("modules.main.users.users.titleAddButton")}
        setOpen={() => setOpen(false)}
        action={handleSubmitAddUser}
        type={"submit"}
        headerTitle={t("modules.main.users.users.titleAddNewButton")}
      />
      <div className="my-5">
        <div className="my-5 mb-7 flex justify-end">
          <DrawerFilter
            handleSearch={() => getAllUsers(currentPage, perPage.value)}
            handleClear={clearSearch}
          >
            <AppInput
              type={"text"}
              value={searchFilter}
              name={"searchFilter"}
              onChange={(e) => setSearchFilter(e.target.value)}
              label={"بحث"}
              variant="gray"
              isRequired={false}
            />
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={true}
              value={usersContext.selectedEntity}
              handleChange={handleChangeEntitiesFilter}
              placeholder={t("modules.main.users.users.filter.entity")}
              label={t("modules.main.users.users.filter.entity")}
              options={entitiesOptions}
              isMultiple={false}
            />
            <NewDropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={usersContext.selectedRole}
              handleChange={handleChangeRole}
              placeholder={t("modules.main.users.users.filter.permission")}
              label={t("modules.main.users.users.filter.permission")}
              options={rolesStatic}
              isMultiple={true}
            />
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={usersContext.selectedStatus}
              handleChange={handleChangeStatus}
              placeholder={t("modules.main.users.users.filter.action")}
              label={t("modules.main.users.users.filter.action")}
              options={statuses}
              isMultiple={false}
            />

            {/* <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={perPage}
              handleChange={handlePerRowsChange}
              placeholder={t("عدد العناصر في الجدول")}
              label={t("عدد العناصر في الجدول")}
              options={[
                { label: "5", value: 5 },
                { label: "10", value: 10 },
                { label: "25", value: 25 },
                { label: "50", value: 50 },
                { label: "100", value: 100 },
              ]}
              isMultiple={false}
            /> */}
          </DrawerFilter>
        </div>

        <div className="mt-3">
          {permissionsCheck("Pages.Administration.Users.GetAll") ? (
            <CustomTable
              onSearch={handleFilterData}
              withExcel
              withSearch
              loading={loading}
              columns={ListUsers}
              data={filteredData || users}
              totalPages={totalPages}
              onPageChange={handlePageClick}
              currentPage={currentPage}
              handlePerRowsChange={handlePerRowsChange}
              // handlePageChange={handlePageChange}
              onExportExcel={() => downloadExcel("users", usersForExcel)}
              totalRows={totalRows}
              handlePerPageChange={handlePerRowsChange}
              perPage={perPage}
              showPerPage={true}
            />
          ) : (
            <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
              {"ليس لديك صلاحية لهذا"}
            </div>
          )}
        </div>
      </div>
    </HeaderWithBreadcrumbLayout>
  );
};

export default UsersList;
