import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { programsIcon } from "../../../../../assets/icons/Index";
import AppLoader from "../../../../../components/loader";
import { errorToast } from "../../../../../helpers/toast";
import { useQuery as useSearchQuery } from "../../../../../helpers/utils";
import HeaderWithBreadcrumbLayout from "../../../../../layouts/headerWithBreadcrumbLayout";
import { axiosGetRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import AddAboutStandard from "../addStandard/addAboutStandard";
import { AddStandardContext } from "../addStandard/addStandardContext";
import StandardOrders from "../addStandard/standardOrders";
import StandardProofFiles from "../addStandard/standardProofFiles";
import StandardRequirements from "../addStandard/standardRequirements";
import SubmitEditStandard from "./submitEditStandard";
import { useQuery } from "@tanstack/react-query";

const EditStandard = () => {
  const search = useSearchQuery();
  const history = useHistory();
  const { surveyId, perspectiveId, interlocutorId, standardId } = useParams();
  const { indicatorId } = useLocation();
  const {
    addFinalValue,
    setAddFinalValue,
    setEditRequirementClassifications,
    setSubRequirementCount,
    setSelectedStandards,
    setSelectedAgencies,
    setSelectedCommonAgencies,
    setMainAgency,
    setNonReTurnableStandard,
  } = useContext(AddStandardContext);

  const [loadData, setLoadData] = useState(false);

  const { data: isPublished } = useQuery({
    queryKey: ["isPublished", surveyId],
    queryFn: () => {
      return axiosGetRequest(
        environment.getSurveyStagesBySuveyId,{
          surveyId: surveyId,
        }
      );
    },
    select: (data) => {
      return data?.result?.isPublished;
    }
  });

  const disableEditStandard = isPublished;

  const getStandardForEdit = async () => {
    setLoadData(true);
    const res = await axiosGetRequest(environment.getStandardForEdit, {
      id: standardId,
    });
    if (!res) {
      setLoadData(false);
      errorToast("يرجى التواصل مع الدعم الفنى");

      history.goBack();
    }
    if (res?.success) {
      // * Stamdard About Data
      const data = res?.result;

      const mappedStandardRelations =
        data?.standardRelations?.map((item) => ({
          label: item.standardRelatedId?.toString() || "",
          value: item.standardRelatedId,
          id: item.standardRelatedId,
        })) || [];
      setSelectedStandards(mappedStandardRelations);

      const mappedSelectedAgencyStandard =
        data?.agencyStandards
          ?.filter((item) => !item?.parentAgencyId)
          ?.map((item) => ({
            label: item?.agencyTitle?.toString() || "",
            value: item?.agencyId,
            id: item?.agencyId,
            agencyId: item?.agencyId,
          })) || [];
      setSelectedAgencies(mappedSelectedAgencyStandard);

      const mappedCommonAgencies =
        data?.agencyStandards
          ?.filter(
            (item) =>
              item.isParentAgency || item?.parentAgencyId == item?.agencyId
          )
          ?.map((item) => ({
            label: item?.agencyTitle,
            value: item?.agencyId,
            id: item?.agencyId,
            agencyId: item?.agencyId,
          })) || [];
      setSelectedCommonAgencies(mappedCommonAgencies);

      const mainAgencyObj = data?.agencyStandards?.find(
        (item) => item?.parentAgencyId
      );
      const mappedMainAgencies = mainAgencyObj
        ? data?.agencyStandards
            ?.filter((item) => item.agencyId == mainAgencyObj.parentAgencyId)
            .map((item) => ({
              label: item?.agencyTitle,
              value: item?.agencyId,
              id: item?.agencyId,
              agencyId: item?.agencyId,
            }))?.[0]
        : "";

      setMainAgency(mappedMainAgencies);
      setNonReTurnableStandard(data?.nonReTurnableStandard);

      // Stamdard Requirements

      const originalStandardRequirements =
        data?.standardRequirements?.map((req) => {
          // data?.standardRequirements?.foreach((req) => {
          //   setSubRequirementCount(
          //     (prev) => prev + req.subStandardRequirements?.length
          //   );
          // });

          const subReqLength = Number(req?.subStandardRequirements?.length);
          setSubRequirementCount((prev) => prev + subReqLength);

          const standardRequirementsObj = {
            id: req?.id,
            title: req?.title,
            standardRequirementClassificationId:
              req?.standardRequirementClassificationId,
            standardRequirementClassificationTitle:
              req?.standardRequirementClassification?.title,
            isExisted: req?.isExisted,
            subStandardRequirements: req?.subStandardRequirements?.map(
              (subReq) => ({
                id: subReq?.id,
                title: subReq?.title,
                standardRequirementClassificationId:
                  subReq?.standardRequirementClassificationId,
                standardRequirementClassification:
                  subReq?.standardRequirementClassification?.title,
                haveAttachments: subReq?.haveAttachments,
                recommendation: subReq?.recommendation,
                answerMandatory: subReq?.answerMandatory,
                numOfTextFields: subReq?.numOfTextFields,
                requirementType: subReq?.requirementType,
                isExisted: subReq?.isExisted,
                questionBankId: subReq?.questionBankId,
              })
            ),
          };

          return standardRequirementsObj;
        }) || [];

      setEditRequirementClassifications(originalStandardRequirements);

      const standardRequirements =
        data?.standardRequirements?.map((item) => {
          // data?.standardRequirements?.foreach((item) => {
          //   setSubRequirementCount(
          //     (prev) => prev + item.subStandardRequirements?.length
          //   );
          // });

          // const subReqLength = Number(item?.subStandardRequirements?.length);
          // setSubRequirementCount((prev) => prev + subReqLength);

          const standardRequirementsObj = {
            id: item?.id,
            title: item?.title,
            recommendation: item?.recommendation,
            standardRequirementClassificationId:
              item?.standardRequirementClassificationId,
            standardRequirementClassificationTitle:
              item?.standardRequirementClassification?.title,
            // agencyStandardRequirements: item?.agencyStandardRequirements,
            isExisted: item?.isExisted,
            subStandardRequirements: item?.subStandardRequirements?.map(
              (item) => ({
                id: item?.id,
                title: item?.title,
                recommendation: item?.recommendation,
                standardRequirementClassificationId:
                  item?.standardRequirementClassificationId,
                standardRequirementClassification:
                  item?.standardRequirementClassification?.title,
                haveAttachments: item?.haveAttachments,
                answerMandatory: item?.answerMandatory,
                numOfTextFields: item?.numOfTextFields,
                requirementType: item?.requirementType,
                isExisted: item?.isExisted,
                questionBankId: item?.questionBankId,
              })
            ),
          };

          return standardRequirementsObj;
        }) || [];

      // sort
      // const nums = standardRequirements?.sort(
      //   (a, b) => Number(a.title[0]) - Number(b.title[0])
      // );

      // * Standard Proofs
      const standardProofs = data?.standardProofs?.map((item) => ({
        ...item,
        id: item.id,
        fileCount: item?.fileCount,
        fileSize: item?.fileSize,
        isRequired: true,
        standardProofFileSizeTypeId: item.standardProofFileSizeTypeId,
        standardProofTypeId: item.standardProofTypeId,
        title: item.title,
        // agencyStandardProofs: item.agencyStandardProofs,
        standardProofRequirements: item.standardProofRequirements,
        standardProofFileTypeAssociations:
          item?.standardProofFileTypeAssociations?.map((val) => ({
            standardProofFileTypeId: val?.standardProofFileTypeId,
          })),
      }));

      setAddFinalValue({
        ...data,
        originalStandardRequirements,
        standardRequirements,
        standardProofs,
        agencyStandards: {
          agencies:
            mappedSelectedAgencyStandard?.map((item) => ({
              agencyId: item.id,
              parentAgencyId: null,
              isParentAgency: false,
            })) || [],
          commonAgencies:
            mappedCommonAgencies?.map((item) => {
              if (item.agencyId == mainAgencyObj?.parentAgencyId) {
                return {
                  agencyId: item.id,
                  parentAgencyId: mainAgencyObj?.parentAgencyId,
                  isParentAgency: false,
                };
              } else {
                return {
                  agencyId: item.id,
                  parentAgencyId: mainAgencyObj?.parentAgencyId,
                  isParentAgency: true,
                };
              }
            }) || [],
        },
        standardRelations:
          mappedStandardRelations?.map((item) => ({
            standardRelatedId: item.id,
          })) || [],
      });
      setLoadData(false);
    }
  };
  useState(() => {
    getStandardForEdit();
  }, []);

  const editStanderdsBreadcumbs = [
    {
      icon: programsIcon,
      title: "استبيان التقنيات الناشئة",
      url: `/indicators/${indicatorId || search.get("indicatorId") || localStorage.getItem("NavIndicatorId")}`,
    },
    {
      title: "القدرات",
      url: `/programs/${surveyId}/perspectives`,
    },
    {
      title: "العناصر",
      url: `/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor`,
    },
    {
      title: "المعايير",
      url: `/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards`,
    },
    {
      title: "تعديل معيار",
    },
  ];

  console.log("addFinalValue", addFinalValue);

  return (
    <HeaderWithBreadcrumbLayout breadcrumbArr={editStanderdsBreadcumbs}>
      <div className="bg-gray_light p-5 mb-10 rounded-lg pb-36 relative">
        {loadData ? (
          <div className="min-h-[400px] flex justify-center items-center">
            <AppLoader />
          </div>
        ) : (
          <>
            <AddAboutStandard from={"edit"} disabled={disableEditStandard} />
            {/* <EditRequirementClassifications disabled={disableEditStandard} /> */}
            {/* <StandardUploadOldFiles disabled={disableEditStandard} /> */}
            <StandardRequirements disabled={disableEditStandard} />
            <StandardProofFiles disabled={disableEditStandard} />
            <StandardOrders disabled={disableEditStandard} />
            <SubmitEditStandard disabled={disableEditStandard} />
          </>
        )}
        {/* <SubmitAddStandard /> */}
      </div>{" "}
    </HeaderWithBreadcrumbLayout>
  );
};

export default EditStandard;
