import {useHistory, useParams} from "react-router";
import {Title} from "../../../../../components";
import SecondaryTapsLayout from "../../../../../layouts/secondaryTapsLayout";
import {serveySettingTabs} from "../data";
import {t} from "i18next";
import {programsIcon} from "../../../../../assets/icons/Index";
import AppRadio from "../../../../../components/form/appRadio";
import AppButton from "../../../../../components/buttons/appButton";
import {useState} from "react";
import {axiosPutRequest} from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import {toast} from "react-toastify";
import AssignStandardToExpertManager from "../../../programs/AssignStandardToExpertManager";

const SurveyExperts = () => {
  const {surveyId} = useParams();
  const [value, setValue] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async () => {
  };

  return (
    <SecondaryTapsLayout
      linksArr={serveySettingTabs(`/surveys/${surveyId}`)}
      breadcrumbs={
        <div className="-mx-5">
          <Title
            iconTitle={programsIcon}
            subTitle={"إسناد الخبراء"}
            thired={true}
            subTitleSeconed={"الخبراء"}
            title={t("المؤشرات")}
            toPage={() => history.push("/indicators")}
            toPageSeconed={() => history.push(`/indicators/${localStorage.getItem("NavIndicatorId") || null}`)}
            withoutButton={true}
          />
        </div>
      }
    >
      <AssignStandardToExpertManager />
     
    </SecondaryTapsLayout>
  );
};

export default SurveyExperts;
