import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { InputLabel } from "../../../../../../../components";
import { axiosGetRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { useAnswerContext } from "../../answerContext";
import ReadOnlyRequirements from "../../components/readOnlyRequirements";
import StandardAnswerRequirement from "../../standardAnswerRequirements";
import MainRequirement from "../../standardAnswerRequirements/ui/mainRequirement";
import AgencyRequirementAnswer from "../Agency/agencyRequirementAnswer";
import { getCommitmentLevelsBasedOnDegree } from "../utils";
import { TextInput } from "flowbite-react";
import UploadFile from "../UploadFile";

const ReviewerRequirementAnswer = ({ setMenuElement }) => {
  const { standardId, entityId } = useParams();
  const { getSelfEvaluation, newReviewerAnswer, setNewReviewerAnswer } =
    useAnswerContext();

  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState("reviewer");

  const { data: commitmentLevels = [] } = useQuery({
    queryKey: ["commitmentLevels", { standardId }],
    queryFn: async ({ queryKey }) => {
      const [, { standardId }] = queryKey;

      const res = await axiosGetRequest(environment.getCommitmentLevels, {
        standardId,
      });

      return res?.result;
    },
  });

  const handleRequirementChange = (requirement, property = "decision") => {
    const updatedRequirements = newReviewerAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((subReq) => {
              return property === "decision"
                ? {
                    ...subReq,
                    decision: requirement?.decision ? 0 : 1,
                    donotApply: 0,
                  }
                : {
                    ...subReq,
                    donotApply: !requirement?.donotApply,
                    decision: !requirement?.donotApply
                      ? 0
                      : requirement?.decision,
                  };
            });

          return property === "decision"
            ? {
                ...requirement,
                subStandardRequirements,
                decision: requirement?.decision ? 0 : 1,
                donotApply: 0,
              }
            : {
                ...requirement,
                subStandardRequirements,
                donotApply: !requirement?.donotApply,
                decision: !requirement?.donotApply ? 0 : requirement?.decision,
              };
        }

        return item;
      }
    );

    getSelfEvaluation(standardId, updatedRequirements, entityId, "reviewer");

    setNewReviewerAnswer({
      ...newReviewerAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const handleSubRequirementPercentageChange = (
    requirement,
    subReqId,
    property = "note",
    value
  ) => {
    const updatedSubRequirements = newReviewerAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  percentage: value,
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewReviewerAnswer({
      ...newReviewerAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSubRequirementNoteChange = (
    requirement,
    subReqId,
    property = "note",
    value
  ) => {
    const updatedSubRequirements = newReviewerAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  note: value,
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewReviewerAnswer({
      ...newReviewerAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSubRequirementRecommendationChange = (
    requirement,
    subReqId,
    property = "note",
    value
  ) => {
    const updatedSubRequirements = newReviewerAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  recommendation: value,
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewReviewerAnswer({
      ...newReviewerAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSubRequirementAttachmentIdChange = (
    requirement,
    subReqId,
    property = "note",
    value
  ) => {
    const updatedSubRequirements = newReviewerAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  attachmentId: value,
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewReviewerAnswer({
      ...newReviewerAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const renderedReviewerRequirements =
    newReviewerAnswer?.standardRequirements?.length > 0 &&
    newReviewerAnswer?.standardRequirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {
              handleRequirementChange(requirement);
            }}
            handleDonotApplyRequirementChange={() =>
              handleRequirementChange(requirement, "donotApply")
            }
          >
            {hasSubRequirements &&
              requirement?.subStandardRequirements?.map((subRequirement) => {
                return (
                  <>
                    <div className="px-5 flex flex-col mb-3 py-2">
                      <div className="border-b pb-2">
                        <h3 className="text-xl pb-4">{subRequirement.title}</h3>
                        <div className=" flex items-center gap-2 mb-4">
                          <span className="w-24">تقييم المدقق</span>
                          <TextInput
                            value={subRequirement?.percentage}
                            type="number"
                            placeholder=""
                            min={0}
                            max={100}
                            onChange={(e) => {
                              handleSubRequirementPercentageChange(
                                requirement,
                                subRequirement?.id,
                                "percentage",
                                e.target.value
                              );
                            }}
                          />
                          <label>
                            {getCommitmentLevelsBasedOnDegree(
                              Number(subRequirement?.percentage),
                              commitmentLevels
                            )}
                          </label>
                        </div>
                        <div className="flex gap-2 w-full items-center mb-4">
                          <span className="w-24"> الملاحظة</span>
                          <TextInput
                            value={subRequirement?.note}
                            className="grow"
                            placeholder="ملاحظة المدقق للجهة"
                            onChange={(e) => {
                              handleSubRequirementNoteChange(
                                requirement,
                                subRequirement?.id,
                                "note",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <div className="flex gap-2 w-full items-center">
                          <span className="w-24">التوصية</span>
                          <TextInput
                            value={subRequirement?.recommendation}
                            className="grow"
                            placeholder="توصية المدقق للجهة"
                            onChange={(e) => {
                              handleSubRequirementRecommendationChange(
                                requirement,
                                subRequirement?.id,
                                "recommendation",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <UploadFile
                          value={subRequirement.attachmentId}
                          onChange={(id) => {
                            handleSubRequirementAttachmentIdChange(
                              requirement,
                              subRequirement?.id,
                              "note",
                              id
                            );
                          }}
                          withOutToolTip
                        />
                      </div>
                    </div>
                  </>
                );
              })}
          </MainRequirement>
        </>
      );
    });

  return (
    <div>
      <StandardAnswerRequirement setMenuElement={setMenuElement}>
        <div className="flex  py-5 w-full">
          <p
            className={
              selectedRequirementsTab == "agency"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("agency")}
          >
            الجهة
          </p>
          <p
            className={
              selectedRequirementsTab == "reviewer"
                ? "bg-indigo-800 text-white px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-emerald-50 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("reviewer")}
          >
            المدقق
          </p>

          <p
            className={
              selectedRequirementsTab == "expert"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("expert")}
          >
            الخبير
          </p>
        </div>

        {selectedRequirementsTab === "agency" && <AgencyRequirementAnswer />}
        {selectedRequirementsTab === "reviewer" && (
          <>{renderedReviewerRequirements}</>
        )}
        {selectedRequirementsTab === "expert" && (
          // <ReadOnlyRequirements
          //   requirements={newReviewerAnswer?.expertRequirementsAnswer}
          // />
          <ReadOnlyRequirementAnswers
            requirements={newReviewerAnswer?.expertRequirementsAnswer}
            commitmentLevels={commitmentLevels}
            label={"تقييم الخبير"}
          />
        )}
      </StandardAnswerRequirement>
    </div>
  );
};

export default ReviewerRequirementAnswer;



function ReadOnlyRequirementAnswers({ requirements, commitmentLevels, label }){
  return requirements?.length > 0 &&
  requirements?.map((requirement) => {
    const hasSubRequirements =
      requirement?.subStandardRequirements?.length > 0;

    return (
      <>
        <MainRequirement
          key={requirement?.id}
          title={requirement?.title}
          hasSubRequirements={hasSubRequirements}
          checked={requirement?.decision}
          requirementDonotApplyChecked={requirement?.donotApply}
          onChange={() => {
          }}
          handleDonotApplyRequirementChange={() =>{}}
        >
          {hasSubRequirements &&
            requirement?.subStandardRequirements?.map((subRequirement) => {
              return (
                <>
                  <div className="px-5 flex flex-col mb-3 py-2">
                    <div className="border-b pb-2">
                      <h3 className="text-xl pb-4">{subRequirement.title}</h3>
                      <div className=" flex items-center gap-2 mb-4">
                        <span className="w-24">{label || "تقييم المدقق"}</span>
                        <TextInput
                          value={subRequirement?.percentage}
                          type="number"
                          placeholder=""
                          min={0}
                          max={100}
                          disabled
                        />
                        <label>
                          {getCommitmentLevelsBasedOnDegree(
                            Number(subRequirement?.percentage),
                            commitmentLevels
                          )}
                        </label>
                      </div>
                      <div className="flex gap-2 w-full items-center mb-4">
                        <span className="w-24"> الملاحظة</span>
                        <TextInput
                          value={subRequirement?.note}
                          className="grow"
                          placeholder="الملاحظة"
                          disabled
                        />
                      </div>
                      <div className="flex gap-2 w-full items-center">
                        <span className="w-24">التوصية</span>
                        <TextInput
                          value={subRequirement?.recommendation}
                          className="grow"
                          placeholder="التوصية"
                          disabled
                        />
                      </div>
                      <UploadFile
                        value={subRequirement.attachmentId}
                        withOutToolTip
                        readOnly
                      />
                    </div>
                  </div>
                </>
              );
            })}
        </MainRequirement>
      </>
    );
  });

}