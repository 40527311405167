import React, { createContext, useState } from "react";
import { axiosGetRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";

const AddStandardContext = createContext();

const defaultValues = {
  interlocutorId: "",
  title: "",
  titleArabic: "",
  royalOrdersAndCabinetDecisions: "",
  answerWay: "",
  code: "",
  target: "",
  standardRequirements: [{
    type: "special",
    title: "specialTextDonTShowIt",
    recommendation: generateRandomString(10),
    subStandardRequirements: [],
  }],
  standardProofs: [],
  agencyStandards: {
    agencies: [],
    commonAgencies: [],
  },
  standardRelations: [],
  pastSurveyStandardAssociations: [],
  allAgencies: true, // should be null able
  questionBankIds: [],
};

function AddStandardProvider(props) {
  let [loading, setLoading] = useState(false);
  let [loadingAgenciesList, setLoadingAgenciesList] = useState(false);
  let [validValues, setValidValues] = useState({
    aboutStandard: false,
    requirmentList: false,
    proofFile: true,
  });

  let [aboutStandard, setAboutStandard] = useState({});

  let [addRequirmentsObj, setAddRequirmentsObj] = useState({
    title: "",
    isExisted: false,
    recommendation: "",
    subStandardRequirements: [],
    agencyStandardRequirements: [],
  });

  let [requirmentList, setRequirmentList] = useState([]);
  let [proofFile, setProofFile] = useState([]);
  let [subRequirementCount, setSubRequirementCount] = useState(0);
  const [mainAgency, setMainAgency] = useState("");
  const [nonReTurnableStandard, setNonReTurnableStandard] = useState();

  let [selectedStandards, setSelectedStandards] = useState([]);
  let [selectedRequirementAgencies, setSelectedRequirementAgencies] = useState(
    []
  );
  let [standardList, setStandardList] = useState([]);
  let [oldStandardList, setOldStandardList] = useState([]);
  let [selectedOldStandardList, setSelectedOldStandardList] = useState("");

  let [selectedCommonAgencies, setSelectedCommonAgencies] = useState([]);
  let [selectedAgencies, setSelectedAgencies] = useState([]);
  let [agencyList, setAgencyList] = useState([]);
  let [classifications, setClassifications] = useState([]);
  

  const [editRequirementClassifications, setEditRequirementClassifications] =
    useState([]);

  let [
    selectedRequirementAgenciesForEdit,
    setSelectedRequirementAgenciesForEdit,
  ] = useState([]);
  // let [allAgenciesOptionsForEdit, setAllAgenciesOptionsForEdit] = useState([]);

  const [addFinalValue, setAddFinalValue1] = useState(defaultValues);
  const [questionsBank, setQuestionsBank] = useState([]);

  function setAddFinalValue(...value){
    console.log({ value112: value })
    setAddFinalValue1(...value)
  }

  const chooseDocType = [
    { id: 1, label: "اجبارية", value: 1 },
    { id: 3, label: "اختيارية", value: 3 },
  ];

  // const fileTypes = [
  //   { id: 2, label: "Pdf", value: 2 },
  //   { id: 3, label: "Word", value: 3 },
  //   { id: 4, label: "Excel", value: 1 },
  //   { id: 5, label: "CSV", value: 4 },
  //   { id: 5, label: "CSV", value: 4 },
  //   { id: 5, label: "CSV", value: 4 },
  //   { id: 5, label: "CSV", value: 4 },
  //   { id: 5, label: "CSV", value: 4 },
  // ];

  
  const fileTypes = [
      {id: 2, label: "Pdf", value: 2, },
      {id: 3, label: "Doc", value: 3, },
      {id: 4, label: "Xls", value: 4, },
      // {id: 5, label: "Csv", value: 5, },
      // {id: 7, label: "Mp4", value: 7, },
      // {id: 8, label: "Avi", value: 8, },
      {id: 9, label: "Ppt", value: 9, },
      {id: 10, label: "Txt", value: 10,},
      {id: 11, label: "Rar", value: 11,},
      {id: 12, label: "Zip", value: 12,},
      {id: 13, label: "Png", value: 13,},
      {id: 14, label: "Gif", value: 14,},
      {id: 15, label: "Jpg", value: 15,},
      {id: 16, label: "Jpeg", value: 16,},
  ]




  const sizeType = [
    { id: 1, label: "للملف", value: 1 },
    { id: 2, label: "لكل الملفات", value: 2 },
  ];

  const getClassifications = async (surveyId) => {
    setLoading(true);
    const res = await axiosGetRequest(environment.getSurveyClassifications, {
      SurveyId: surveyId,
    });
    if (res.success) {
      setClassifications(res?.result);
    }
    setLoading(false);
  };

  const getAllStandards = async (surveyId) => {
    setLoading(true);

    const res = await axiosGetRequest(environment.getStandards, {
      SurveyId: surveyId,
    });
    if (res.success) {
      setStandardList(res?.result?.items);
    }
    setLoading(false);
  };

  const getLastYearStandards = async () => {
    setLoading(true);

    const res = await axiosGetRequest(environment.getQiyas23Standards);
    if (res.success) {
      setOldStandardList(res?.result);
    }
    setLoading(false);
  };

  const getAllAgency = async () => {
    setLoadingAgenciesList(true);

    const res = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination
    );
    if (res.success) {
      setAgencyList(res?.result);
    }
    setLoadingAgenciesList(false);
  };

  return (
    <AddStandardContext.Provider
      value={{
        loading,
        loadingAgenciesList,
        setLoadingAgenciesList,
        aboutStandard,
        setAboutStandard,
        requirmentList,
        setRequirmentList,
        addRequirmentsObj,
        setAddRequirmentsObj,
        subRequirementCount,
        setSubRequirementCount,
        selectedRequirementAgencies,
        setSelectedRequirementAgencies,

        proofFile,
        setProofFile,

        classifications,
        getClassifications,

        agencyList,
        getAllAgency,
        selectedAgencies,
        setSelectedAgencies,
        selectedCommonAgencies,
        setSelectedCommonAgencies,
        mainAgency,
        setMainAgency,
        nonReTurnableStandard,
        setNonReTurnableStandard,

        standardList,
        getAllStandards,
        selectedStandards,
        setSelectedStandards,
        getLastYearStandards,
        oldStandardList,
        setOldStandardList,
        selectedOldStandardList,
        setSelectedOldStandardList,

        editRequirementClassifications,
        setEditRequirementClassifications,

        chooseDocType,
        fileTypes,
        sizeType,

        validValues,
        setValidValues,

        addFinalValue,
        setAddFinalValue,

        selectedRequirementAgenciesForEdit,
        setSelectedRequirementAgenciesForEdit,

        setQuestionsBank,
        questionsBank
      }}
    >
      {props.children}
    </AddStandardContext.Provider>
  );
}

export { AddStandardContext, AddStandardProvider };


function generateRandomString(length) {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
