import { useContext } from "react";
import { AddStandardContext } from "../addStandardContext";
import StandardDimensionItem from "./standardDimensionItem";

const StandardRequirementList = ({
  disabled,
  structureSubStandardRequirementDirectly,
}) => {
  const { addFinalValue, selectedRequirementAgenciesForEdit } =
    useContext(AddStandardContext);

  let standardRequirementsFiltered = addFinalValue.standardRequirements ?? [];

  // if not special // TODO: remove this filter
  if (!structureSubStandardRequirementDirectly) {
    standardRequirementsFiltered = (
      addFinalValue.standardRequirements ?? []
    ).filter(
      (item) =>
        item.title !== "specialTextDonTShowIt" ||
        (item.type && item.type !== "special") || true
    );
  }

  console.log("structureSubStandardRequirementDirectly", structureSubStandardRequirementDirectly);

  const renderedRequirements =
    standardRequirementsFiltered?.length > 0 &&
    standardRequirementsFiltered.map((item, index) => {
      const isTheDefaultItem = item.title === "specialTextDonTShowIt" || item.type === "special";

      if(
        !structureSubStandardRequirementDirectly && isTheDefaultItem
      ){
        return null;
      }
      
      return (
        <StandardDimensionItem
          {...item}
          index={index}
          key={item.title}
          disabled={disabled}
          structureSubStandardRequirementDirectly={structureSubStandardRequirementDirectly}
          selectedRequirementAgenciesForEdit={selectedRequirementAgenciesForEdit}
          number={
            !structureSubStandardRequirementDirectly ? index + 1 : index + 1
          }
        />
      )
    });

  return <div> {renderedRequirements}</div>;
};

export default StandardRequirementList;
