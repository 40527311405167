import { produce } from "immer";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { AppInput } from "../../../../../components";
import { handleInputValidation } from "../../../../../helpers/utils";
import { AddStandardContext } from "./addStandardContext";
import { addStandardValidations } from "./addStandardValidation";

const defaultValues = {
  title: "",
  royalOrdersAndCabinetDecisions: "",
  answerWay: "",
  target: "",
  weight: "",
  standardRelations: "",
  agencyStandards: "",
  commonAgencies: "",
  mainAgency: "",
};

const AddAboutStandard = ({ from, disabled = true }) => {
  const { surveyId } = useParams();
  const [errors, setErrors] = useState(defaultValues);
  const {
    getAllStandards,
    getAllAgency,
    addFinalValue,
    setAddFinalValue,
  } = useContext(AddStandardContext);

  useEffect(() => {
    getAllStandards(surveyId);
    getAllAgency();
  }, []);

  const validateInput = async (name, value) => {
    const { valid, errMsg } = await handleInputValidation(
      addStandardValidations,
      name,
      value
    );

    if (valid) {
      setErrors({ ...errors, [name]: "" });
      // setDisabledBtn(false);
    } else if (errMsg) {
      setErrors({ ...errors, [name]: errMsg });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    validateInput(name, value);
    setAddFinalValue(
      produce((draft) => {
        draft[e.target.name] = e.target.value;
        if (e.target.name == "title") {
          draft.titleArabic = e.target.value;
        }
      })
    );
  };


  return (
    <div>
      <h2 className="font-semibold mb-4">عن المعيار</h2>
      <div className="bg-white p-5 pb-7 rounded-lg">
        <div className="row gap-y-3 mt-3">
          <div className="col-lg-6">
          <AppInput
            variant="gray"
            label="اسم المعيار"
            placeholder="اسم المعيار"
            name="title"
            value={addFinalValue.title}
            onChange={handleInputChange}
            errorMessage={errors.title}
            disabled={disabled}
          />
          </div>
          <div className="col-lg-6">
            <AppInput
              variant="gray"
              label="وصف المعيار"
              placeholder="وصف المعيار"
              name="target"
              value={addFinalValue.target}
              onChange={handleInputChange}
              errorMessage={errors.target}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAboutStandard;
