import {useEffect, useState} from "react";
import {AppInput} from "../../../../../components";
import AppButton from "../../../../../components/buttons/appButton";
import {axiosGetRequest, axiosPutRequest} from "../../../../../services/Request";
import {useParams} from "react-router";
import environment from "../../../../../services/environment";
import Select from "react-tailwindcss-select";
import {toast} from "react-toastify";

const defaultValues = {
  maxAgencyFormsCount: "",
  innovativeFormDegreePercentage: "",
};

const UpdateInnovativeForm = () => {
  const {surveyId} = useParams();
  const [evaluationStage, setEvaluationStage] = useState("");
  const [answerStage, setAnswerStage] = useState("");
  const [inputs, setInputs] = useState(defaultValues);
  const [stages, setStages] = useState([]);

  const getSurveyStages = async () => {
    const res = await axiosGetRequest(environment.getSurveyStagesBySuveyId, {
      surveyId,
    });
    console.log(res);
    if (res?.success) {
      const result = res?.result?.items?.map((item) => ({
        id: item.id,
        label: item.title,
        value: item.id,
      }));
      setStages(result);
    }
  };

  useEffect(() => {
    getSurveyStages();
  }, []);

  const handleChange = (e) => {
    setInputs({...inputs, [e.target.name]: e.target.value});
  };

  const handleChangeAnswerStage = (value) => {
    setAnswerStage(value);
  };
  const handleChangeEvaluationStage = (value) => {
    setEvaluationStage(value);
    console.log(evaluationStage);
  };

  const handleSubmit = async () => {
    const res = await axiosPutRequest(
      environment.UpdateSurveyInnovationFormConfiguration,
      {
        maxAgencyFormsCount: Number(inputs.maxAgencyFormsCount),
        innovativeFormDegreePercentage: Number(
          inputs.innovativeFormDegreePercentage
        ),
        answerStageId: answerStage.id,
        evaluationStageId: evaluationStage.id,
      },
      {surveyId}
    );
    console.log(res);
    if (res.success == true) {
      console.log("res success");
      toast.success("تمت الاضافة");
    }
  };
  return (
    <div className="">
      <div className="bg-white p-5 flex flex-wrap rounded-lg">
        <div className="p-2 w-1/2 mb-4">
          <AppInput
            type="number"
            label="عدد النماذج الإبداعية الأقصى من ناحية الجهة"
            name="maxAgencyFormsCount"
            onChange={handleChange}
            value={inputs.maxAgencyFormsCount}
            variant="gray"
          />
        </div>
        <div className="p-2 w-1/2 mb-4">
          <AppInput
            type="number"
            label="نسبة درجة النموذج في درجة الإستبیان الكلي"
            name="innovativeFormDegreePercentage"
            onChange={handleChange}
            value={inputs.innovativeFormDegreePercentage}
            variant="gray"
          />
        </div>
        <div className="p-2 w-1/2">
          {/* <DropdownMultiSelect
            isSearchable={true}
            value={answerStage}
            handleChange={handleChangeAnswerStage}
            placeholder={t("اختر مرحلة تعبئة النموذج ")}
            label={t(" مرحلة تعبئة النموذج")}
            options={stages}
            isMultiple={false}
          /> */}
          <p className="mb-2">مرحلة تعبئة النموذج</p>
          <Select
            value={answerStage}
            onChange={(val) => setAnswerStage(val)}
            isMultiple={false}
            options={stages}
            name="answerStageId"
            isSearchable={true}
            isClearable={true}
            classNames={{
              list: "h-full overflow-y-scroll border-[#A3AED0]",
              searchBox: "bg-white w-full border-0 rounded-[30px] outline-none",
              tagItem: ({item}) =>
                `break-all flex flex-row items-center p-2 rounded-lg bg-white`,
              control: () => "border border-gray-300 rounded-3xl",
            }}
          />
          {/* <AppInput
            type="number"
            label="مرحلة تعبئة النموذج"
            name="answerStageId"
            onChange={handleChange}
            value={inputs.answerStageId}
            variant="gray"
          /> */}
        </div>
        <div className="p-2 w-1/2">
          <p className="mb-2">مرحلة تقييم النموذج</p>
          <Select
            value={evaluationStage}
            onChange={(val) => setEvaluationStage(val)}
            isMultiple={false}
            options={stages}
            name="evaluationStage"
            isSearchable={true}
            isClearable={true}
            classNames={{
              list: "h-full overflow-y-scroll border-[#A3AED0]",
              searchBox: "bg-white w-full border-0 rounded-[30px] outline-none",
              tagItem: ({item}) =>
                `break-all flex flex-row items-center p-2 rounded-lg bg-white`,
              control: () => "border border-gray-300 rounded-3xl",
            }}
          />
          {/* <DropdownMultiSelect
            isSearchable={true}
            value={evaluationStage}
            handleChange={handleChangeEvaluationStage}
            placeholder={t("اختر مرحلة تقييم النموذج ")}
            label={t(" مرحلة تقييم النموذج")}
            options={stages}
            isMultiple={true}
          /> */}
          {/* <AppInput
            type="number"
            label="مرحلة تقييم النموذج"
            name="evaluationStageId"
            onChange={handleChange}
            value={inputs.evaluationStageId}
            variant="gray"
          /> */}
        </div>
      </div>
      <div className="flex justify-end mt-3">
        <AppButton onClick={handleSubmit}> حفظ </AppButton>
      </div>
    </div>
  );
};

export default UpdateInnovativeForm;
