import {
  ai,
  aiD,
  creativeModels,
  creativeModelsD,
  current,
  currentD,
  details,
  detailsD,
  entities,
  entitiesD,
  faq,
  faqD,
  home,
  homeD,
  inquiries,
  inquiriesD,
  layers,
  layersD,
  logs,
  logsD,
  notifications,
  notificationsD,
  previous,
  previousD,
  role,
  roleD,
  settings,
  settingsD,
  standards,
  standardsD,
  userManagement,
  userManagementD,
  users,
  usersD,
  visits,
  visitsD,
  download,
  downloadD,
  reports,
  reportsD,
  qualityD,
  quality,
} from "../../assets/icons/newIcons";
import { isFeatureHidden } from "../../tenants";

import { permissionsCheck } from "../utils";
// export const List = () => {
//   let arr = []
export const list = () => [
  {
    id: 1,
    name: "الرئيسية",
    icon: home,
    iconD: homeD,
    content: "",
    permission: "Pages.Dashboard",
    subMenus: [],
    url: "/",
  },
  {
    id: 5,
    name: "المؤشرات",
    icon: layers,
    iconD: layersD,
    content: "",
    permission: "Pages.Indicators",
    subMenus: [],
    url: "/indicators",
  },
  {
    id: 7,
    name: "القياس السابق ",
    icon: previous,
    iconD: previousD,
    content: "",
    permission: "Pages.oldQiyas.Menu",
    subMenus: [],
    url: "/previousSurveys",
    omit: isFeatureHidden("PREVIOUS_SURVEYS"),
  },
  {
    id: 13,
    name: "مؤشر التقنيات الناشئة",
    icon: current,
    iconD: currentD,
    permission: "Pages.QiyasTwentyFour",
    content: "",
    subMenus: [
      {
        id: 14,
        name: "المعايير الاساسية",
        icon: standards,
        iconD: standardsD,
        permission: "Pages.SurveyAnswers",
        content: "",
        subMenus: [],
        url: permissionsCheck("Pages.UserType.Admin")
          ? `/programs/${localStorage.getItem("surveyId")}/perspectives`
          : `/programs/${localStorage.getItem("surveyId")}/standards`,
        // `/programs/${localStorage.getItem("surveyId")}/perspectives`,
      },
      {
        id: 14,
        name: "ملاحظات الإستبیان",
        icon: inquiries,
        iconD: inquiriesD,
        permission: "Pages.SurveyAnswers",
        content: "",
        subMenus: [],
        url: "/evaluationNote"
        // `/programs/${localStorage.getItem("surveyId")}/perspectives`,
      },
      // !(
      //   permissionsCheck("Pages.UserType.POC") ||
      //   permissionsCheck("Pages.UserType.CIO") ||
      //   permissionsCheck("Pages.UserType.Delegate")
      // ) && {
      //   id: 6,
      //   name: "النماذج الأبداعية",
      //   icon: creativeModels,
      //   iconD: creativeModelsD,
      //   permission:
      //     JSON.parse(localStorage.getItem("agencyId")) != null
      //       ? "Pages.Templates.Create"
      //       : "Pages.InnovativeFormAgencyAnswerCorrectors",
      //   content: "",
      //   subMenus: [],
      //   url:
      //     JSON.parse(localStorage.getItem("agencyId")) != null
      //       ? "/creativeModels"
      //       : "/creativeCards",
      // },

      // permissionsCheck("Pages.InnovativeFormAgencyAnswers.GetAll") && {
      //   id: 6,
      //   name: "تحميل النماذج الابداعية لقياس 2023",
      //   permission: "Pages.Dashboard",
      //   icon: creativeModels,
      //   iconD: creativeModelsD,
      //   url: "/creativeModelsList",
      //   content: "",
      //   subMenus: [],
      // },
      // (permissionsCheck("Pages.UserType.POC") ||
      //   permissionsCheck("Pages.UserType.CIO") ||
      //   permissionsCheck("Pages.UserType.Delegate")) && {
      //   id: 50,
      //   name: "تحميل التقرير النهائي",
      //   permission: "Pages.Dashboard",
      //   icon: download,
      //   iconD: downloadD,
      //   url: null,
      //   action: () => {
      //     // console.log(123);
      //   },
      //   content: "",
      //   subMenus: [],
      // },
    ],
    url: "/programs",
  },
  {
    id: 17,
    name: "الاجتماعات والزيارات",
    icon: visits,
    iconD: visitsD,
    permission: "Pages.VisitSlots",
    content: "",
    subMenus: [],
    url: "/visitsAndMeetings",
    omit: isFeatureHidden("VISITS_AND_MEETINGS"),
  },
  {
    id: 18,
    name: "الاستفسارات",
    icon: inquiries,
    iconD: inquiriesD,
    permission: "Pages.Tickets",
    content: "",
    subMenus: [],
    url: "/tickets",
    omit: isFeatureHidden("TICKETS"),
  },

  {
    id: 20,
    name: "الاشعارات",
    icon: notifications,
    iconD: notificationsD,
    permission: "Pages.Notics",
    content: "",
    subMenus: [],
    url: "/notifications",
    omit: isFeatureHidden("NOTIFICATIONS"),
  },
  {
    id: 39,
    name: "التقارير",
    icon: reports,
    iconD: reportsD,
    // permission: "Pages.ReportsMenu",
    permission: "Pages.Reports",
    content: "",
    subMenus: [
      {
        id: 40,
        name: "تقرير الجودة",
        icon: quality,
        iconD: qualityD,
        permission: "Pages.ReportsMenu.QualityReport",
        content: "",
        subMenus: [],
        url: "/reports",
      },
      {
        id: 41,
        name: "سجل الاٍجابات",
        icon: quality,
        iconD: qualityD,
        permission: "Pages.ReportsMenu.AnswersRecord",
        content: "",
        subMenus: [],
        url: "/Answers-Report",
      },
    ],
    url: "/reports",
    omit: isFeatureHidden("REPORTS"),
  },
  JSON.parse(localStorage.getItem("agencyId")) != null
    ? {
        id: 35,
        name: "بيانات الجهة",
        icon: details,
        iconD: detailsD,
        permission: "Pages.Agencies.Get",
        content: "",
        subMenus: [],
        url: `/entities/${localStorage.getItem("agencyId")}/details`,
      }
    : {},
  permissionsCheck("Pages.UserType.POC") ||
  permissionsCheck("Pages.UserType.CIO")
    ? {
        id: 36,
        name: "الصلاحيات",
        icon: role,
        iconD: roleD,
        content: "",
        permission: "Pages.Agencies.Standardpermissions",
        subMenus: [],
        url: `/entities/${localStorage.getItem("agencyId")}/permissions`,
      }
    : {},
  {
    id: 19,
    name: "الذكاء الاصطناعي",
    icon: ai,
    iconD: aiD,
    permission: "Pages.Ai.Menu",
    content: "",
    subMenus: [],
    url: "/AI",
    omit: isFeatureHidden("AI"),
  },
  {
    id: 21,
    name: "مستخدمو النظام",
    icon: userManagement,
    iconD: userManagementD,
    permission: "Pages.SystemUsers",
    content: "",
    subMenus: [
      {
        id: 22,
        name: "الجهات الحكومية",
        icon: entities,
        iconD: entitiesD,
        permission: "Pages.Agencies",
        content: "",
        url: "/entities",
      },
      {
        id: 23,
        name: "الادوار",
        icon: role,
        iconD: roleD,
        permission: "Pages.Roles",
        content: "",
        url: "/permissions/1",
      },
      {
        id: 24,
        name: "المستخدمين",
        icon: users,
        iconD: usersD,
        permission: "Pages.Users",
        content: "",
        url: "/users",
      },
    ],
    url: "/users",
  },
  {
    id: 29,
    name: "التقارير",
    // icon: programs,
    // iconD: programsD,
    permission: "",
    content: "",
    subMenus: [],
    url: "/reports",
  },

  {
    id: 30,
    name: "الاسئلة الشائعة",
    icon: faq,
    iconD: faqD,
    permission: "Pages.Faq",
    content: "",
    subMenus: [],
    url: "/faq",
  },

  {
    id: 25,
    name: "الاعدادات",
    icon: settings,
    iconD: settingsD,
    permission: "Pages.Settings",
    content: "",
    subMenus: [
      {
        id: 26,
        name: "سجل الانشطة",
        icon: logs,
        iconD: logsD,
        permission: "Pages.Administration.AuditLogs",
        content: "",
        subMenus: [],
        url: "/activity-logs",
      },
      {
        id: 27,
        name: "اٍعدادات الاستفسارات",
        icon: inquiries,
        iconD: inquiriesD,
        permission: "Pages.TicketsCategory",
        content: "",
        subMenus: [],
        url: "/tickets/TicketSettings",
      },
      {
        id: 28,
        name: "إعدادات الزيارات",
        icon: visits,
        iconD: visitsD,
        permission: "Pages.VisitTeam.GetAll",
        content: "",
        subMenus: [],
        url: "/visit/settings",
      },
      {
        id: 29,
        name: "إعدادات الجهات الحكومية",
        icon: entities,
        iconD: entitiesD,
        permission: "Pages.AgencySectors",
        content: "",
        subMenus: [],
        url: "/visit/AgenciesCategories",
      },
      {
        id: 30,
        name: "اٍعدادات الأسئلة الشائعة",
        icon: faq,
        iconD: faqD,
        permission: "Pages.FaqSettings",
        content: "",
        subMenus: [],
        url: "/FQA-settings",
      },
    ],
    url: "/settings",
    omit: isFeatureHidden("SETTINGS"),
  },
];
