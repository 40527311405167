import { useState } from "react";
import { useGetAnswerHistory } from "./useGetAnswerHistory";
import { isAgency, isExpertManager } from "../../helpers/isPermission";
import DashboardTabs from "../../components/DashboardTabs";
import AppTabs from "../../components/AppTabs";
import AnswersHistory from "./AnswersHistory";
import AppBlueTabs from "../../components/AppBlueTabs";

function AuditsTabs({ standardId, agencyId }) {
  const [activeTab, setActiveTab] = useState(0);

  const { data, isGettingAnswerHistory } = useGetAnswerHistory({
    standardId,
    agencyId,
  });

  const tabs = [
    {
      title: "اٍجابات الجهة",
      content: (
        <AnswersHistory
          answersHistory={data?.result?.agencyAnswersHistory}
          showNote={false}
        />
      ),
    },
    !isAgency() && {
      title: "اٍجابات المدققين",
      content: (
        <AnswersHistory
          userType="reviewer"
          answersHistory={data?.result?.reviewerAnswersHistory}
        />
      ),
    },
    !isAgency() &&
      {
        title: "اٍجابات الخبراء",
        content: (
          <AnswersHistory
            userType="expert"
            answersHistory={data?.result?.expertAnswersHistory}
          />
        ),
      },
    isExpertManager() &&  {
      title: "اٍجابات رؤساء الخبراء",
      content: (
        <AnswersHistory
          userType="expertManager"
          answersHistory={data?.result?.expertManagerAnswersHistory}
        />
      ),
    },
    // !isAgency() && {
    //   title: "اٍجابات ضباط الاتصال الداخليين",
    //   content: (
    //     <AnswersHistory
    //       userType="pocOfficer"
    //       answersHistory={data?.result?.internalPOCOfficerAnswersHistory}
    //     />
    //   ),
    // },
  ].filter(Boolean);

  return (
    <AppBlueTabs
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
}

export default AuditsTabs;
