import { Timeline } from "flowbite-react";
import AppBadge from "../../components/AppBadge";
import { arabicFormatForDateTime } from "../../helpers/utils";
import { isReviewer } from "../../helpers/isPermission";
import SubRequirement from "../../modules/main/features/standerds/standardAnswer/standardAnswerRequirements/ui/subRequirement";
import { checkTheDummyDimensionName } from "../../modules/main/features/standerds/standardAnswer/answers/utils";

function AuditTimeLine({ events, showNote = true, userType }) {
  const theme = {
    root: {
      direction: {
        horizontal: "sm:flex",
        vertical:
          "relative border-r-[4px] border-gray-200 dark:border-gray-700",
      },
    },
    item: {
      root: {
        horizontal: "relative mb-6 sm:mb-0",
        vertical: "mb-5 mr-6",
      },
      content: {
        root: {
          base: "mt-3 sm:pl-8",
        },
        body: {
          base: "mb-4 text-base font-normal text-gray-500 dark:text-gray-400",
        },
        time: {
          base:
            "mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500",
        },
        title: {
          base: "text-lg font-semibold text-gray-900 dark:text-white",
        },
      },
      point: {
        horizontal: "flex items-center",
        line: "hidden h-0.5 w-full bg-gray-200 dark:bg-gray-700 sm:flex",
        marker: {
          base: {
            horizontal:
              "absolute !-right-1.5 h-5 w-5 rounded-full border border-white bg-gray-200",
            vertical:
              "absolute !-right-1.5 mt-1.5 h-5 w-5 rounded-full border border-white bg-gray-200",
          },
          icon: {
            base: "!-h-[1rem] !-w-[1rem] text-cyan-600 dark:text-cyan-300",
            wrapper:
              "absolute !-right-3 flex h-6 w-6 items-center justify-center rounded-full bg-cyan-200 ring-8 ring-white dark:bg-cyan-900 dark:ring-gray-900",
          },
        },
        vertical: "",
      },
    },
  };

  console.log(events, "text-gray-900");

  return (
    <Timeline theme={theme} className="py-5">
      {events?.map((event) => (
        <Timeline.Item key={event.answerDate} className="mb-5">
          <Timeline.Point
            theme={{
              marker: {
                base: {
                  vertical:
                    "absolute border-[5px] border-[#1cc182] top-[32px] right-[-10px] bg-[white] w-[1rem] h-[1rem] rounded-full",
                },
              },
            }}
            className="relative right-0 mt-1.5 text-lg w-5 h-5 "
          />
          <Timeline.Content className="px-7 flex flex-col gap-1">
            <Timeline.Time className="text-lg">
              {event.answerDate}
            </Timeline.Time>
            <Timeline.Title className="text-xl mb-4">
              الاسم : {event.answerCreatorUserName}
            </Timeline.Title>
            <Timeline.Body className="flex flex-col gap-2">
              <div className="flex gap-2">
                <span className="text-lg text-gray-900">الاٍجابة : </span>
                <AppBadge badgeColor={event.answerColor}>
                  {event.answer}
                </AppBadge>
                <div className="w-full">
                  {
                    event.pocRequirementAnswers?.map((requirement) => {
                      return (
                        <>
                          {!checkTheDummyDimensionName(
                            requirement.standardRequirementTitle
                          ) && <h1>{requirement.standardRequirementTitle}</h1>}
                          {requirement.pocSubRequirementAnswerDtos.map(
                            (subRequirement) => {
                              return (
                                <>
                                  <SubRequirement
                                    key={subRequirement?.id}
                                    title={
                                      subRequirement?.standardRequirementTitle
                                    }
                                    checked={subRequirement?.decision}
                                    disableDonotApply={true}
                                    {...subRequirement}
                                    subRequirementDonotApplyChecked={
                                      subRequirement?.donotApply
                                    }
                                  />
                                </>
                              );
                            }
                          )}
                        </>
                      );
                    })
                  }
                </div>
              </div>

              {showNote && (
                <>
                  {(userType == "reviewer" || userType == "pocOfficer") && (
                    <div className="mt-4">
                      <span className="text-lg text-gray-900">
                        ملاحظة المدقق :{" "}
                      </span>
                      <div className="px-3 mt-2">
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              (event?.noteForAgency?.content || "") +
                                (event?.noteForAgency?.template || "") ||
                              "لا يوجد ملاحظات",
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                  {(userType == "expert" || userType == "expertManager") && (
                    <>
                      <div className="mt-4">
                        <span className="text-lg text-gray-900">
                          ملاحظة للجهة :{" "}
                        </span>
                        <div className="px-3 mt-2">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                (event?.noteForAgency?.noteTemplate
                                  ?.templateContent || "") +
                                  (event?.noteForAgency?.unCheckRequirement ||
                                    "") +
                                  (event?.noteForAgency?.content || "") +
                                  (event?.noteForAgency?.template || "") ||
                                "لا يوجد ملاحظات",
                            }}
                          ></div>
                        </div>
                      </div>
                      {/* <div className="mt-4">
                        <span className="text-lg text-gray-900">
                          ملاحظة للمدقق :{" "}
                        </span>
                        <div className="px-3 mt-2">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                (event?.noteForReviewer?.content || "") +
                                  (event?.noteForReviewer?.template || "") ||
                                "لا يوجد ملاحظات",
                            }}
                          ></div>
                        </div>
                      </div> */}
                    </>
                  )}
                  <div className="mt-4">
                    <span className="text-lg text-gray-900">
                      ملاحظة الإجابة :{" "}
                    </span>
                    <div className="px-3 mt-1">
                      <p>{event.answerNote || "لا يوجد ملاحظة"}</p>
                    </div>
                  </div>
                </>
              )}
            </Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
      ))}
    </Timeline>
  );
}

export default AuditTimeLine;
