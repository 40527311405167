import React, { useState, useEffect } from "react";
import notificationIcon from "../../../assets/icons/notification-icon.svg";
import NewHeader from "./../../../layouts/header/NewHeader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { axiosGetRequest, get, post, put } from "../../../services/Request";
import environment from "../../../services/environment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Button,
  CModal,
  DropdownMultiSelect,
  FModal,
  InputLabel,
  SModal,
  Title,
} from "../../../components";
import NewTitle from "../../../components/NewTitle";
import UploadAdapter from "../../../components/UploadAdapter";
import { Field, Formik } from "formik";
import { programsIcon } from "../../../assets/icons/Index";
import { toast } from "react-toastify";
import NewDropdownMultiSelect from "../../../components/NewDropDownMultiSelect";
import HeaderWithBreadcrumbLayout from "../../../layouts/headerWithBreadcrumbLayout";
import AppButton from "../../../components/buttons/appButton";
import { permissionsCheck } from "../../../helpers/utils";

const NewNotification = () => {
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState({});
  const { t } = useTranslation();
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [description, setDescription] = useState("");
  const [type, setType] = useState({});
  const [standard, setStandard] = useState({});
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [standards, setStandards] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [users, setUsers] = useState([]);
  const [types, setTypes] = useState([
    { value: "0", label: t("عام") },
    { value: "1", label: t("تحذير") },
    { value: "2", label: t("معلومة") },
    { value: "3", label: t("رسالة") },
  ]);

  useEffect(() => {
    getAllStandards();
    getAllAgencies();
  }, []);

  // useEffect(() => {
  //   getAllUsers();
  // }, [selectedAgencies]);

  console.log(users, "usersusers");

  const onChangeFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleChangeStandard = (value) => {
    setStandard(value);
  };

  const handleChangeAgencies = (value) => {
    setSelectedAgencies(value);

    getAllUsers(value);
  };

  const handleChangeSelectedUsers = (value) => {
    setSelectedUsers(value);
  };

  const handleChangeType = (value) => {
    setType(value);
  };

  const onFileUpload = (values) => {
    if (!selectedFile) return;
    setSendLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const formData = new FormData();
    formData.append("Name", selectedFile.name);
    formData.append("FileType", "image/*");
    formData.append("EntityType", "");
    formData.append("File", selectedFile);

    post(environment.attachmentFile, formData, config, (res) => {
      setSendLoading(false);
      createNotification(values, res.data.result.id);
    });
  };

  const createNotification = (values, attachmentId) => {
    setSendLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let userIds = [];
    if (selectedUsers && selectedUsers.length > 0) {
      userIds = selectedUsers.map((x) => x.value);
    }
    let agencyIds = [];
    if (selectedAgencies && selectedAgencies.length > 0) {
      agencyIds = selectedAgencies.map((x) => x.value);
    }
    let data = {
      notificationName: values.title,
      type: type.value,
      standardId: standard.value,
      arabicMessage: description,
      attachmentId: attachmentId,
      agencyIds: agencyIds,
      userIds: userIds,
    };
    post(environment.createNotification, data, config, (res) => {
      setSendLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        toast.success(t("تم الإرسال بنجاح"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOpenModal(true);
        setTimeout(() => {
          backToList();
        }, 500);
      }
    });
  };

  const backToList = () => {
    history.push("/notifications");
  };

  const getAllStandards = async () => {
    setLoading(true);
    const res = await axiosGetRequest(environment.getStandards, {
      SurveyId:
        localStorage.getItem("surveyId") != "null"
          ? localStorage.getItem("surveyId")
          : null,
    });
    // console.log(res, "STANDARDS");
    if (res?.success) {
      const result = res?.result?.items?.map((item) => ({
        value: item.id,
        label: item.title,
        isSelected: false,
      }));
      setStandards(result);
    }
    setLoading(false);
  };

  const getAllAgencies = async () => {
    const res = await axiosGetRequest(environment.getAllAgency, {
      MaxResultCount: 6000,
    });

    if (res?.success) {
      const result = res?.result?.items
        ?.filter((item) => item?.nameInArabic)
        .map((item) => ({
          value: item.id,
          agencyId: item.id,
          label: item.nameInArabic?.toString() || "",
          isSelected: false,
        }));
      setAgencies(result);
    }
  };

  const getAllUsers = async (agencies) => {
    setLoading(true);
    let query = "";
    if (agencies && agencies?.length > 0) {
      query = "?" + agencies?.map((ele) => `AgencyIds=${ele.value}`).join("&");
      const res = await axiosGetRequest(environment.getAllUsers + query);

      if (res?.success) {
        const result = res?.result?.items
          // ?.filter((item) => item?.nameInArabic)
          .map((item) => ({
            value: item.id,
            userId: item.id,
            label: item.name + " " + item.surname || "",
            isSelected: false,
          }));

        setUsers(result);
      }
    } else {
      setUsers([]);
    }

    setLoading(false);
  };

  const newNotificationBreadcumbs = [
    {
      icon: notificationIcon,
      title: "الاشعارات",
      url: "/notifications",
    },
    {
      title: "اٍشعار جديد",
    },
  ];

  return (
    <HeaderWithBreadcrumbLayout breadcrumbArr={newNotificationBreadcumbs}>
      <h2 className="text-lg font-bold mb-5 px-5">إنشاء اشعار جديد</h2>
      <Formik
        initialValues={{ title: "", type: {}, standard: {} }}
        validate={(values) => {
          const errors = {};
          if (!values.title) {
            errors.title = t("عنوان الاشعار مطلوب");
          }
          if (!type || !type.value) {
            errors.type = t("نوع الاشعار مطلوب");
          }
          // if (!standard || !standard.value) {
          //   errors.standard = t("المعيار مطلوب");
          // }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (selectedFile && selectedFile.name) {
            onFileUpload(values);
          } else {
            createNotification(values, undefined);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className="pb-5 px-5">
            <div className="row">
              <div className="col-lg-6">
                <InputLabel
                  label={t("عنوان الاشعار")}
                  type={"text"}
                  value={values.title}
                  onChange={handleChange}
                  style={{
                    borderColor: errors.title
                      ? "red"
                      : values.title != ""
                      ? "#22bb33"
                      : "",
                  }}
                  name="title"
                />
                <div className="text-sm text-red-600 mt-3">
                  {errors.title && touched.title && errors.title}
                </div>
              </div>
              <div className="col-lg-6">
                <DropdownMultiSelect
                  withoutLabel={false}
                  isSearchable={true}
                  value={type}
                  handleChange={handleChangeType}
                  placeholder={t("نوع الاشعار")}
                  label={t("نوع الاشعار")}
                  options={types}
                  isMultiple={false}
                  name="type"
                  className="w-[881px]"
                  style={{ width: "881px" }}
                />
                <div className="text-sm text-red-600 mt-3">
                  {errors.type && touched.type && errors.type}
                </div>
              </div>
              {/* <div className="col-lg-6"></div> */}
              <div className="col-lg-6">
                <DropdownMultiSelect
                  withoutLabel={false}
                  isSearchable={true}
                  value={standard}
                  handleChange={handleChangeStandard}
                  placeholder={t("modules.main.tickets.standard")}
                  label={t("modules.main.tickets.standard")}
                  options={standards}
                  isMultiple={false}
                  name="standard"
                  className="w-[881px]"
                  style={{ width: "881px" }}
                />
                <div className="text-sm text-red-600 mt-3">
                  {errors.standard && touched.standard && errors.standard}
                </div>
              </div>
              <div className="col-lg-6">
                <NewDropdownMultiSelect
                  isClearable={true}
                  withoutLabel={false}
                  isSearchable={true}
                  value={selectedAgencies || []}
                  handleChange={handleChangeAgencies}
                  placeholder="الجهات الحكومية"
                  label={"الجهات الحكومية"}
                  options={agencies || []}
                  className="relative z-30"
                  isMultiple={true}
                  // errorMessage={errors.agencyStandards}
                />
                {/* <NewDropdownMultiSelect
                  withoutLabel={false}
                  isSearchable={false}
                  value={selectedAgencies}
                  handleChange={handleChangeAgencies}
                  placeholder={t("الجهات")}
                  label={t("الجهات")}
                  options={agencies}
                  isMultiple={true}
                  name="agencies"
                  className="w-[881px]"
                  style={{ width: "881px" }}
                /> */}
              </div>
              {/* {selectedAgencies?.length == 0 ? null : (
                <div className="col-lg-6">
                  <NewDropdownMultiSelect
                    className="w-[full] border-none  text-violet-950 text-opacity-30 text-[15px] h-[50px] bg-white rounded-[100px] p-3"
                    withoutLabel={false}
                    isSearchable={true}
                    value={selectedUsers}
                    handleChange={handleChangeSelectedUsers}
                    placeholder={t("sideMenu.users")}
                    label={t("sideMenu.users")}
                    options={users}
                    isMultiple={true}
                    name="users"
                  />
                </div>
              )} */}
            </div>
            <div className="z-10 " style={{ position: "relative", zIndex: 0 }}>
              <div className="flex flex-col justify-between">
                <div className="flex flex-col justify-between my-3">
                  <label className="text-violet-950 mb-2">الرسالة</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onReady={(editor) => {
                      setDescription(editor.getData());
                      editor.plugins.get("FileRepository").createUploadAdapter =
                        function (loader) {
                          return new UploadAdapter(loader);
                        };
                    }}
                    config={{
                      language: "ar",

                      simpleUpload: {
                        uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                        withCredentials: true,
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "token"
                          )}`,
                        },
                      },
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                      editor.plugins.get("FileRepository").createUploadAdapter =
                        function (loader) {
                          return new UploadAdapter(loader);
                        };
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                </div>
                <div className="flex flex-col justify-between my-3">
                  <InputLabel
                    style={{ display: "none" }}
                    type={"file"}
                    id={"fileUpload"}
                    isImage={false}
                    multiple={false}
                    onChange={onChangeFile}
                    label={"إرفاق ملف"}
                    isRequired={false}
                  />

                  {selectedFile && selectedFile.name ? (
                    <div>
                      <p>تم اٍرفاق ملف {selectedFile.name}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-end gap-5 items-center my-5">
              {permissionsCheck("Pages.Notics.Create") ? (
                <AppButton type="submit" loading={sendLoading}>
                  اٍرسال
                </AppButton>
              ) : null}

              <button
                className="w-[99px] h-[52px] bg-red-700 rounded-[50px] text-white"
                onClick={() => backToList()}
              >
                {t("الغاء")}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </HeaderWithBreadcrumbLayout>
  );
};

export default NewNotification;
