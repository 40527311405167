import { errorToast, successToast } from "../../../../../../../helpers/toast";
import { axiosPostRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { useAnswerContext } from "../../answerContext";
import { useHistory } from "react-router";
import usePrevExpertAnswer from "../useGetPrevAnswer/usePrevExpertAnswer";

const useCreateExpertAnswer = () => {
  const history = useHistory();
  const { getPrevExpertAnswer } = usePrevExpertAnswer();
  const { newExpertAnswer, setCreateAnswerLoading, expertDegree } =
    useAnswerContext();

  const createExpertAnswer = async (standardId, entityId, currentStage) => {
    const expertRequirementAnswers = newExpertAnswer?.standardRequirements?.map(
      (req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            subStandardRequirementId: subReq?.id,
            decision: subReq?.decision,
            note: subReq?.note || "",
            // recommendation: req?.subReq || "",
            recommendation: subReq?.recommendation || "",
            donotApply: subReq?.donotApply,
            percentage: subReq?.percentage,
            attachmentId: subReq?.attachmentId,
            expertSubRequirementAnswerSides: subReq?.sides.map( item => {
              return {
                subStandardRequirementSideId: item?.id,
                evaluationDegree: parseFloat(item?.evaluationDegree),
                answer: item?.answer || "",
                attachmentId: item?.attachmentId || null,
                evaluationNote: item?.evaluationNote || "",
                recommendation: item?.recommendation || "",
              }
            }),
          })
        );

        return {
          standardRequirementId: req?.id,
          decision: req?.decision,
          note: req?.note || "",
          recommendation: req?.note || "",
          donotApply: req?.donotApply,
          percentage: req?.percentage,
          expertSubRequirementAnswers: subStandardRequirements,
        };
      }
    );

    // expert note for sub requirement should be between 0 and 100
    const allSubRequirmenets = expertRequirementAnswers.map(item => item.expertSubRequirementAnswers).flat()
    const subRequirementNotes = allSubRequirmenets.map(item => item.percentage)
    const isValidPercentage = subRequirementNotes.every(percentage => !percentage || (+percentage >= 0 && +percentage <= 100)) 

    if (!isValidPercentage) {
      // the evaluation of the expert  for the questions should be within 0 and 100
      errorToast("التقييم الخاص بالخبير للأسئلة يجب أن يكون بين 0 و 100")
      return
    }

    const surveyExpertAnswerStandardProofs =
      newExpertAnswer?.standardProofs
        ?.filter((proof) => proof?.expertProofNotes)
        .map((proof) => {
          console.log(
            proof,
            "dsl;kfjkals;djfiewurpoiuwerkljdzkjnljkgsjldkfgsfjdklhjklh"
          );
          return {
            standardProofId: proof?.standardProofId || null,
            expertProofNotes: proof?.expertProofNotes || "",
          };
        }) || [];

    // validation
    // if (newExpertAnswer?.checkedExpertDegree?.value == null) {
    //   return errorToast("لابد من وجود اجابة للتدقيق");
    // }

    // newExpertAnswer?.selectedTemplatesForReviewer +
    const data = {
      standardId: Number(standardId),
      surveyStageId: Number(currentStage),
      expertRequirementAnswers,
      surveyExpertAnswerStandardProofs,

      noteForAgency: {
        unCheckRequirement:
          newExpertAnswer?.newRequirementsAgency == undefined
            ? ""
            : `<ul style="padding : 6px 0 ">` +
              newExpertAnswer?.newRequirementsAgency +
              "</ul>",
        template: "",
        content: newExpertAnswer?.expertSelectedTemplateForAgency,
        noteTemplateId: newExpertAnswer?.selectedTemplatesForAgency,
      },
      noteForReviewer: {
        content: "<ul>" + newExpertAnswer?.newRequirementsReviewer + "</ul>",
        template: newExpertAnswer?.expertSelectedTemplateForReviewer,
      },

      expertId: Number(localStorage.getItem("userId")),
      agencyId: Number(entityId),
      finalDegree: newExpertAnswer?.checkedExpertDegree?.fixedDegree,
      donotApply: newExpertAnswer?.donotApply,
      finalCommitmentLevelId: newExpertAnswer?.checkedExpertDegree?.value,
      selfEvaluationCommitmentLevelId:
        expertDegree?.expertSelfEvaluationCommitmentLevel?.id || null,
      selfEvaluationDegree: expertDegree?.expertSelfEvaluationDegree || 0,

      expertAnswerNote:
        expertDegree?.expertSelfEvaluationCommitmentLevel?.title !==
        newExpertAnswer?.checkedExpertDegree?.title
          ? newExpertAnswer?.expertAnswerNote
          : null,

      needVisit: newExpertAnswer?.needVisit,
      visitNote: newExpertAnswer?.visitNote,
    };

    setCreateAnswerLoading(true);
    const res = await axiosPostRequest(environment.addExpertAnswer, data);
    if (res?.success) {
      successToast("تم اضافة الاجاية بنجاح");
      getPrevExpertAnswer(
        Number(standardId),
        Number(entityId),
        Number(currentStage)
      );
    }
    setCreateAnswerLoading(false);
  };
  return { createExpertAnswer };
};

export default useCreateExpertAnswer;
