import React, {useContext, useState} from "react";
import UploadAdapter from "../../../../../../components/UploadAdapter";
import {StandardProgramContext} from "../../../../../../contexts/StandardProgramContext";
import {DropdownMultiSelect} from "../../../../../../components";
import {t} from "i18next";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ExpertAnswerNotes = ({templateList}) => {
  const standardProgramContext = useContext(StandardProgramContext);
  const [selectedTemplates, setSelectedTemplates] = useState("");
  const [
    selectedTemplatesForReviewer,
    setSelectedTemplatesForReviewer,
  ] = useState("");

  function extractTextFromHtml(htmlString) {
    // Remove HTML tags
    var text = htmlString.replace(/<[^>]*>/g, "");
    // Remove backslashes
    text = text.replace(/\\/g, "");
    // Return the extracted text
    return text;
  }

  const templateListOptions =
    templateList?.result?.items &&
    templateList?.result?.items?.map((item) => {
      return {
        // label: item?.id?.toString(),
        label: extractTextFromHtml(item.templateContent) || "",
        value: item.id,
      };
    });
  const handleChangeTemplates = (value) => {
    setSelectedTemplates(value || "");
  };
  const handleChangeTemplatesForReviewer = (value) => {
    setSelectedTemplatesForReviewer(value || "");
  };

  return (
    <>
      <div className="w-[87%] mb-3">
        <h2 className="text-[#292069] text-lg font-bold mb-4">
          ملاحظات المدقق:
        </h2>
        <div
          className="text=[#4c566b]"
          dangerouslySetInnerHTML={{
            __html:
              standardProgramContext?.noteReviewer == null
                ? "<p>لا يوجد ملاحظات للمدقق</p>"
                : standardProgramContext?.noteReviewer,
          }}
        ></div>
        <div className="text=[#7D8BAB] text-[14px]">
          {standardProgramContext?.standard.programExpertAnswer == null
            ? null
            : standardProgramContext?.standard.reviewerNoteDate}
        </div>
      </div>
      <div className="w-[87%] mb-2 mt-8">
        <h2 className="text-[#292069] text-lg font-bold mb-4">
          ملاحظات الي المدقق:
        </h2>

        {/* <div className="pb-5">
          <DropdownMultiSelect
            isSearchable={true}
            value={selectedTemplatesForReviewer}
            handleChange={handleChangeTemplatesForReviewer}
            placeholder={t("اختر من القوالب")}
            label={t("قوالب الملاحظات")}
            options={templateListOptions}
            isMultiple={false}
          />
        </div> */}

        <CKEditor
          editor={ClassicEditor}
          data={
            selectedTemplatesForReviewer?.label ||
            standardProgramContext?.noteExpertForReviewer
          }
          onReady={(editor) => {
            standardProgramContext?.setNoteExpertForReviewer(editor.getData());
            // setContent(editor.getData());
            editor.plugins.get("FileRepository").createUploadAdapter = function (
              loader
            ) {
              return new UploadAdapter(loader);
            };
          }}
          config={{
            language: "ar",
            simpleUpload: {
              uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
              withCredentials: true,

              headers: {
                // 'X-CSRF-TOKEN': 'CSRF-Token',
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            standardProgramContext?.setNoteExpertForReviewer(data);
            // setContent(data);
            editor.plugins.get("FileRepository").createUploadAdapter = function (
              loader
            ) {
              return new UploadAdapter(loader);
            };
          }}
        />
        <div className="text=[#7D8BAB] text-[14px]">
          {standardProgramContext?.standard.programExpertAnswer == null
            ? null
            : standardProgramContext?.standard.expertToReviewerNoteDate}
        </div>
      </div>
      <div className="w-[87%] mt-7">
        <h2 className="text-[#292069] text-lg font-bold mb-4">
          ملاحظة الي الجهة:
        </h2>
        {/* <div className="pb-5">
          <DropdownMultiSelect
            isSearchable={true}
            value={selectedTemplates}
            handleChange={handleChangeTemplates}
            placeholder={t("اختر من القوالب")}
            label={t("قوالب الملاحظات")}
            options={templateListOptions}
            isMultiple={false}
          />
        </div> */}
        <CKEditor
          editor={ClassicEditor}
          data={selectedTemplates?.label || standardProgramContext?.noteExpert}
          onReady={(editor) => {
            standardProgramContext?.setNoteExpert(editor.getData());
            // setContent(editor.getData());
            editor.plugins.get("FileRepository").createUploadAdapter = function (
              loader
            ) {
              return new UploadAdapter(loader);
            };
          }}
          config={{
            language: "ar",
            simpleUpload: {
              uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
              withCredentials: true,
              headers: {
                // 'X-CSRF-TOKEN': 'CSRF-Token',
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            standardProgramContext?.setNoteExpert(data);
            // setContent(data);
            editor.plugins.get("FileRepository").createUploadAdapter = function (
              loader
            ) {
              return new UploadAdapter(loader);
            };
          }}
        />
        <div className="text=[#7D8BAB] text-[14px]">
          {standardProgramContext?.standard.programExpertAnswer == null
            ? null
            : standardProgramContext?.standard.agencyNoteDate}
        </div>
      </div>
    </>
  );
};

export default ExpertAnswerNotes;
