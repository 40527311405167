import { useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import cloud from "../../../../../../../assets/icons/cloud.svg";
import pdf from "../../../../../../../assets/icons/pdf.svg";
import { InputLabel } from "../../../../../../../components";
import { axiosGetRequest, post } from "../../../../../../../services/Request";
import { useAnswerContext } from "../../answerContext";
import environment from "../../../../../../../services/environment";
import ReadOnlyRequirements from "../../components/readOnlyRequirements";
import StandardAnswerRequirement from "../../standardAnswerRequirements";
import MainRequirement from "../../standardAnswerRequirements/ui/mainRequirement";
import AgencyRequirementAnswer from "../Agency/agencyRequirementAnswer";
import { getCommitmentLevelsBasedOnDegree } from "../utils";
import { Spinner, TextInput } from "flowbite-react";
import { useTranslation } from "react-i18next";
import FilePreview from "../../../../../../../components/upload/filePreview";
import UploadFile from "../UploadFile";

const ExpertRequirementAnswer = ({ setMenuElement }) => {
  const { standardId, entityId } = useParams();
  const { getSelfEvaluation, newExpertAnswer, setNewExpertAnswer } =
    useAnswerContext();
  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState("expert");

  const { data: commitmentLevels = [] } = useQuery({
    queryKey: ["commitmentLevels", { standardId }],
    queryFn: async ({ queryKey }) => {
      const [, { standardId }] = queryKey;

      const res = await axiosGetRequest(environment.getCommitmentLevels, {
        standardId,
      });

      return res?.result;
    },
  });

  const handleRequirementChange = (requirement, property = "decision") => {
    const updatedRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((subReq) => {
              return property === "decision"
                ? {
                    ...subReq,
                    decision: requirement?.decision ? 0 : 1,
                    donotApply: 0,
                  }
                : {
                    ...subReq,
                    donotApply: !requirement?.donotApply,
                    decision: !requirement?.donotApply
                      ? 0
                      : requirement?.decision,
                  };
            });

          return property === "decision"
            ? {
                ...requirement,
                subStandardRequirements,
                decision: requirement?.decision ? 0 : 1,
                donotApply: 0,
              }
            : {
                ...requirement,
                subStandardRequirements,
                donotApply: !requirement?.donotApply,
                decision: !requirement?.donotApply ? 0 : requirement?.decision,
              };
        }

        return item;
      }
    );

    getSelfEvaluation(standardId, updatedRequirements, entityId);

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const handleSubRequirementPercentageChange = (
    requirement,
    subReqId,
    property = "note",
    value
  ) => {
    const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  percentage: value,
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSubRequirementNoteChange = (
    requirement,
    subReqId,
    property = "note",
    value
  ) => {
    const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  note: value,
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSubRequirementRecommendationChange = (
    requirement,
    subReqId,
    property = "note",
    value
  ) => {
    const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  recommendation: value,
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSubRequirementAttachmentIdChange = (
    requirement,
    subReqId,
    property = "note",
    value
  ) => {
    const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  attachmentId: value,
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const renderedExpertRequirements =
    newExpertAnswer?.standardRequirements?.length > 0 &&
    newExpertAnswer?.standardRequirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {
              handleRequirementChange(requirement);
            }}
            handleDonotApplyRequirementChange={() =>
              handleRequirementChange(requirement, "donotApply")
            }
          >
            {hasSubRequirements &&
              requirement?.subStandardRequirements?.map((subRequirement) => {
                return (
                  <>
                    <div className="px-5 flex flex-col mb-3 py-2">
                      <div className="border-b pb-2">
                        <h3 className="text-xl pb-4">{subRequirement.title}</h3>
                        <div className="flex items-center gap-2 mb-4">
                          <span className="w-24">تقييم الخبير</span>
                          <TextInput
                            value={subRequirement?.percentage}
                            type="number"
                            placeholder=""
                            min={0}
                            max={100}
                            onChange={(e) => {
                              handleSubRequirementPercentageChange(
                                requirement,
                                subRequirement?.id,
                                "percentage",
                                e.target.value
                              );
                            }}
                          />
                          <label>
                            {getCommitmentLevelsBasedOnDegree(
                              Number(subRequirement?.percentage),
                              commitmentLevels
                            )}
                          </label>
                        </div>
                        <div className="flex gap-2 w-full items-center mb-4">
                          <span className="w-24"> الملاحظة</span>
                          <TextInput
                            value={subRequirement?.note}
                            className="grow"
                            placeholder="ملاحظة الخبير للجهة"
                            onChange={(e) => {
                              handleSubRequirementNoteChange(
                                requirement,
                                subRequirement?.id,
                                "note",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <div className="flex gap-2 w-full items-center">
                          <span className="w-24">التوصية</span>
                          <TextInput
                            value={subRequirement?.recommendation}
                            className="grow"
                            placeholder="توصية الخبير للجهة"
                            onChange={(e) => {
                              handleSubRequirementRecommendationChange(
                                requirement,
                                subRequirement?.id,
                                "recommendation",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <UploadFile
                          value={subRequirement.attachmentId}
                          onChange={(id) => {
                            handleSubRequirementAttachmentIdChange(
                              requirement,
                              subRequirement?.id,
                              "note",
                              id
                            );
                          }}
                          withOutToolTip
                        />
                      </div>
                    </div>
                  </>
                );
              })}
          </MainRequirement>
        </>
      );
    });

  return (
    <div>
      <StandardAnswerRequirement setMenuElement={setMenuElement}>
        <div className="flex  py-5 w-full">
          <p
            className={
              selectedRequirementsTab == "agency"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("agency")}
          >
            الجهة
          </p>
          <p
            className={
              selectedRequirementsTab == "reviewer"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("reviewer")}
          >
            المدقق
          </p>

          <p
            className={
              selectedRequirementsTab == "expert"
                ? "bg-indigo-800 text-white px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-emerald-50 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("expert")}
          >
            الخبير
          </p>
        </div>

        {selectedRequirementsTab === "agency" && <AgencyRequirementAnswer />}
        {selectedRequirementsTab === "reviewer" && (
          // <ReadOnlyRequirements
          //   requirements={newExpertAnswer?.reviewerRequirementsAnswer}
          // />
          <ReadOnlyRequirementAnswers
            requirements={newExpertAnswer?.reviewerRequirementsAnswer}
            commitmentLevels={commitmentLevels}
          />
        )}
        {selectedRequirementsTab === "expert" && (
          <>{renderedExpertRequirements}</>
        )}
      </StandardAnswerRequirement>
    </div>
  );
};

export default ExpertRequirementAnswer;

function ReadOnlyRequirementAnswers({ requirements, commitmentLevels }) {

  const hasAnswer = requirements?.some(requirement => 
    requirement?.subStandardRequirements?.some(item => 
      item?.reviewerSubRequirementAnswerSides
    )
  );
  

  return !hasAnswer ? (
    <h2 className="text-2xl pb-2">لا يوجد إجابة حتى الآن</h2>
  ) : (
    requirements?.length > 0 &&
      requirements?.map((requirement) => {
        const hasSubRequirements =
          requirement?.subStandardRequirements?.length > 0;

        return (
          <>
            <MainRequirement
              key={requirement?.id}
              title={
                requirement?.title || requirement?.standardRequirementTitle
              }
              hasSubRequirements={hasSubRequirements}
              checked={requirement?.decision}
              requirementDonotApplyChecked={requirement?.donotApply}
              onChange={() => {}}
              handleDonotApplyRequirementChange={() => {}}
            >
              {hasSubRequirements &&
                hasAnswer &&
                requirement?.subStandardRequirements?.map((subRequirement) => {
                  return (
                    <>
                      <div className="px-5 flex flex-col mb-3 py-2">
                        <h2 className="text-2xl pb-2">
                          {subRequirement.title}
                        </h2>
                        <div className="flex flex-col gap-4">
                          <>
                            {subRequirement?.subRequirementAnswerSides?.map(
                              (side, index) => {
                                return (
                                  <div className="border-b pb-2">
                                    <h3 className="text-xl pb-3">
                                      {side.title}
                                    </h3>
                                    <div className=" flex items-center gap-2 mb-4">
                                      <span className="w-24">تقييم المدقق</span>
                                      <TextInput
                                        value={side?.evaluationDegree}
                                        type="number"
                                        placeholder=""
                                        min={0}
                                        max={100}
                                        disabled
                                      />
                                      <label>
                                        {getCommitmentLevelsBasedOnDegree(
                                          Number(
                                            subRequirement
                                              ?.expertSubRequirementAnswerSides?.[
                                              index
                                            ]?.evaluationDegree
                                          ),
                                          commitmentLevels
                                        )}
                                      </label>
                                    </div>
                                    <div className="flex gap-2 w-full items-center mb-4">
                                      <span className="w-24"> الملاحظة</span>
                                      <TextInput
                                        value={side?.evaluationNote}
                                        className="grow"
                                        placeholder="ملاحظة المدقق للجهة"
                                        disabled
                                      />
                                    </div>
                                    <div className="flex gap-2 w-full items-center">
                                      <span className="w-24">التوصية</span>
                                      <TextInput
                                        value={side?.recommendation}
                                        className="grow"
                                        placeholder="توصية المدقق للجهة"
                                        disabled
                                      />
                                    </div>
                                    <UploadFile
                                      value={side.attachmentId}
                                      withOutToolTip
                                      readOnly
                                      label="يمكنك إرفاق ملف ولا يزيد حجم الملف عن 30 ميجا"
                                    />
                                  </div>
                                );
                              }
                            )}
                          </>
                        </div>
                      </div>
                    </>
                  );
                })}
            </MainRequirement>
          </>
        );
      })
  );
}
