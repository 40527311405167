import React, { useEffect, useState } from "react";
import StandardAnswerRequirement from "../../standardAnswerRequirements";
import MainRequirement from "../../standardAnswerRequirements/ui/mainRequirement";
import SubRequirement from "../../standardAnswerRequirements/ui/subRequirement";
import { useAnswerContext } from "../../answerContext";
import { useParams } from "react-router-dom";
import AppTextarea from "../../../../../../../components/form/appTextarea";
import AgencyRequirementAnswer from "../Agency/agencyRequirementAnswer";
import ReadOnlyRequirements from "../../components/readOnlyRequirements";
import {
  isAdminFunc,
  isExpertManager,
  isInquiryManager,
} from "../../../../../../../helpers/isPermission";
import { getCommitmentLevelsBasedOnDegree } from "../utils";
import { InputLabel } from "../../../../../../../components";
import { useQuery } from "@tanstack/react-query";
import environment from "../../../../../../../services/environment";
import { axiosGetRequest } from "../../../../../../../services/Request";
import { TextInput } from "flowbite-react";
import UploadFile from "../UploadFile";

const ExpertManagerRequirementAnswer = ({ setMenuElement }) => {
  const { standardId, entityId } = useParams();
  const {
    getSelfEvaluation,
    newExpertManagerAnswer,
    setNewExpertManagerAnswer,
  } = useAnswerContext();

  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState("expertManager");

  const { data: commitmentLevels = [] } = useQuery({
    queryKey: ["commitmentLevels", { standardId }],
    queryFn: async ({ queryKey }) => {
      const [, { standardId }] = queryKey;

      const res = await axiosGetRequest(environment.getCommitmentLevels, {
        standardId,
      });

      return res?.result;
    },
  })

  const handleRequirementChange = (requirement, property = "decision") => {
    const updatedRequirements =
      newExpertManagerAnswer?.standardRequirements?.map((item) => {
        if (item?.id === requirement?.id) {
          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((subReq) => {
              return property === "decision"
                ? {
                    ...subReq,
                    decision: requirement?.decision ? 0 : 1,
                    donotApply: 0,
                  }
                : {
                    ...subReq,
                    donotApply: !requirement?.donotApply,
                    decision: !requirement?.donotApply
                      ? 0
                      : requirement?.decision,
                  };
            });

          return property === "decision"
            ? {
                ...requirement,
                subStandardRequirements,
                decision: requirement?.decision ? 0 : 1,
                donotApply: 0,
              }
            : {
                ...requirement,
                subStandardRequirements,
                donotApply: !requirement?.donotApply,
                decision: !requirement?.donotApply ? 0 : requirement?.decision,
              };
        }

        return item;
      });

    getSelfEvaluation(
      standardId,
      updatedRequirements,
      entityId,
      "expertManager"
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const handleSubRequirementChange = (
    requirement,
    subReqId,
    property = "decision"
  ) => {
    const updatedSubRequirements =
      newExpertManagerAnswer?.standardRequirements?.map((item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return property === "decision"
                  ? {
                      ...subRequire,
                      decision: subRequire?.decision ? 0 : 1,
                      donotApply: 0,
                    }
                  : {
                      ...subRequire,
                      donotApply: !subRequire?.donotApply,
                      decision: !subRequire?.donotApply
                        ? 0
                        : subRequire?.decision,
                    };
              }

              return subRequire;
            }
          );

          const isAllSubReqChecked = newSubReqs?.every(
            (value) => value?.decision == 1
          );
          const isAllSubReqDonotApply = newSubReqs?.every(
            (value) => value?.donotApply === true
          );

          console.log(isAllSubReqChecked);

          if (isAllSubReqChecked) requirement.decision = 1;
          else requirement.decision = 0;
          if (isAllSubReqDonotApply) requirement.donotApply = true;
          else requirement.donotApply = false;

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      });
    getSelfEvaluation(
      standardId,
      updatedSubRequirements,
      entityId,
      "expertManager"
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSelectAllReqs = (action = "selectAll") => {
    const updatedRequirements =
      newExpertManagerAnswer?.standardRequirements?.map((req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            ...subReq,
            donotApply: 0,
            decision: action === "selectAll" ? 1 : 0,
          })
        );

        return {
          ...req,
          subStandardRequirements,
          donotApply: 0,
          decision: action === "selectAll" ? 1 : 0,
        };
      });

    getSelfEvaluation(
      standardId,
      updatedRequirements,
      entityId,
      "expertManager"
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const handleSubRequirementPercentageChange = (
    requirement,
    subReqId,
    property = "note",
    value
  ) => {
    const updatedSubRequirements = newExpertManagerAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                      ...subRequire,
                      percentage: value,
                    }
                  
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };


  
  const handleSubRequirementNoteChange = (
    requirement,
    subReqId,
    property = "note",
    value
  ) => {
    const updatedSubRequirements = newExpertManagerAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  note: value,
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSubRequirementRecommendationChange = (
    requirement,
    subReqId,
    property = "note",
    value
  ) => {
    const updatedSubRequirements = newExpertManagerAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  recommendation: value,
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSubRequirementAttachmentIdChange = (
    requirement,
    subReqId,
    property = "note",
    value
  ) => {
    const updatedSubRequirements = newExpertManagerAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return {
                  ...subRequire,
                  attachmentId: value,
                };
              }

              return subRequire;
            }
          );

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };


  const renderedExpertManagerRequirements =
    newExpertManagerAnswer?.standardRequirements?.length > 0 &&
    newExpertManagerAnswer?.standardRequirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {
              handleRequirementChange(requirement);
            }}
            handleDonotApplyRequirementChange={() =>
              handleRequirementChange(requirement, "donotApply")
            }
          >
            {hasSubRequirements &&
              requirement?.subStandardRequirements?.map((subRequirement) => {
                return (
                  <>
                    <div className="px-5 flex flex-col mb-3 py-2">
                      <div className="border-b pb-2">
                        <h3 className="text-xl pb-4">{subRequirement.title}</h3>
                        <div className=" flex items-center gap-2 mb-4">
                          <span className="w-24">تقييم رئيس الخبير</span>
                          <TextInput
                            value={subRequirement?.percentage}
                            type="number"
                            placeholder=""
                            min={0}
                            max={100}
                            onChange={(e) => {
                              handleSubRequirementPercentageChange(
                                requirement,
                                subRequirement?.id,
                                "percentage",
                                e.target.value
                              );
                            }}
                          />
                          <label>
                            {getCommitmentLevelsBasedOnDegree(
                              Number(subRequirement?.percentage),
                              commitmentLevels
                            )}
                          </label>
                        </div>
                        <div className="flex gap-2 w-full items-center mb-4">
                          <span className="w-24"> الملاحظة</span>
                          <TextInput
                            value={subRequirement?.note}
                            className="grow"
                            placeholder="ملاحظة رئيس الخبير للجهة"
                            onChange={(e) => {
                              handleSubRequirementNoteChange(
                                requirement,
                                subRequirement?.id,
                                "note",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <div className="flex gap-2 w-full items-center">
                          <span className="w-24">التوصية</span>
                          <TextInput
                            value={subRequirement?.recommendation}
                            className="grow"
                            placeholder="توصية رئيس الخبير للجهة"
                            onChange={(e) => {
                              handleSubRequirementRecommendationChange(
                                requirement,
                                subRequirement?.id,
                                "recommendation",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <UploadFile
                          value={subRequirement.attachmentId}
                          onChange={(id) => {
                            handleSubRequirementAttachmentIdChange(
                              requirement,
                              subRequirement?.id,
                              "note",
                              id
                            );
                          }}
                          withOutToolTip
                        />
                      </div>
                    </div>
                  </>
                );
              })}
          </MainRequirement>
        </>
      );
    });

  return (
    <div>
      <StandardAnswerRequirement setMenuElement={setMenuElement}>
        <div className="flex  pb-5 w-full">
          <p
            className={
              selectedRequirementsTab == "agency"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("agency")}
          >
            الجهة
          </p>
          <p
            className={
              selectedRequirementsTab == "reviewer"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("reviewer")}
          >
            المدقق
          </p>

          <p
            className={
              selectedRequirementsTab == "expert"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("expert")}
          >
            الخبير
          </p>
          {/* <p
            className={
              selectedRequirementsTab == "pocOfficer"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("pocOfficer")}
          >
            ضابط الاتصال
          </p> */}
          <p
            className={
              selectedRequirementsTab == "expertManager"
                ? "bg-indigo-800 text-white px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-emerald-50 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("expertManager")}
          >
            رئيس الخبراء
          </p>
        </div>

        {selectedRequirementsTab === "agency" && <AgencyRequirementAnswer />}
        {selectedRequirementsTab === "reviewer" && (
          // <ReadOnlyRequirements
          //   label={"تقييم المدقق"}
          //   requirements={newExpertManagerAnswer?.reviewerRequirementsAnswer}
          // />
          <ReadOnlyRequirementAnswers
            requirements={newExpertManagerAnswer?.reviewerRequirementsAnswer}
            commitmentLevels={commitmentLevels}
            label={"تقييم المدقق"}
          />
        )}
        {selectedRequirementsTab === "expert" && (
          // <ReadOnlyRequirements
          //   label={'تقييم الخبير'}
          //   requirements={newExpertManagerAnswer?.expertRequirementsAnswer}
          // />
          <ReadOnlyRequirementAnswers
            requirements={newExpertManagerAnswer?.expertRequirementsAnswer}
            commitmentLevels={commitmentLevels}
            label={'تقييم الخبير'}
          />
        )}
        {/* {selectedRequirementsTab === "pocOfficer" && (
          <ReadOnlyRequirements
            requirements={newExpertManagerAnswer?.pocOfficerRequirementsAnswer}
          />
        )} */}

        {!isExpertManager() &&
          (isAdminFunc() || isInquiryManager()) &&
          selectedRequirementsTab === "expertManager" && (
            <ReadOnlyRequirements
              label={"تقييم رئيس الخبراء"}
              requirements={newExpertManagerAnswer?.standardRequirements}
            />
            // <>
            //   <div className="flex justify-end w-full gap-4">
            //     <p
            //       onClick={() => handleSelectAllReqs()}
            //       className="mb-6 cursor-pointer text-end text-emerald-500"
            //     >
            //       تحديد الكل
            //     </p>
            //     <p
            //       onClick={() => handleSelectAllReqs("cancel")}
            //       className="mb-6 cursor-pointer text-end text-red-800"
            //     >
            //       الغاء تحديد الكل
            //     </p>
            //   </div>
            //   {renderedExpertManagerRequirements}
            // </>
          )}
        {isExpertManager() &&
          !isAdminFunc() &&
          !isInquiryManager() &&
          selectedRequirementsTab === "expertManager" && (
            <>
              {/* <div className="flex justify-end w-full gap-4">
                <p
                  onClick={() => handleSelectAllReqs()}
                  className="mb-6 cursor-pointer text-end text-emerald-500"
                >
                  تحديد الكل
                </p>
                <p
                  onClick={() => handleSelectAllReqs("cancel")}
                  className="mb-6 cursor-pointer text-end text-red-800"
                >
                  الغاء تحديد الكل
                </p>
              </div> */}
              {renderedExpertManagerRequirements}
            </>
          )}

        {/* {
          isReviewerRequirement ? (
            
          ) : (
            <AgencyRequirementAnswer />
          )

          // renderedAgencyRequirements
        } */}
      </StandardAnswerRequirement>
    </div>
  );
};

export default ExpertManagerRequirementAnswer;



function ReadOnlyRequirementAnswers({ requirements, commitmentLevels, label }){
  return requirements?.length > 0 &&
  requirements?.map((requirement) => {
    const hasSubRequirements =
      requirement?.subStandardRequirements?.length > 0;

    return (
      <>
        <MainRequirement
          key={requirement?.id}
          title={requirement?.title}
          hasSubRequirements={hasSubRequirements}
          checked={requirement?.decision}
          requirementDonotApplyChecked={requirement?.donotApply}
          onChange={() => {
          }}
          handleDonotApplyRequirementChange={() =>{}}
        >
          {hasSubRequirements &&
            requirement?.subStandardRequirements?.map((subRequirement) => {
              return (
                <>
                  <div className="px-5 flex flex-col mb-3 py-2">
                    <div className="border-b pb-2">
                      <h3 className="text-xl pb-4">{subRequirement.title}</h3>
                      <div className=" flex items-center gap-2 mb-4">
                        <span className="w-24">{label || "تقييم المدقق"}</span>
                        <TextInput
                          value={subRequirement?.percentage}
                          type="number"
                          placeholder=""
                          min={0}
                          max={100}
                          disabled
                        />
                        <label>
                          {getCommitmentLevelsBasedOnDegree(
                            Number(subRequirement?.percentage),
                            commitmentLevels
                          )}
                        </label>
                      </div>
                      <div className="flex gap-2 w-full items-center mb-4">
                        <span className="w-24"> الملاحظة</span>
                        <TextInput
                          value={subRequirement?.note}
                          className="grow"
                          placeholder="الملاحظة"
                          disabled
                        />
                      </div>
                      <div className="flex gap-2 w-full items-center">
                        <span className="w-24">التوصية</span>
                        <TextInput
                          value={subRequirement?.recommendation}
                          className="grow"
                          placeholder="التوصية"
                          disabled
                        />
                      </div>
                      <UploadFile
                        value={subRequirement.attachmentId}
                        withOutToolTip
                        readOnly
                      />
                    </div>
                  </div>
                </>
              );
            })}
        </MainRequirement>
      </>
    );
  });

}