import { produce } from "immer";
import { useContext, useEffect, useState } from "react";
import { AddStandardContext } from "../addStandardContext";

import { AppInput, DropdownMultiSelect } from "../../../../../../components";
import NewDropdownMultiSelect from "../../../../../../components/NewDropDownMultiSelect";
import AppAccordion from "../../../../../../components/appAccordion";
import AppButton from "../../../../../../components/buttons/appButton";
import {
  handleFormValidation,
  handleInputValidation,
} from "../../../../../../helpers/utils";
import { editStandardProofsValidations } from "../addStandardValidation";

const defaultValues = {
  title: "",
  standardProofTypeId: "",
  standardProofFileSizeTypeId: "",
  standardProofFileTypeAssociations: [
    {
      standardProofFileTypeId: "",
    },
  ],
  fileSize: "",
  isRequired: true,
};

const StandardProofFileItem = ({
  id,
  index,
  title,
  number,
  disabled,
  standardProofTypeId,
  standardProofFileSizeTypeId,
  selectedProofFileAgenciesForEdit,
  standardProofFileTypeAssociations,
  selectedSubStandardProofRequirementsForEdit,
}) => {
  const {
    addFinalValue,
    setAddFinalValue,
    chooseDocType,
    fileTypes,
    sizeType,
  } = useContext(AddStandardContext);

  const renderedRequirements =
    addFinalValue.originalStandardRequirements ??
    addFinalValue.standardRequirements ??
    [];

  const subRequirements = renderedRequirements
    .map((item) => item.subStandardRequirements ?? [])
    .flat();

  const requirementsOptions =
    subRequirements
      ?.filter((item) => item.haveAttachments === 1)
      .map((item) => ({
        value: item.id,
        label: item.title,
        id: item.id,
      })) ?? [];

  const itemAgencies = selectedProofFileAgenciesForEdit?.map((item) => ({
    value: item?.agencyId,
    label: item?.agencyNameInArabic?.toString() || "",
    id: item?.agencyId,
  }));

  const itemProofSubRequirements =
    selectedSubStandardProofRequirementsForEdit?.map((item) => ({
      value: item?.subStandardRequirementId ?? item?.value,
      label:
        renderedRequirements
          ?.map((item) => item?.subStandardRequirements ?? [])
          ?.flat()
          ?.find((i) => i.id === item?.subStandardRequirementId ?? item?.id)
          ?.title ?? item.label,
      id: item?.subStandardRequirementId ?? item?.value,
    }));

  const typesAssociations = standardProofFileTypeAssociations?.map(
    (item) => item?.standardProofFileTypeId
  );

  const fileType = fileTypes?.filter((item) =>
    typesAssociations?.includes(item?.id)
  );

  const fileDocType = chooseDocType?.find(
    (item) => item?.value == standardProofTypeId
  );

  const types =
    (fileType?.length > 0 &&
      fileType.map((item) => ({
        standardProofFileTypeId: item.id,
      }))) ||
    [];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedRelatedSubRequirements, setSelectedRelatedSubRequirements] =
    useState(itemProofSubRequirements);
  const [errors, setErrors] = useState({
    title: "",
    standardProofTypeId: "",
    fileCount: "",
    standardProofFileSizeTypeId: "",
    standardProofFileTypeAssociations: [],
    standardProofSubRequirements: "",
    fileSize: "",
    agencyStandardProofs: "",
  });
  const [selectedDocType, setSelectedDocType] = useState("");
  const [selectedFileTypes, setSelectedFileTypes] = useState([]);

  const [values, setValues] = useState({
    id: id,
    title: title || "",
    standardProofTypeId:
      chooseDocType?.find((item) => item?.value == standardProofTypeId)?.id ||
      1,
    standardProofFileSizeTypeId:
      sizeType?.find((item) => item?.value == standardProofFileSizeTypeId)
        ?.id || 1,
    standardProofFileTypeAssociations: [...types] || [],
    subStandardProofRequirements: selectedSubStandardProofRequirementsForEdit,
    standardProofSubRequirements: itemProofSubRequirements,
  });

  useEffect(() => {
    setSelectedFileTypes(fileType);
    setSelectedDocType(fileDocType);
    setSelectedRelatedSubRequirements(itemProofSubRequirements);
    setAddFinalValue(
      produce((draft) => {
        draft.standardProofs[index] = values;
      })
    );
  }, []);

  const validateInput = async (name, value) => {
    const { valid, errMsg } = await handleInputValidation(
      editStandardProofsValidations,
      name,
      value
    );

    if (valid) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } else if (errMsg) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: errMsg }));
    }
  };

  const handleDeleteClick = () => {
    setAddFinalValue(
      produce((draft) => {
        draft.standardProofs =
          draft.standardProofs.filter((item, itemIndx) => itemIndx != index) ||
          [];
      })
    );
  };

  const handleChangeDocType = async (value) => {
    setSelectedDocType(value);
    await validateInput("standardProofTypeId", value?.label);
    setValues({
      ...values,
      standardProofTypeId: value?.id ? value?.id?.toString() : "",
    });
  };

  const handleChangeFileTypes = async (value) => {
    setSelectedFileTypes(value);
    await validateInput("standardProofFileTypeAssociations", value || []);

    const types =
      (value?.length > 0 &&
        value.map((item) => ({
          standardProofFileTypeId: item.id,
        }))) ||
      [];

    setValues({
      ...values,
      standardProofFileTypeAssociations: [...types],
    });
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    validateInput(name, value);
  };

  const handleChangeRelatedSubRequirements = async (value) => {
    setSelectedRelatedSubRequirements(value);
    await validateInput("standardProofSubRequirements", value || []);
    setValues({
      ...values,
      standardProofSubRequirements: value,
    });
  };

  const handleSubmit = async () => {
    const { validForm, formErrors } = await handleFormValidation(
      editStandardProofsValidations,
      values
    );
    if (formErrors || !validForm) {
      setErrors({ ...errors, ...formErrors });
      return;
    }

    setAddFinalValue(
      produce((draft) => {
        draft.standardProofs[index] = values;
      })
    );

    setIsOpen(false);
  };

  return (
    <div className="mt-3">
      <AppAccordion
        number={number}
        title={title}
        variant="green"
        handleDeleteClick={handleDeleteClick}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      >
        <div className=" p-5 pb-7 rounded-lg bg-white mt-3">
          <h2 className="font-semibold mb-4">تعديل المستند</h2>
          <div className="row gap-y-3">
            <div className="col-md-6 col-xl-3">
              <AppInput
                variant="gray"
                label="اسم المستند"
                placeholder="اكتب هنا"
                name="title"
                value={values.title}
                onChange={handleInputChange}
                errorMessage={errors.title}
                disabled={disabled}
              />
            </div>
            <div className="col-md-6 col-xl-3">
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedDocType}
                handleChange={handleChangeDocType}
                label="القيود"
                placeholder="اختر القيود"
                options={chooseDocType || []}
                isMultiple={false}
                // errorMessage={errors.standardProofTypeId}
                isRequired={true}
                disabled={disabled}
              />
            </div>
            <div className="col-md-6 col-xl-3">
              <NewDropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedFileTypes}
                handleChange={handleChangeFileTypes}
                label="نوع الملف"
                placeholder="اختر نوع الملف"
                options={fileTypes || []}
                isMultiple={true}
                errorMessage={errors.standardProofFileTypeAssociations}
                disabled={disabled}
              />
            </div>
            <div className="col-md-6 col-xl-3">
              <NewDropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedRelatedSubRequirements || []}
                handleChange={handleChangeRelatedSubRequirements}
                label="الأسئلة المرتبطة"
                options={requirementsOptions}
                errorMessage={errors.standardProofSubRequirements}
                isMultiple={true}
                isRequired={true}
                disabled={disabled}
              />
            </div>
            <div className="col-md-6 col-xl-3 self-end">
              <div className="text-end">
                <AppButton onClick={handleSubmit} disabled={false}>
                  تعديل مستند
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </AppAccordion>
    </div>
  );
};

export default StandardProofFileItem;
