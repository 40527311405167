import { useContext, useEffect, useRef, useState } from "react";
import { programsIcon } from "../../../assets/icons/Index";
import {
  DropdownMultiSelect,
  InputLabel,
  SModal,
  Title,
} from "../../../components";
import NewTable from "../../../components/NewTable";
import NewHeader from "../../../layouts/header/NewHeader";
import {
  axiosGetRequest,
  axiosPostRequest,
  get,
  post,
} from "../../../services/Request";
import environment from "../../../services/environment";
import { StageContext } from "../../../contexts/stageContext";
import { ErrorMessage } from "formik";
import { NewForm } from "../../../components/form/NewForm";
import AppModal from "../../../components/Models/appModal";
import { toast } from "react-toastify";
import { errorToast } from "../../../helpers/toast";
import cloud from "../../../assets/icons/cloud.svg";
import pdf from "../../../assets/icons/pdf.svg";
import { useTranslation } from "react-i18next";
import { Spinner } from "flowbite-react";
import { agencyId, isDgaUsers, isExpert } from "../../../helpers/isPermission";

const EvaluationNote = () => {
  const { currentStage, userId } = useContext(StageContext);
  const surveyId = currentStage?.surveyId || localStorage.getItem("surveyId");
  const expertId = userId || localStorage.getItem("userId");

  const { t } = useTranslation();
  const fileDescriptionInputRef = useRef(null);

  const [allAgencies, setAllAgencies] = useState([]);

  const [loading, setLoading] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [logs, setLogs] = useState([]);
  const [message, setMessage] = useState({});

  const [descriptionFile, setDescriptionFile] = useState("");
  const [descriptionFileId, setDescriptionFileId] = useState("");
  const [loadingImageDescription, setLoadingImageDescription] = useState(false);

  useEffect(() => {
    getEvaluationNote(0);
    getAllAgencies();
  }, []);

  const getEvaluationNote = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };


    const queryParams = new URLSearchParams();
    queryParams.append("SurveyId", surveyId);
    queryParams.append("ExpertId", expertId);

    if (agencyId){
      queryParams.append("agencyId", agencyId);
    }

    get(
      `${environment.getSurveyExpertEvaluationNote}?` + queryParams.toString(),
      config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: "error", message: "لا يوجد لديك صلاحيات" },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 500) {
          setMessage(
            {
              type: "error",
              message: "هناك مشكلة في الخدمة يجب العودة للدعم الفني",
            },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 200) {
          setTotalPages(Math.ceil(res.data.result.totalCount / 8));
          setLogs(res.data.result, setLoading(false));
        }
      }
    );
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getEvaluationNote(newOffset);
  };

  async function getAllAgencies() {
    const res = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination,
      {
        surveyId,
      }
    );
    setAllAgencies(res.result);
  }

  const initialInputsState = {
    note: "",
    recommendation: "",
    agency: null,
  };
  const [inputs, setInputs] = useState(initialInputsState);
  const [addLoading, setAddLoading] = useState(false);

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const formRef = useRef(null);

  const handleSubmitAdd = () => {
    // if (!descriptionFileId) {
    //   toast.error("يجب اٍضافة مستند");
    //   return;
    // }
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const validate = () => {
    const errors = {};
    if (!inputs.note.trim()) {
      errors.note = "هذا الحقل مطلوب";
    }    
    if(!inputs.agency){
        errors.agency = "هذا الحقل مطلوب";
    }
    return errors;
  };

  const handleSubmit = async () => {
    setAddLoading(true);
    const res = await axiosPostRequest(
      environment.addSurveyExpertEvaluationNote,
      {
        ...inputs,
        surveyId: surveyId || 0,
        agencyId: inputs?.agency?.value,
        expertId: expertId,
        attachmentId: descriptionFileId,
      }
    );
    setAddLoading(false);
    if (res?.success) {
      toast.success("تم إضافة الملاحظة بنجاح");
      setOpen(false);
      setInputs(initialInputsState);
      getEvaluationNote(0);
    } else {
      errorToast(res?.response?.data?.error?.message);
    }
  };

  const downloadFile = async (file) => {
    const queryParams = new URLSearchParams();

    queryParams.append("id", file.id);

    const resp = await axiosPostRequest(
      environment.downloadFile,
      null,
      queryParams,
      true,
      true,
      true
    );

    if (resp) {
      const blob = new Blob([resp], { type: "application/octet-stream" });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleFileSelect = (e) => {
    fileDescriptionInputRef.current.click();
  };

  const generateUpload = (file, e, values) => {
    setLoadingImageDescription(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const formData = new FormData();

    formData.append("Name", file.name);
    formData.append("FileType", "image/*");
    formData.append("EntityType", "");
    formData.append("File", file);

    post(environment.attachmentFile, formData, config, (res) => {
      setDescriptionFileId(
        res.data?.result?.id,
        setLoadingImageDescription(false)
      );
    });
  };

  const onChangeFile = (event, e) => {
    console.log("files", event.target.files[0]);
    if (event.target.files.length > 0) {
      setDescriptionFile(
        event.target.files[0],
        generateUpload(event.target.files[0], 1)
      );
    }
  };

  const handleChangeSelect = async (value) => {
    setInputs({ ...inputs, agency: value });
  };

  const selectValues = allAgencies
    .filter((agency) => agency.id && agency.nameInArabic)
    .map((agency) => {
      return { label: agency.nameInArabic, value: agency.id };
    });

  const EvaluationNoteList = [
    {
      name: "الإستبیان",
      selector: (row) => row.survey?.title,
      accessorKey: "surveyId",
      footer: (props) => props.column.id,
      cell: (info) => <div>{info.survey?.title}</div>,
    },
    {
      name: "الجهة",
      selector: (row) => row.agency?.nameInArabic,
      accessorKey: "nameInArabic",
      footer: (props) => props.column.id,
      cell: (info) => <div>{info.agency?.nameInArabic}</div>,
    },
    {
      name: "الخبير",
      selector: (row) => row.expert?.name,
      accessorKey: "expertId",
      footer: (props) => props.column.id,
      cell: (info) => <div>{info.expert?.name}</div>,
    },
    {
      name: "الملاحظة",
      selector: (row) => row.note,
      accessorKey: "note",
      footer: (props) => props.column.id,
      cell: (info) => <div>{info.note}</div>,
    },
    {
      name: "التوصية",
      selector: (row) => row.recommendation,
      accessorKey: "recommendation",
      footer: (props) => props.column.id,
      cell: (info) => <div>{info.recommendation}</div>,
    },
    {
      name: "المستند",
      selector: (row) => row.attachment.name,
      accessorKey: "attachmentId",
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span
            className="underline cursor-pointer"
            onClick={() => downloadFile(info.attachment)}
          >
            {info.attachment?.name}
          </span>
        </div>
      ),
    },
  ];

  // ----------------------------------------------------- //
  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        onClick={() => setOpen(true)}
        title={"ملاحظات الإستبیان الي الجهة"}
        single={isExpert()}
        withoutButton={!isDgaUsers()}
        textStyle={{ color: "white", fontSize: 18 }}
        titleButton="إضافة ملاحظة"
        style={{ backgroundColor: "#2B2969" }}
      />

      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />

      <AppModal
        loading={addLoading}
        handleSubmit={handleSubmitAdd}
        type={"submit"}
        headerTitle={"إضافة ملاحظات الإستبیان الى الجهة"}
        open={open}
        setOpen={(value) => {
          setInputs(initialInputsState);
          setOpen(value);
        }}
        submitLabel={"اٍضافة"}
      >
        <NewForm
          initialValues={initialInputsState}
          validate={validate}
          innerRef={formRef}
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-3">
            <div className="space-y-1">
              <InputLabel
                label={"الملاحظات الى الجهة"}
                name="note"
                value={inputs.note}
                onChange={handleInputChange}
                isRequired={true}
              />
              <ErrorMessage
                className="text-red-700"
                name="note"
                component="div"
              />
            </div>
            <div className="space-y-1">
              <InputLabel
                rows={4}
                type={"textarea"}
                value={inputs.recommendation}
                onChange={handleInputChange}
                name={"recommendation"}
                label={"التوصية"}
                isRequired={false}
              />
              <ErrorMessage
                className="text-red-700"
                name="recommendation"
                component="div"
              />
            </div>
            <div>
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={inputs.agency}
                handleChange={handleChangeSelect}
                label="اختر الجهة"
                placeholder="اختر الجهة"
                options={selectValues}
                isMultiple={false}
                isRequired={true}
                // errorMessage={"هذا الحقل مطلوب"}
              />
              <ErrorMessage
                className="text-red-700"
                name="agency"
                component="div"
              />
            </div>
            <div className="bg-[#F9F9FF] flex flex-row items-center justify-between rounded-3xl px-5 py-9 mt-10">
              <img src={cloud} alt="cloud" />
              <p className="text-[14px] text-[#E42829] w-[40%] text-center">
                يمكنك إرفاق بصيغة pdf ولا يزيد حجم الملف عن 100 ميجا
              </p>

              <input
                style={{ display: "none" }}
                isImage={true}
                ref={fileDescriptionInputRef}
                type={"file"}
                id={"fileUpload"}
                label={t("modules.main.users.users.form.image")}
                onChange={(event) => onChangeFile(event, 1)}
                image={descriptionFile.name}
              />

              <button
                type="button"
                onClick={() => handleFileSelect(1)}
                className="bg-[#1CC081] w-[35%] rounded-3xl p-3 text-white text-[16px]"
              >
                إرفاق ملف
              </button>
            </div>
            <div className="bg-[#F9F9FF] flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl">
              <div className="flex flex-row items-center">
                <img src={pdf} alt="pdf" />
                {loadingImageDescription ? (
                  <Spinner aria-label="Info Spinner example" color="info" />
                ) : (
                  <p className="text-sm text-[#161318] mr-3">
                    {descriptionFile.name}
                  </p>
                )}
              </div>
              <div className="flex flex-row items-center">
                <p className="text-sm text-[#7D8BAB] ml-3">
                  {descriptionFile.size}KB
                </p>
                {/* <img src={download} alt="download" onClick={handleDownload} /> */}
              </div>
            </div>
          </div>
        </NewForm>
      </AppModal>

      <div className="px-5">
        {/* <div className="my-5 mb-7 flex justify-end">
          <DrawerFilter
            handleSearch={() => search()}
            handleClear={() => clear()}
          >
            <AppInput
              type={"text"}
              label={"اسم الخدمة"}
              name="serviceName"
              variant="gray"
              value={serviceName}
              isRequired={false}
              onChange={(e) => setServiceName(e.target.value)}
            />
            <AppInput
              type={"text"}
              label={"اسم العملية"}
              name="methodName"
              variant="gray"
              value={methodName}
              isRequired={false}
              onChange={(e) => setMethodName(e.target.value)}
            />

            <AppInput
              type={"text"}
              label={"اسم المستخدم"}
              name="userName"
              variant="gray"
              value={userName}
              isRequired={false}
              onChange={(e) => setUserName(e.target.value)}
            />
          </DrawerFilter>
        </div> */}

        <NewTable
          loading={loading}
          columns={EvaluationNoteList}
          data={logs}
          pagination={true}
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default EvaluationNote;
