import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { programsIcon } from "../../../../../assets/icons/Index";
import { Title } from "../../../../../components";
import NewDropdownMultiSelect from "../../../../../components/NewDropDownMultiSelect";
import AppButton from "../../../../../components/buttons/appButton";
import SecondaryTapsLayout from "../../../../../layouts/secondaryTapsLayout";
import { axiosGetRequest, axiosPostRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import { serveySettingTabs } from "../data";

const SurveyToAgency = () => {
  const { surveyId } = useParams();
  // there is a bug here default value should come from the api
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [selectedAgencies, setSelectedAgencies] = useState([]);

  const {data: allAgenciesData = [], isLoading: allAgenciesLoading} = useQuery({
    queryKey: ["getAllAgenciesWithoutPagination"],
    queryFn: async () => {
      const res = await axiosGetRequest(environment.getAllAgenciesWithoutPagination);
      return res;
    },
    select: (data) => data?.result ??  [],
  })

  const {data: defaultSelectedAgencies = []} = useQuery({
    queryKey: ["GetAllSurveyAgencies"],
    queryFn: async () => {
      const searchQuery = `?surveyId=${surveyId}`;
    
      const res = await axiosGetRequest(environment.getAllSurveyAgencies + searchQuery);
  
      console.log("hello world", res.result);
      setSelectedAgencies(res.result.map((item) => ({
        value: item.agencyId,
        label: item?.agencyTitle || '',
      })));
      return res;
    },
    select: (data) => data?.result ??  [],
  })

  const handleSubmit = async (value) => {
    setLoading(true);

    const res = await axiosPostRequest(
      environment.assignAgencyToSurvery,
      {
        surveyId,
        "agencyIds": selectedAgencies.map((item) => item.value),
      }
    );
  
    setLoading(false);

    if (res.success) {
      toast.success("تم تعديل البيانات بنجاح");
    }
  };

  function handleAssignAgencies(value) {
    setSelectedAgencies(value || []);
  }

  return (
    <SecondaryTapsLayout
      linksArr={serveySettingTabs(`/surveys/${surveyId}`)}
      breadcrumbs={
        <div className="-mx-5">
          <Title
            iconTitle={programsIcon}
            subTitle={"اختر الجهات الحكومية"}
            seconed
            title={t("المؤشرات")}
            toPage={() => history.push("/indicators")}
            toPageSeconed={() => history.push(`/indicators/${localStorage.getItem("NavIndicatorId") || null}`)}
            withoutButton={true}
          />
        </div>
      }
    >
      <h2 className="mb-8 text-lg text-blue-text ">اختر الجهات الحكومية</h2>
      <div className="w-[40%]">
          <NewDropdownMultiSelect
            filter={true}
            label={"الجهات"}
            withoutLabel={false}
            isSearchable={false}
            value={selectedAgencies}
            isRequired={false}
            handleChange={handleAssignAgencies}
            placeholder={"اختر الجهة"}
            options={allAgenciesData.map((item) => ({
              value: item.id,
              label: item.nameInArabic || item.title || '',
            }))}
            isMultiple={true}
          />
        </div>
      <AppButton onClick={handleSubmit} loading={loading} className="mt-4">
        حفظ
      </AppButton>
    </SecondaryTapsLayout>
  );
};

export default SurveyToAgency;
