import { useQueryClient, useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";
import { permissionsCheck } from "../../../../helpers/utils";

export const useGetExperts = ({ currentPage, filterValue }) => {
  const { currentStage, userId } =
    useContext(StageContext);
  const surveyStageId =
    currentStage?.id || localStorage.getItem("surveyStageId");
  const surveyId = currentStage?.surveyId || localStorage.getItem("surveyId");
  const expertId = permissionsCheck("Pages.UserType.Expert")
    ? userId || localStorage.getItem("userId")
    : null;
  const reviewerId = permissionsCheck("Pages.UserType.Reviewer")
    ? userId || localStorage.getItem("userId")
    : null;

  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAllExperts } = useQuery({
    queryKey: ["Experts", currentPage, filterValue],
    queryFn: () =>
      axiosGetRequest(environment.GetAllExpertsWithAssignedStandards, {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId,
        ReviewerId: reviewerId,
        ExpertId: expertId,
        KeyWord: filterValue,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
      }),
    enabled: surveyStageId != null,
  });

  const allExperts = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / 5);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: ["Experts", currentPage + 1, filterValue],
      queryFn: () =>
        axiosGetRequest(environment.GetAllExpertsWithAssignedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId,
          ReviewerId: reviewerId,
          ExpertId: expertId,
          KeyWord: filterValue,
          SkipCount: (currentPage + 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: ["Experts", currentPage - 1, filterValue],
      queryFn: () =>
        axiosGetRequest(environment.GetAllExpertsWithAssignedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId,
          ReviewerId: reviewerId,
          ExpertId: expertId,
          KeyWord: filterValue,
          SkipCount: (currentPage - 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  return {
    queryClient,
    allExperts,
    totalCount,
    totalPages,
    data,
    isGettingAllExperts,
  };
};
