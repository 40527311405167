import { axiosGetRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { noAnswerDegreeId, useAnswerContext } from "../../answerContext";
import usePrevAgencyAnswer from "./usePrevAgencyAnswer";
import usePrevReviewerAnswer from "./usePrevReviewerAnswer";

const usePrevExpertAnswer = () => {
  const { getPrevAgencyAnswer } = usePrevAgencyAnswer();
  const { getPrevReviewerAnswer } = usePrevReviewerAnswer();

  const {
    setLoadPreExpertAnswer,
    setNewExpertAnswer,
    setExpertAnswer,
    expertDegree,
    newExpertAnswer,
    setExpertDegree,
    getSelfEvaluation,
  } = useAnswerContext();

  const getDegrees = async (standardId) => {
    let degreeValues = [];
    const degreesResult = await axiosGetRequest(
      environment.getCommitmentLevels,
      {
        standardId,
      }
    );

    if (degreesResult?.success) {
      degreeValues = degreesResult?.result?.map((degree) => ({
        ...degree,
        label: degree.title,
        value: degree.id,
      }));
    }

    const surveyDegrees = [...degreeValues];

    return surveyDegrees;
  };
  // const { currentStage } = useContext(StageContext);

  const getPrevExpertAnswer = async (standardId, entityId, currentStage) => {
    setLoadPreExpertAnswer(true);

    const res = await axiosGetRequest(environment.getExpertAnswer, {
      AgencyId: entityId,
      StageId: currentStage,
      StandardId: standardId,
      ExpertId: localStorage.getItem("userId"),
    });
    let agencyAnswer;

    // if (res?.result?.lastAnsweredReviewerId) {
    //   agencyAnswer = await getPrevReviewerAnswer(
    //     standardId,
    //     entityId,
    //     currentStage,
    //     res?.result?.lastAnsweredReviewerId
    //   );
    //   setNewExpertAnswer(agencyAnswer);
    // } else {
    agencyAnswer = await getPrevAgencyAnswer(
      standardId,
      entityId,
      currentStage
    );

    setNewExpertAnswer(agencyAnswer);
    setExpertAnswer(agencyAnswer);
    // }

    const reviewerRequirementsAnswer = agencyAnswer?.standardRequirements?.map(
      (requirement) => {
        const reqAnswer =
          res?.result?.lastAnswer?.reviewerRequirementAnswers?.find(
            (reqAns) => reqAns.standardRequirementId === requirement?.id
          );

        if (reqAnswer?.reviewerSubRequirementAnswers) {
          const { reviewerSubRequirementAnswers, ...reqs } = reqAnswer;

          const subRequirements = requirement?.subStandardRequirements?.map(
            (subReq) => {
              const sub = reviewerSubRequirementAnswers?.find(
                (item) => subReq?.id === item?.subStandardRequirementId
              );

              return {
                ...subReq,
                ...sub,
                id: subReq.id,
                decision: sub?.decision,
                donotApply: sub?.donotApply,
                subRequirementAnswerSides: subReq?.sides?.map(subReqSides => {
                  const subSides = sub?.reviewerSubRequirementAnswerSides?.find(
                    (item) => subReqSides?.id === item?.subStandardRequirementSideId
                  );

                  return {
                    ...subSides,
                    title: subReqSides?.title,
                  };
                }),
              };
            }
          );

          return {
            ...requirement,
            ...reqs,
            decision: reqs?.decision,
            donotApply: reqs?.donotApply,
            id: requirement.id,
            recommendation: "",
            subStandardRequirements: [...subRequirements],
          };
        }

        const subStandardRequirements =
          requirement?.subStandardRequirements?.map((item) => ({
            ...item,
            decision: 0,
            donotApply: false,
          }));
        return {
          ...requirement,
          decision: 0,
          donotApply: false,
          subStandardRequirements,
        };
      }
    );
    const pocOfficerRequirementsAnswer =
      agencyAnswer?.standardRequirements?.map((requirement) => {
        const reqAnswer =
          res?.result?.lastAnswer?.pocOfficerRequirementAnswers?.find(
            (reqAns) => reqAns.standardRequirementId === requirement?.id
          );

        if (reqAnswer?.pocOfficerSubRequirementAnswers) {
          const { pocOfficerSubRequirementAnswers, ...reqs } = reqAnswer;

          const subRequirements = requirement?.subStandardRequirements?.map(
            (subReq) => {
              const sub = pocOfficerSubRequirementAnswers?.find(
                (item) => subReq?.id === item?.subStandardRequirementId
              );

              return {
                ...subReq,
                ...sub,
                id: subReq.id,
                decision: sub?.decision,
                donotApply: sub?.donotApply,
              };
            }
          );

          return {
            ...requirement,
            ...reqs,
            decision: reqs?.decision,
            donotApply: reqs?.donotApply,
            id: requirement.id,
            recommendation: "",
            subStandardRequirements: [...subRequirements],
          };
        }

        const subStandardRequirements =
          requirement?.subStandardRequirements?.map((item) => ({
            ...item,
            decision: 0,
            donotApply: false,
          }));
        return {
          ...requirement,
          decision: 0,
          donotApply: false,
          subStandardRequirements,
        };
      });

    const ExpertRequirementsAnswer = agencyAnswer?.standardRequirements?.map(
      (requirement) => {
        const reqAnswer =
          res?.result?.surveyExpertAnswer?.expertRequirementAnswers?.find(
            (reqAns) => reqAns.standardRequirementId === requirement?.id
          );

        if (reqAnswer?.expertSubRequirementAnswers) {
          const { expertSubRequirementAnswers, ...reqs } = reqAnswer;

          const subRequirements = requirement?.subStandardRequirements?.map(
            (subReq) => {


              const sub = expertSubRequirementAnswers?.find(
                (item) => subReq?.id === item?.subStandardRequirementId
              );

              const subStandardRequirement = {
                ...subReq,
                ...sub,
                id: subReq.id,
                decision: sub?.decision,
                donotApply: sub?.donotApply,
              }


              if (sub?.expertSubRequirementAnswerSides) {
                subStandardRequirement.sides = structuredClone(sub?.expertSubRequirementAnswerSides?.map((sideAnswer, idx) => {
                  return {
                    ...subReq.sides?.[idx],
                    answer: sideAnswer?.answer,
                    evaluationDegree: sideAnswer?.evaluationDegree,
                    attachmentId: sideAnswer?.attachmentId,
                    evaluationNote: sideAnswer?.evaluationNote,
                    recommendation: sideAnswer?.recommendation,
                  }
                }));
              }
              else {
                const allSides =
                  res.result.surveyExpertAnswer.expertRequirementAnswers
                    .map((item) => item.expertSubRequirementAnswers || [])
                    .flat()
                    .map((item) => item.expertSubRequirementAnswerSides || [])
                    .flat();

                subStandardRequirement.sides = subReq.sides.map((side) => {
                  const sideAnswer = allSides.find(
                    (item) => item.subStandardRequirementSideId === side.id
                  );
  
  
                  return {
                    ...side,
                    answer: sideAnswer?.answer,
                    evaluationDegree: sideAnswer?.evaluationDegree,
                    attachmentId: sideAnswer?.attachmentId,
                    evaluationNote: sideAnswer?.evaluationNote,
                    recommendation: sideAnswer?.recommendation,
                  };
                });
              }



              return subStandardRequirement
            }
          );

          return {
            ...requirement,
            ...reqs,
            decision: reqs?.decision,
            donotApply: reqs?.donotApply,
            id: requirement.id,
            recommendation: "",
            subStandardRequirements: [...subRequirements],
          };
        }

        const subStandardRequirements =
          requirement?.subStandardRequirements?.map((item) => ({
            ...item,
            decision: 0,
            donotApply: false,
          }));
        return {
          ...requirement,
          decision: 0,
          donotApply: false,
          subStandardRequirements,
        };
      }
    );

    const agencyPrevUploadedFilesAnswer = agencyAnswer?.standardProofs?.map(
      (proofFile) => {
        const proofNote =
          res?.result?.surveyExpertAnswer?.surveyExpertAnswerStandardProofs?.find(
            (proofAns) =>
              proofAns.standardProofId === proofFile?.standardProofId
          );

        return {
          ...proofFile,
          ...proofNote,
          expertProofNotes: proofNote?.expertProofNotes || "",
          pastSelectedFiles: [],
          standardProofId: proofFile?.standardProofId || proofFile?.id,
        };
      }
    );

    const templateList = await axiosGetRequest(environment.getAllNoteTemplate);

    // * Get and select selected Expert degree
    const surveyDegrees = (await getDegrees(standardId))?.filter(
      (item) => item?.id !== noAnswerDegreeId
    );

    const donotApplyDegree =
      surveyDegrees?.filter((item) => item?.title == "لا ينطبق")?.[0] || "";
    const noCommitmentDegree =
      surveyDegrees?.filter((item) => item?.title == "عدم التزام")?.[0] || "";

    const checkedExpertDegree =
      surveyDegrees?.filter(
        (item) =>
          item?.id == res?.result?.surveyExpertAnswer?.finalCommitmentLevel?.id
      )?.[0] || null;

    setExpertDegree({
      ...expertDegree,
      expertSelfEvaluationCommitmentLevel:
        res?.result?.surveyExpertAnswer?.selfEvaluationCommitmentLevel ||
        noCommitmentDegree,
      expertSelfEvaluationDegree:
        res?.result?.surveyExpertAnswer?.selfEvaluationDegree || 0,
    });

    const answer = {
      ...newExpertAnswer,
      standardRequirements: ExpertRequirementsAnswer,
      reviewerRequirementsAnswer,
      pocOfficerRequirementsAnswer,
      standardProofs: agencyPrevUploadedFilesAnswer,
      checkedExpertDegree,
      surveyDegrees,
      templateList: templateList?.result?.items,
      expertAnswerNote: res?.result?.surveyExpertAnswer?.expertAnswerNote || "",

      expertSelectedTemplateForAgency:
        res?.result?.surveyExpertAnswer?.noteForAgency?.template ||
        res?.result?.surveyExpertAnswer?.noteForAgency?.content ||
        "",
      expertSelectedTemplateForReviewer:
        res?.result?.surveyExpertAnswer?.noteForReviewer?.template || "",

      noteForAgency:
        res?.result?.surveyExpertAnswer?.noteForAgency?.content || "",
      noteForReviewer:
        res?.result?.surveyExpertAnswer?.noteForReviewer?.content || "",

      agencyDonotApply: agencyAnswer?.agencyDonotApply || false,
      // donotApply: res?.result?.lastAnswer?.donotApply || false,
      agencyFinalCommitmentLevel: agencyAnswer?.agencyDonotApply
        ? donotApplyDegree
        : res?.result?.lastAnswer?.agencyFinalCommitmentLevel || null,
      agencySelfEvaluationCommitmentLevel:
        res?.result?.lastAnswer?.agencySelfEvaluationCommitmentLevel,
      reviewerFinalCommitmentLevel:
        res?.result?.lastAnswer?.reviewerFinalCommitmentLevel,
      pocOfficerFinalCommitmentLevel:
        res?.result?.lastAnswer?.pocOfficerFinalCommitmentLevel,
      reviewerSelfEvaluationCommitmentLevel:
        res?.result?.reviewerSelfEvaluationCommitmentLevel,
      needVisit: res?.result?.surveyExpertAnswer?.needVisit,
      visitNote: res?.result?.surveyExpertAnswer?.visitNote,
      selectedTemplatesForAgency:
        res?.result?.surveyExpertAnswer?.noteForAgency?.noteTemplateId || null,

      templateIdForAgency:
        res?.result?.surveyExpertAnswer?.noteForAgency?.noteTemplate?.id,

      reviewerRequirementAnswers: res?.result?.lastAnswer.reviewerRequirementAnswers,
      reviewerNoteTemplate: res?.result?.lastAnswer?.reviewerNoteForAgency?.noteTemplate?.templateContent || "",
      reviewerNotContent: res?.result?.lastAnswer?.reviewerNoteForAgency?.content,
      reviewerNoteForAgency:
        (res?.result?.lastAnswer?.reviewerNoteForAgency?.noteTemplate
          ?.templateContent || "") +
        (res?.result?.lastAnswer?.reviewerNoteForAgency?.unCheckRequirement ||
          "") +
        (res?.result?.lastAnswer?.reviewerNoteForAgency?.content || "") +
        (res?.result?.lastAnswer?.reviewerNoteForAgency?.template || ""),

      expertNoteTemplate: res?.result?.lastAnswer?.expertNoteForAgency?.noteTemplate?.templateContent || "",
      expertNotContent: res?.result?.lastAnswer?.expertNoteForAgency?.content,
      expertNoteForAgency: 
        (res?.result?.lastAnswer?.expertNoteForAgency?.noteTemplate
          ?.templateContent || "") +
        (res?.result?.lastAnswer?.expertNoteForAgency?.unCheckRequirement ||
          "") +
        (res?.result?.lastAnswer?.expertNoteForAgency?.content || "") +
        (res?.result?.lastAnswer?.expertNoteForAgency?.template || ""),
      
      pocOfficerRequirementAnswers: res?.result?.lastAnswer.pocOfficerRequirementAnswers,
      pocOfficerNoteTemplate: res?.result?.lastAnswer?.pocOfficerNoteForAgency?.noteTemplate?.templateContent || "",
      pocOfficerNotContent: res?.result?.lastAnswer?.pocOfficerNoteForAgency?.content,
      pocOfficerNoteForAgency:
        (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.noteTemplate
          ?.templateContent || "") +
        (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.unCheckRequirement ||
          "") +
        (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.content || "") +
        (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.template || ""),
    };
    setNewExpertAnswer(answer);
    setExpertAnswer(structuredClone(answer));
    // console.log(getExpertAnswer, "stans Expert answ");
    // setNewExpertAnswer({ agencyAnswer ,

    //  });

    setLoadPreExpertAnswer(false);
  };

  return { getPrevExpertAnswer };
};

export default usePrevExpertAnswer;
