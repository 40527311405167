import { useHistory, useParams } from "react-router";
import { Title } from "../../../../../components";
import SecondaryTapsLayout from "../../../../../layouts/secondaryTapsLayout";
import { serveySettingTabs } from "../data";
import { t } from "i18next";
import { programsIcon } from "../../../../../assets/icons/Index";
import AppRadio from "../../../../../components/form/appRadio";
import { useEffect, useState } from "react";
import { axiosPutRequest, get } from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import { toast } from "react-toastify";
import DegreeItemSkeleton from "../serveyDegrees/degreeList/DegreeItemSkeleton";

const QuestionsLevel = () => {
  const { surveyId } = useParams();
  // there is a bug here default value should come from the api
  const [value, setValue] = useState("1");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const getQuestionLevel = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.getStructureSubStandardRequirementDirectly +
        `?surveyId=${surveyId}`,
      config,
      (res) => {
        setLoading(false);
        if (res?.status == 200) {
          setValue(
            res?.data?.result?.structureSubStandardRequirementDirectly
              ? "1"
              : "0"
          );
        }
      }
    );
  };

  useEffect(() => {
    getQuestionLevel();
  }, []);

  const handleSubmit = async (value) => {
    setLoading(true);
    const searchParams = new URLSearchParams();
    searchParams.append("surveyId", surveyId);
    searchParams.append(
      "structureSubStandardRequirementDirectly",
      value === "1"
    );

    const res = await axiosPutRequest(
      environment.updateSurveyStructureSubStandardRequirement +
        `?${searchParams.toString()}`,
          undefined,
          undefined,
          undefined,
          () => {
            getQuestionLevel();
          }
    );

    setLoading(false);

    if (res.success) {
      toast.success("تم تعديل مستوى إنشاء الأسئلة بنجاح");
    }
  
  };

  function handleLevelChange(e) {
    setValue(e.target.value);
    handleSubmit(e.target.value);
  }

  return (
    <SecondaryTapsLayout
      linksArr={serveySettingTabs(`/surveys/${surveyId}`)}
      breadcrumbs={
        <div className="-mx-5">
          <Title
            iconTitle={programsIcon}
            subTitle={"مستوى إنشاء الأسئلة"}
            seconed
            // subTitleSeconed={"مستوى إنشاء الأسئلة"}
            title={t("المؤشرات")}
            toPage={() => history.push("/indicators")}
            toPageSeconed={() =>
              history.push(
                `/indicators/${localStorage.getItem("NavIndicatorId") || null}`
              )
            }
            withoutButton={true}
          />
        </div>
      }
    >
      <h2 className="mb-8 text-lg text-blue-text ">مستوى إنشاء الأسئلة</h2>
      {loading ? (
        <>
          <DegreeItemSkeleton />
        </>
      ) : (
        <>
          <AppRadio
            label="على مستوى المعيار "
            name={"level"}
            id={1}
            value="1"
            checked={value === "1"}
            onChange={handleLevelChange}
          />
          <AppRadio
            label="على مستوى البعد"
            name={"level"}
            id={2}
            value="0"
            checked={value === "0"}
            onChange={handleLevelChange}
          />
        </>
      )}

      {/* 
      <AppButton onClick={handleSubmit} loading={loading} className="mt-4">
        حفظ
      </AppButton> */}
    </SecondaryTapsLayout>
  );
};

export default QuestionsLevel;
